import React from 'react';
import PropTypes from 'prop-types';
import LightboxModal from 'components/LightboxModal';
import isEqual from 'lodash/isEqual';

import AttachmentCard from 'components/AttachmentCard';
import {pluralize} from 'utils/stringUtils';
import './attachments.scss';

class Attachments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.mediaResources, this.props.mediaResources);
    }

    onAttachmentRemoved = (mediaResourceId) => {
        this.props.onAttachmentRemoved(mediaResourceId);
    }

    render() {
        const {mediaResources, isAdded} = this.props;
        if (mediaResources !== undefined && mediaResources.length > 0) {
            return (
                <React.Fragment>
                    {this.state.isLoading && <LightboxModal show={this.state.isLoading}/>}
                    <div className="attachments-container clr">
                        {!this.props.notShowAttachmentsCount &&
                        <div
                            className="attachments-count">{`${`${mediaResources.length} `}${pluralize('Attachment', mediaResources.length)}`}</div>
                        }
                        {
                            mediaResources.map((mediaResource, index) => (
                                <AttachmentCard
                                    index={index}
                                    classname="attachment-seperator"
                                    key={mediaResource.id}
                                    attachment={mediaResource}
                                    isAdded={isAdded}
                                    onAttachmentRemoved={this.onAttachmentRemoved}
                                />
                            ))
                        }
                    </div>
                </React.Fragment>
            );
        }
        return '';
    }
}

Attachments.propTypes = {
    mediaResources: PropTypes.array,
    isAdded: PropTypes.bool,
    onAttachmentRemoved: PropTypes.func,
    notShowAttachmentsCount: PropTypes.bool
};

export default Attachments;
