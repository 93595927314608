export const GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SENT =
    "GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SENT";
export const GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SUCCEED =
    "GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SUCCEED";
export const GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_FAILED =
    "GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_FAILED";

export const ADD_NEW_LOCATION_REQUEST_SENT = "ADD_NEW_LOCATION_REQUEST_SENT";
export const ADD_NEW_LOCATION_REQUEST_SUCCEED =
    "ADD_NEW_LOCATION_REQUEST_SUCCEED";
export const ADD_NEW_LOCATION_REQUEST_FAILED =
    "ADD_NEW_LOCATION_REQUEST_FAILED";

export const UPDATE_LOCATION_REQUEST_SENT =
    "UPDATE_LOCATION_REQUEST_SENT";
export const UPDATE_LOCATION_REQUEST_SUCCEED =
    "UPDATE_LOCATION_REQUEST_SUCCEED";
export const UPDATE_LOCATION_REQUEST_FAILED =
    "UPDATE_LOCATION_REQUEST_FAILED";

export const GET_TIMEZONES_REQUEST_SENT = "GET_TIMEZONES_REQUEST_SENT";
export const GET_TIMEZONES_REQUEST_SUCCEED =
    "GET_TIMEZONES_REQUEST_SUCCEED";
export const GET_TIMEZONES_REQUEST_FAILED = "GET_TIMEZONES_REQUEST_FAILED";

export const UPLOAD_FILE_REQUEST_SENT = "UPLOAD_FILE_REQUEST_SENT";
export const UPLOAD_FILE_REQUEST_SUCCEED ="UPLOAD_FILE_REQUEST_SUCCEED";
export const UPLOAD_FILE_REQUEST_FAILD = "UPLOAD_FILE_REQUEST_FAILD";