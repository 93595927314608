import React from 'react';
import ValueContainer from './ValueContainer';
import ClearIndicator from './Indicators';
import MultiSelect from './MultiSelect';
import Option from './Options';


// eslint-disable-next-line react/prefer-stateless-function
export default class CustomizedMultiSelect extends React.Component {
    render() {
        const {className: classes, ...props} = this.props;
        return (
            <MultiSelect
                {...props}
                className={`multi-select-container ${classes || ''}`}
                classNamePrefix="multi-select-customized"
                backspaceRemovesValue={false}
                components={{
                    ValueContainer, ClearIndicator, Option,
                }}
            />
        );
    }
}
