import React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import InputTextField from "./../../../components/StyleGuideComponents/InputTextField";
import DatePicker from "./../../../components/StyleGuideComponents/DatePicker";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer } from "./../reducers";
import validate, { clearErrorsForField } from "common/validator";
import { signUpDataValidation } from "./../validators";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { setSignUpData } from "./../action";
import DisclaimerView from "./DisclaimerView";
import { generateOtp, verifyOtp } from "../action";
import title from "./../../../assets/images/securestart3x.png";
import Box from "@mui/material/Box";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Media from "react-media";
import NameView from "./NameView";
import { NAME_VIEW } from "../constants";
import { useSelector } from "react-redux";

const Wrapper = (props) => {

  const page = useSelector((store) => store.register.patientRegisterReducer.page)
  const flowType = useSelector((store) => store.register.patientRegisterReducer.flowType)

    return (
      <div className="wrapper-outer-body">
        { props.showBanner && <div className="wrapper-banner-container">{props.bannerMessage}</div>}


        <div className="wrapper-body">
        <div className="header-wrapper">
          <div className="sign-up-header">
            {
              flowType !== 'mobile_sign_up' || page !== NAME_VIEW ? 
            <Media query={{ maxWidth: 480 }}>
              {(screenIsSmall) =>
                screenIsSmall ? <ArrowBackOutlinedIcon sx={{width:"24px",height:"24px"}} onClick={()=>props.nextPage(props.prevPage)} style={{alignSelf:"flex-start"}} fontSize="large" /> :<ArrowBackIosIcon className="back-icon" sx={{width:"24px",height:"24px"}}  onClick={()=>props.nextPage(props.prevPage)} fontSize="normal" />
              }
            </Media>:
            <div style={{alignSelf:"flex-start",marginRight: "30px"}}></div>
            } 
            
          </div>
        { page >= 2 && page <= 6 ? 
        <div className="progress-bar-container">
            <div className={`eclipse-progress-dot ${page >= 2 ? 'dark-circle':''}`}/>
            <div className={`eclipse-progress-dot ${page >= 3 ? 'dark-circle':''}`}/>
            <div className={`eclipse-progress-dot ${page >= 4 ? 'dark-circle':''}`}/>
            <div className={`eclipse-progress-dot ${page >= 5 ? 'dark-circle':''}`}/>
            <div className={`eclipse-progress-dot ${page >= 6 ? 'dark-circle':''}`}/>
        </div>
        :
         page >= 12 && page <= 13 &&
        <div className="progress-bar-container progress-bar-container-second">
          <div className={`eclipse-progress-dot ${page >= 12 ? 'dark-circle':''}`}/>
          <div className={`eclipse-progress-dot ${page >= 13 ? 'dark-circle':''}`}/>
        </div>
        
        }
       
          </div>
          <img className="img-fluid header-img" src={title}></img>
          <div className="wrapper-inner-body">
          {props.title1 && (<div className={`wrapper-title-one ${[9,10,12,13].includes(page) ? 'wrapper-centered-texts' : '' } `}>{props.title1}</div>)}
          {props.title2 && (<div className="wrapper-title-two">{props.title2}</div>)}
          {<div className="procedure-dates-note-text">{page === 4 && "*Note: If you do not know your surgery date, it can be added later."}</div>}

            {props.children}
          </div>
      </div>
      </div>
    );
}

export default Wrapper;
