import {
    ALTER_FORGOT_PASSWORD_REQUEST_SENT_FLAG,
    GET_FORGOT_PASSWORD_REQUEST_FAILED,
    GET_FORGOT_PASSWORD_REQUEST_SENT,
    GET_FORGOT_PASSWORD_REQUEST_SUCCEED
} from './constants';


const initialState = {
    isLoading: false,
    message: '',
    error: '',
    email: '',
    forgotPasswordSent: false,
};

const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FORGOT_PASSWORD_REQUEST_SENT:
            return {
                ...state,
                isLoading: true,
                isRequestInProgress: true,
            };
        case GET_FORGOT_PASSWORD_REQUEST_SUCCEED:
            return {
                ...state,
                isLoading: false,
                isRequestInProgress: false,
                forgotPasswordSent: true,
                email: action.data.email
            };
        case GET_FORGOT_PASSWORD_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                isRequestInProgress: false,
            };

        case ALTER_FORGOT_PASSWORD_REQUEST_SENT_FLAG:
            return {
                ...state,
                isRequestInProgress: false,
                forgotPasswordSent: false
            };
        default:
            return state;
    }
};

export default forgotPasswordReducer;
