/*
 *
 * UserCookieConsent constants
 *
 */

export const GET_COOKIE_CONSENT_REQUEST_SENT =
    "app/PatientRegister/GET_COOKIE_CONSENT_REQUEST_SENT";
export const GET_COOKIE_CONSENT_REQUEST_SUCCEED =
    "app/PatientRegister/GET_COOKIE_CONSENT_REQUEST_SUCCEED";
export const GET_COOKIE_CONSENT_REQUEST_FAILED =
    "app/PatientRegister/GET_COOKIE_CONSENT_REQUEST_FAILED";

export const POST_COOKIE_CONSENT_PROVIDED_SENT =
    "app/PatientRegister/POST_COOKIE_CONSENT_PROVIDED_SENT";
export const POST_COOKIE_CONSENT_PROVIDED_SUCCEED =
    "app/PatientRegister/POST_COOKIE_CONSENT_PROVIDED_SUCCEED";
export const POST_COOKIE_CONSENT_PROVIDED_FAILED =
    "app/PatientRegister/POST_COOKIE_CONSENT_PROVIDED_FAILED";
