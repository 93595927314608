/*
 *
 * UserCookieConsent reducer
 *
 */
import {
    GET_COOKIE_CONSENT_REQUEST_FAILED,
    GET_COOKIE_CONSENT_REQUEST_SENT,
    GET_COOKIE_CONSENT_REQUEST_SUCCEED,
} from './constants';

const initState = {
    cookieConsentText: "",
    isLoading: false,
};

export const getCookieConsentReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_COOKIE_CONSENT_REQUEST_SENT:
            return {
                ...state,
                isLoading: true,
            };
        case GET_COOKIE_CONSENT_REQUEST_SUCCEED:
            return {
                ...state,
                isLoading: false,
                cookieConsentText: action.data.cookies_consent
            }
        case GET_COOKIE_CONSENT_REQUEST_FAILED:
            return {
                ...state,
                isLoading: true,
            };
        default:
            return state;
    }
};
