import camelcaseKeys from 'change-case-object';
import {deleteAuthHeaders, setAuthHeaders} from '../services/base';
import {showToaster} from '../common/toasterActions';

import {SIGN_IN_URL} from '../services/constants';
import * as StorageService from '../services/storage';
import {
    generateOtpUserService,
    getPrivacyNoticeUrlService,
    getTermsAndConditionUrlService,
    registerUserService,
    signInUserService,
    signOutService,
} from '../services/deviceAuth';

import {
    deleteAccountService,
    getCurrentUserInfo as getCurrentUserInfoCall,
    getUserInfo as getUserInfoCall,
    updateUserEmailCall,
    updateUserProfile as updateUserProfileCall,
    updateUserProfilePicture as updateUserProfilePictureCall,
} from '../services/users';

import Actions from './authActions';
import {RESET_GLOBAL_REDUCERS} from '../constants';
import history from '../configureHistory';
import {startHeartBeats} from '../services/heartbeats';

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const registerUser = (userRegistrationDetails) => (dispatch) => {
    dispatch(Actions.registrationRequestSent());
    registerUserService(userRegistrationDetails)
        .then((response) => {
            setAuthHeaders(response.headers);
            dispatch(Actions.registrationRequestSucceeded(camelcaseKeys.camelCase(response.data.data)));
            StorageService.persistAuthHeadersInDeviceStorage(response.headers);
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                dispatch(showToaster({
                    message: 'No internet connection. Please check your internet settings',
                    type: 'error'
                }));
            }
            dispatch(Actions.registrationRequestFailed());
        });
};

export const hideLoginErrorModalAction = () => (dispatch) => {
    dispatch(Actions.resetPatientLoginPrevented());
};

export const getTermsAndConditionsUrl = (emailOrContactNumber) => (dispatch) => {
    dispatch(Actions.getTermsAndConditionUrlRequestSent());
    getTermsAndConditionUrlService({email_or_contact_number: emailOrContactNumber})
        .then((response) => {
            dispatch(Actions.getTermsAndConditionUrlRequestSucceeded(camelcaseKeys.camelCase(response.data)));
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                dispatch(showToaster({message: 'Could not reach server. Please try again.', type: 'error'}));
            }
            dispatch(Actions.getTermsAndConditionUrlRequestFailed());
            let message = 'Something went wrong';
            if (error.response && error.response.data && error.response.data.error) {
                message = error.response.data.error;
            }
            dispatch(showToaster({message, type: 'error'}));
        });
};

export const getPrivacyNoticeUrl = () => (dispatch) => {
    dispatch(Actions.getPrivacyNoticeUrlRequestSent());
    getPrivacyNoticeUrlService({})
        .then((response) => {
            dispatch(Actions.getPrivacyNoticeUrlRequestSucceeded(camelcaseKeys.camelCase(response.data)));
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                dispatch(showToaster({message: 'Could not reach server. Please try again.', type: 'error'}));
            }
            dispatch(Actions.getPrivacyNoticeUrlRequestFailed());
            let message = 'Something went wrong';
            if (error.response && error.response.data && error.response.data.error) {
                message = error.response.data.error;
            }
            dispatch(showToaster({message, type: 'error'}));
        });
};

export const generateOtp = (userSignInCredentials) => (dispatch) => {
    dispatch(Actions.generateOtpRequestSent());
    generateOtpUserService(userSignInCredentials)
        .then((response) => {
            const data = camelcaseKeys.camelCase(response.data);
            dispatch(Actions.generateOtpRequestSucceeded(data));
        })
        .catch((error) => {
            let message = 'Something went wrong';
            dispatch(Actions.generateOtpRequestFailed());
            if (error.response && error.response.data) {
                if (error.response.data.errors) {
                    message = error.response.data.errors.length > 0 ? error.response.data.errors[0] : 'Something went wrong';
                }
            }
            if (error.message === 'Network Error') {
                message = 'No internet connection. Please check your internet settings';
            }
            dispatch(showToaster({message, type: 'error'}));
        });
};

export const closeOtpModal = () => (dispatch) => {
    dispatch(Actions.closeOtpModalRequestSucceeded());
};

export const signInUser = (userSignInCredentials,nextPage,page) => (dispatch) => {
    dispatch(Actions.signInRequestSent());
    signInUserService(userSignInCredentials)
        .then((response) => {
            setAuthHeaders(response.headers, response.data.access_token);
            StorageService.persistAuthHeadersInDeviceStorage(response.headers, response.data.access_token);
            const currentUser = camelcaseKeys.camelCase(response.data.data);
            StorageService.setItemToStorage('currentUser', JSON.stringify(currentUser));
            dispatch(Actions.signInRequestSucceeded(currentUser));
            dispatch(Actions.resetPatientLoginPrevented());
            if(typeof nextPage !== 'undefined') nextPage(page);
        })
        .catch((error) => {
            let message = 'Something went wrong';
            dispatch(Actions.signInRequestFailed());
            if (error.response && error.response.data) {
                if (error.response.data.errors) {
                    message = error.response.data.errors.length > 0 ? error.response.data.errors[0] : 'Something went wrong';
                }
            }
            if (message == 'Patient web login is not allowed') {
                dispatch(Actions.patientLoginPrevented());
            } else {
                dispatch(Actions.resetPatientLoginPrevented());
            }
            if (error.message === 'Network Error') {
                message = 'No internet connection. Please check your internet settings';
            }
            if (message != 'Patient web login is not allowed') {
                dispatch(showToaster({message, type: 'error'}));
            }
        });
};

export const signOutUser = (postLogoutRedirectUrl = null) => (dispatch) => {
    dispatch(Actions.signOutRequestSent());
    signOutService()
        .then(() => {
            dispatch(handlePostLogoutAction(postLogoutRedirectUrl));
            dispatch(Actions.resetPatientLoginPrevented());
        })
        .catch(() => {
            dispatch(Actions.signOutRequestFailed());
        });
};

export const getCurrentUserInformation = () => (dispatch) => {
    dispatch(Actions.getCurrentUserInfoRequestSent());
    getCurrentUserInfoCall()
        .then((response) => {
            StorageService.setItemToStorage('currentUser', JSON.stringify(camelcaseKeys.camelCase(response.data.data)));
            dispatch(Actions.getCurrentUserInfoRequestSucceeded(camelcaseKeys.camelCase(response.data.data)));
        })
        .catch(() => {
            dispatch(Actions.getCurrentUserInfoRequestFailed());
        });
};

export const getCurrentUserInfo = (store) => {
    if (StorageService.getItemFromStorage('token')) {
        store.dispatch(getCurrentUserInformation());
    } else {
        store.dispatch(Actions.setHasVerificationBeenAttempted(true));
    }
};

export const sendHeartBeatsAgain = () => {
    let currentHeartBeats = JSON.parse(localStorage.getItem('currentHeartBeats'));
    if (currentHeartBeats === undefined || currentHeartBeats === null) {
        const currentHeartBeat = []
        localStorage.setItem('currentHeartBeats', JSON.stringify(currentHeartBeat))
    } else {
        for (let currentHeartBeat of currentHeartBeats) {
            startHeartBeats(currentHeartBeat)
        }
        ;
    }
}

export const getUserInfo = () => (dispatch) => {
    dispatch(Actions.getProfileRequestSent());
    getUserInfoCall()
        .then((response) => {
            dispatch(Actions.getProfileRequestSucceeded((response.data)));
        })
        .catch(() => {
            dispatch(Actions.getProfileRequestFailed());
        });
};

export const updateUserInfo = (params) => (dispatch) => {
    dispatch(Actions.updateUserProfileRequestSent());
    updateUserProfileCall(params)
        .then((response) => {
            updateLocalUserStore(dispatch);
            dispatch(Actions.updateUserProfileRequestSucceeded((response.data)));
        })
        .catch((error) => {
            dispatch(Actions.updateUserProfileRequestFailed(error));
        });
};

export const updateUserProfilePicture = (params) => (dispatch) => {
    dispatch(Actions.updateUserProfilePictureRequestSent());
    updateUserProfilePictureCall(params)
        .then((response) => {
            updateLocalUserStore(dispatch);
            dispatch(getUserInfo());
            dispatch(Actions.updateUserProfilePictureRequestSucceeded((response.data)));
            dispatch(showToaster({message: 'Profile picture updated successfully.'}));
        })
        .catch((error) => {
            dispatch(Actions.updateUserProfilePictureRequestFailed(error));
        });
};

export const updateUserEmail = (params) => (dispatch) => {
    dispatch(Actions.updateUserEmailRequestSent());
    updateUserEmailCall(params)
        .then((response) => {
            updateLocalUserStore(dispatch);
            dispatch(Actions.updateUserEmailRequestSucceeded((response.data)));
        })
        .catch(() => {
            dispatch(Actions.updateUserEmailRequestFailed());
        });
};

export const updateLocalUserStore = (dispatch) => {
    if (StorageService.getItemFromStorage('token')) {
        dispatch(getCurrentUserInformation());
    }
};

export const deleteUserAccount = (params) => (dispatch) => {
    dispatch(Actions.deleteAccountRequestSent());
    deleteAccountService(params)
        .then(() => {
            dispatch(Actions.deleteAccountRequestSucceeded());
            dispatch(handlePostLogoutAction());
        })
        .catch(() => {
            dispatch(Actions.deleteAccountRequestFailed());
        });
};

export const handlePostLogoutAction = () => (dispatch) => {
    deleteAuthHeaders();
    StorageService.deleteAuthHeadersFromDeviceStorage();
    dispatch(Actions.signOutRequestSucceeded());
    StorageService.clearDefaultStorage();
    dispatch({ type: RESET_GLOBAL_REDUCERS });
  };
