import * as React from "react";
import { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import "../../assets/stylesheets/_variables.scss";
import { IconButton } from "@material-ui/core";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { InputTextFieldTheme } from "./common/Themes";
import { InputBoxStyleGuide } from "./StyleGuideCSS";
import PhoneInput from "components/PhoneInput";
import InputMask from "react-input-mask";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import {PHONE_NUMBER_MASK, ZIP_CODE_MASK} from "../../constants";

export default function InputTextField(props) {

  const formatCharsForZip = {
    'd': '[0-9]'
  }

  const handleChange = (e) =>{
    props.onChange(props.name,e.target.value);
  }

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
 
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !props.value.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    if(props.value && props.value.endsWith('-')){
      cursorPosition++;
        selection = { start: cursorPosition, end: cursorPosition };
    }
 
    return {
      value,
      selection
    };
  }

  const errorIcon = {
    endAdornment: (
      <InputAdornment position="end">
        <ErrorOutlineIcon
          sx={{ color: `${InputBoxStyleGuide.colorAlertNegative}` }}
        />
      </InputAdornment>
    ),
  };

  const clearButton = {
    endAdornment: (
      <IconButton
        aria-label="toggle password visibility"
        onClick={ ()=>props.onChange(props.name,"") }
      >
        <CancelOutlinedIcon
          fontSize="small"
          sx={{ color: "#575558", height: "20px", width: "20px" }}
        />
      </IconButton>
    ),
  };

  return (
    <ThemeProvider theme={InputTextFieldTheme}>
      <FormControl>
        {props.type === "phone" || props.name === 'zipCode' ? (
          <InputMask
            mask={props.name === 'zipCode'? ZIP_CODE_MASK : PHONE_NUMBER_MASK}
            beforeMaskedValueChange={ props.name === 'zipCode' ? beforeMaskedValueChange : undefined}
            formatChars={props.name === 'zipCode'?formatCharsForZip : null}
            maskChar=""
            value={props.value}
            onChange={ handleChange }
          >
            {() => (
              <TextField
                sx={{
                  ...props.sx,
                  fontSize: "16px",
                }}
                error={props.error}
                type={props.type === "phone" ? "tel": "text"}
                id={props.id || "outlined-basic"}
                label={props.label || "Enter Text"}
                variant={props.variant || "outlined"}
                name={props.name || "text"}
                color="primary"
                fullWidth
                InputProps={
                  !props.disabled
                    ? props.error
                      ? errorIcon
                      : props.value
                      ? clearButton
                      : {}
                    : null
                }
                inputProps={{
                  maxLength: props.maxLength ? props.maxLength : 150,
                  pattern: "[a-z]{1,15}"
                }}
                required={props.required}
                size="normal"
              />
            )}
          </InputMask>
        ) : (
          <TextField
            sx={{
              ...props.sx,
              fontSize: "16px",
            }}
            pattern={[0-9]}
            error={props.error}
            type={props.type === "email" ? "email": "text"}
            id={props.id || "outlined-basic"}
            label={props.label || "Enter Text"}
            variant={props.variant || "outlined"}
            name={props.name || "text"}
            color="primary"
            value={props.value ? props.value : ""}
            fullWidth
            InputProps={
              !props.disabled
                ? props.error
                  ? errorIcon
                  : props.value
                  ? clearButton
                  : {}
                : null
                
            }
            inputProps={{ maxLength: props.maxLength ? props.maxLength : 150, pattern: "[a-z]{1,15}" }}
            required={props.required}
            onChange={ handleChange }
            size="normal"
            disabled={props.disabled}
          />
        )}
        <FormHelperText id="outlined-weight-helper-text" className="no-margin"><span style={{color:`${InputBoxStyleGuide.colorAlertNegative}`, fontSize: "12px"}}>{props.errorText ? props.errorText : ""}</span></FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
}
