import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import "../patientRegister.scss";
import DisclaimerView from "./DisclaimerView";

import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import Stack from "@mui/material/Stack";
import SecondaryButton from "./../../../components/StyleGuideComponents/SecondaryButton";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {combineReducers, compose} from "redux";
import injectReducer from "utils/injectReducer";
import {patientRegisterReducer} from "./../reducers";
import Media from "react-media";
import {generateOtp, saveProcedure, setProcedureConfirmationFlag} from "./../action";

import {trimCountryCodeFromPhoneNumber} from 'utils/stringUtils'

import {PHONE_VERIFY_VIEW, SUCCESS_VIEW} from "./../constants";

const ProcedureVerificationView = (props) => {

    const [modifierIdentifier, setModifierIdentifier] = useState(props.modifierIdentifier || { label: '', value: '' })
    const [facilityId, setFacilityId] = useState(props.facilityId || { label: '', value: '' })
    const [flowType, setFlowType] = useState(props.flowType || "")
    const [flowId, setFlowId] = useState(props.flowId || "")
    const [phone, setPhone] = useState(props.phone || "")
    const [showMessageModal, setShowMessageModal] = useState(false)
    const [existingUser, setExistingUser] = useState(props.existingUser || false)

    useEffect(() => {
        setExistingUser(props.existingUser)
    }, [props.existingUser])

    useEffect(() => {
        setFlowType(props.flowType)
    }, [props.flowType])

    useEffect(() => {
        setFlowId(props.flowId)
    }, [props.flowId])

    useEffect(() => {
        setPhone(props.phone)
    }, [props.phone])


    const history = useHistory();
    useEffect(() => {
        setModifierIdentifier(props.modifierIdentifier)
    }, [props.modifierIdentifier])

    const handleSubmit = (procedureStatus) => {
        if (existingUser) {
            const params = {
                facility_id: props.facilityId.value,
                procedure_modifier: props.modifierIdentifier.value,
                procedure_date: props.surgeryDate,
                stoma_marking_date: props.stomaMeasuringAppointment,
                flow_type: props.flowType,
                flow_id: props.flowId
            };
            props.saveUserProcedure(params, props.nextPage, SUCCESS_VIEW);
        } else {
            props.setProcedureCorrectFlag(procedureStatus);
            handleOTP();
        }
    }

    const handleIncorrectProcedure = () => {
        setShowMessageModal(true)
    }

    const startsWithVowel = (word) => {
        var vowels = ("aeiouAEIOU");
        return vowels.indexOf(word[0]) !== -1;
    }


    const getModalBodyText = () => {
        if (existingUser) {
            return (
                <span>If it’s incorrect please contact your Facility for the correct QR Code</span>)
        } else {
            return (
                <span>If it’s incorrect, then you can contact your Secure Start<sup><span>SM</span></sup> Advisor on 1-888-808-7456 and can continue Signing Up.</span>
            )
        }
    }


    const handleOTP = () => {
        const params = {
            flow_type: flowType,
            flow_id: flowId,
            contact_number: trimCountryCodeFromPhoneNumber(phone),
        };
        props.generatePhoneOtp(params, props.nextPage, PHONE_VERIFY_VIEW);
    };


    return (
        <div className="text-center">
            <Media query={{maxWidth: 480}}>
                {(screenIsSmall) =>
                    screenIsSmall ? (
                        <></>
                    ) : (
                        <hr className="hr-line"/>
                    )
                }
            </Media>

            <div className="procedure-verification-content">
                <div>Please verify that your procedure is
                    <span
                        className="dark-highlight"> {modifierIdentifier ? modifierIdentifier.label.toUpperCase() : ""} </span> surgery.<br/><br/>
                    If it’s incorrect, then you can contact your Secure Start℠ advisor on&nbsp;
                    <Media query={{maxWidth: 480}}>
                        {
                            (screenIsSmall) => screenIsSmall
                                ? (
                                    <span className="highlight-text contact-number">
                        <a href="tel: 1-888-808-7456">
                      1-888-808-7456 </a></span>
                                ) : (
                                    <span className="highlight-text contact-number">
                      1-888-808-7456</span>

                                )
                        }
                    </Media>
                </div>
            </div>

            <Stack spacing={"17px"} className="procedure-verification-button">
                <PrimaryButton
                    onClick={() => handleSubmit(true)}
                    text="Yes, it's correct"
                />

                <SecondaryButton
                    onClick={() => handleIncorrectProcedure()}
                    cancel
                    text="No, it's incorrect"
                />
            </Stack>
            {/* </div>
      </div> */}

            <DisclaimerView
                title="We’ve got you!"
                body={getModalBodyText()}
                primaryButtonText="Continue Signing Up"
                open={showMessageModal}
                onAgree={() => {
                    handleSubmit(false)
                }}
                onDisagree={() => {
                }}
                onClose={() => {
                }}
            />
        </div>
    );
};

ProcedureVerificationView.propTypes = {
    flowId: PropTypes.string,
    flowType: PropTypes.string,
    isLoading: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    stomaMeasuringAppt: PropTypes.object,
    surgeryDate: PropTypes.object,
    procedure: PropTypes.string,
    facilityName: PropTypes.string,
    marketingConsent: PropTypes.string,
    existingUser: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    procedure: state.patientRegister.patientRegisterReducer.procedure,
    phone: state.patientRegister.patientRegisterReducer.phone,
    flowType: state.patientRegister.patientRegisterReducer.flowType,
    flowId: state.patientRegister.patientRegisterReducer.flowId,
    existingUser: state.patientRegister.patientRegisterReducer.existingUser,
    facilityCode: state.patientRegister.patientRegisterReducer.facilityCode,
    modifierIdentifier: state.patientRegister.patientRegisterReducer.modifierIdentifier,
    facilityId: state.patientRegister.patientRegisterReducer.facilityId,
    surgeryDate: state.patientRegister.patientRegisterReducer.surgeryDate,
    stomaMeasuringAppointment: state.patientRegister.patientRegisterReducer.stomaMeasuringAppt,
});

const mapDispatchToProps = (dispatch) => ({
    generatePhoneOtp: (params, nextPage, page) =>
        dispatch(generateOtp(params, nextPage, page)),
    saveUserProcedure: (params, nextPage, page) =>
        dispatch(saveProcedure(params, nextPage, page)),
    setProcedureCorrectFlag: (params) =>
        dispatch(setProcedureConfirmationFlag(params)),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
    key: "patientRegister",
    reducer: combineReducers({
        patientRegisterReducer,
    }),
});

export default compose(withReducer, withConnect)(ProcedureVerificationView);




