import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import DatePicker from "./../../../components/StyleGuideComponents/DatePicker";
import validate from "common/validator";
import { procedureDatesValidation } from "./../validators";
import { setSignUpData, setSignUpDataInStore } from "./../action";
import { PROCEDURE_DETAILS_VIEW } from "./../constants";
import { useSelector, useDispatch } from "react-redux";

const ProcedureDatesView = props => {

  const patientRegister = useSelector((store) => store.register.patientRegisterReducer)
  
  const dispatch = useDispatch()

  const [state, setState] = useState({
    stomaMeasuringAppt: patientRegister.stomaMeasuringAppt || null,
    surgeryDate: patientRegister.surgeryDate || null,
    errors: []
  })

  useEffect(() => {
    return () => {
      dispatch(
        setSignUpDataInStore(state)
      );
    };
  }, [state]);

  const handleChange = (name, value) => {
    let errors = state.errors
    delete errors[name]
    setState({ ...state, [name]: value, errors });
  };

  const onValidationFailure = (errors) => {
    setState({ ...state, errors });
  };

  const onNextValidationSuccess = () => {
    dispatch(setSignUpData(state, props.nextPage, PROCEDURE_DETAILS_VIEW))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validate(
      procedureDatesValidation,
      state,
      onValidationFailure,
      onNextValidationSuccess
    );
  }

    return (
      <div style={{paddingBottom:"-6%"}}>
        <form type="post">
          <div>
            <Stack sx={{zIndex:"0"}} spacing={"24px"}>
              <DatePicker
                name="stomaMeasuringAppt"
                value={state.stomaMeasuringAppt}
                label="Stoma Measuring Appointment"
                error={"stomaMeasuringAppt" in state.errors}
                errorText={
                  "stomaMeasuringAppt" in state.errors
                    ? state.errors.stomaMeasuringAppt
                    : ""
                }
                onChange={ handleChange }
              />

              <DatePicker
                name="surgeryDate"
                value={state.surgeryDate}
                label="Surgery Date"
                error={"surgeryDate" in state.errors}
                errorText={
                  "surgeryDate" in state.errors
                    ? state.errors.surgeryDate
                    : ""
                }
                onChange={ handleChange }
              />

            </Stack>
            <div className="continue-button-for-form">
              <PrimaryButton
                fullWidth
                text={props.nextButtonText}
                onClick={handleSubmit}
              />
              </div>
          </div>
        </form>

      </div>
    );
}

export default ProcedureDatesView

