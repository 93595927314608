import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ThemeProvider } from "@mui/material";
import { InputTextFieldTheme } from "./common/Themes";
import { InputBoxStyleGuide } from "./StyleGuideCSS";
import { IconButton } from "@material-ui/core";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import moment from "moment";
import { DATE_FORMAT } from "../../constants";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

export default function DatePickers(props) {

  const handleChange = (e) =>{
    props.onChange(props.name,e);
  }

  const clearButton = {
    endAdornment: (
      <IconButton
        aria-label="toggle password visibility"
        onClick={props.onChange}
      >
        <CancelOutlinedIcon
          fontSize="small"
          sx={{ color: "#575558", height: "20px", width: "20px" }}
        />
      </IconButton>
    ),
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {/* we are using same theme as we used for InputTextField */}
      <ThemeProvider theme={InputTextFieldTheme}>
        <FormControl>
          <DesktopDatePicker
            label={props.label || "Date Picker"}
            inputFormat={props.inputFormat || DATE_FORMAT}
            value={props.value || null}
            renderInput={(params) => (
              <TextField
                required={props.required}
                {...params}
                sx={{ fontSize: "16px" }}
                error={props.error || !!props.error}
                size="normal"
              />
            )}
            onChange={ handleChange }
          />
        </FormControl>
        <FormHelperText id="outlined-weight-helper-text" className="no-margin">
          <span
            style={{
              color: `${InputBoxStyleGuide.colorAlertNegative}`,
              fontSize: "12px",
            }}
          >
            {props.errorText ? props.errorText : ""}
          </span>
        </FormHelperText>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
