import {
    ADD_NEW_PROVIDER_REQUEST_FAILED,
    ADD_NEW_PROVIDER_REQUEST_SENT,
    ADD_NEW_PROVIDER_REQUEST_SUCCEED,
    GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_FAILED,
    GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SENT,
    GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SUCCEED,
    GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_FAILED,
    GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SENT,
    GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SUCCEED,
    PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_FAILED,
    PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_SENT,
    PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_SUCCEED,
    UPDATE_PROVIDER_INFO_REQUEST_FAILED,
    UPDATE_PROVIDER_INFO_REQUEST_SENT,
    UPDATE_PROVIDER_INFO_REQUEST_SUCCEED
} from "./constants";

const initialState = {
    show: false,
    isRequestInProgress: false,
    isGetAllPerformersRequestInProgress: false,
    users: [],
    performersOfUserTypeMaster: [],
    error: "",
    pagination: null,
};

export const userAdministrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SENT:
            return {
                ...state,
                isRequestInProgress: true,
                error: "",
                show: false,
            };
        case GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SUCCEED:
            return {
                ...state,
                isRequestInProgress: false,
                users: action.data.users,
                pagination: action.data.pagination,
            };
        case GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_FAILED:
            return {
                ...state,
                isRequestInProgress: false,
                error: action.data.error,
            };
        case ADD_NEW_PROVIDER_REQUEST_SENT:
            return {
                ...state,
                show: true,
                isRequestInProgress: true,
            };
        case ADD_NEW_PROVIDER_REQUEST_SUCCEED:
            return {
                ...state,
                show: false,
                isRequestInProgress: false,
            };
        case ADD_NEW_PROVIDER_REQUEST_FAILED:
            return {
                ...state,
                show: true,
                isRequestInProgress: false,
            };
        case UPDATE_PROVIDER_INFO_REQUEST_SENT:
            return {
                ...state,
                isRequestInProgress: true,
                show: true,
            };
        case UPDATE_PROVIDER_INFO_REQUEST_SUCCEED:
            return {
                ...state,
                isRequestInProgress: false,
                show: false,
            };
        case UPDATE_PROVIDER_INFO_REQUEST_FAILED:
            return {
                ...state,
                isRequestInProgress: false,
                show: true,
            };
        case GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SENT:
            return {
                ...state,
                isGetAllPerformersRequestInProgress: true,
                error: "",
            };
        case GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SUCCEED:
            return {
                ...state,
                isGetAllPerformersRequestInProgress: false,
                performersOfUserTypeMaster: action.data.performers,
            };
        case GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_FAILED:
            return {
                ...state,
                isGetAllPerformersRequestInProgress: false,
                error: "Error",
            };

        case PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_SENT:
            return {
                ...state,
                isRequestInProgress: true,
                error: "",
            };
        case PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_SUCCEED:
            return {
                ...state,
                isRequestInProgress: false,
                error: "",
            };

        case PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_FAILED:
            return {
                ...state,
                isRequestInProgress: false,
                error: "",
            };
        default:
            return state;
    }
};
