import React, {PureComponent} from 'react';
import {getItemFromStorage} from 'services/storage';
import {isMasquerading} from 'utils/common';
import isEmpty from 'lodash/isEmpty';

import './masqueradingHeader.scss';

class MasqueradingHeader extends PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const proxyUser = isEmpty(getItemFromStorage('proxyUser')) ? null : JSON.parse(getItemFromStorage('proxyUser'));
    return (
      isMasquerading() && proxyUser ? (
        <div className="masquerading-header">
          <span>
            {`Viewing as ${proxyUser.firstName} ${proxyUser.lastName}`}
          </span>
        </div>
      ) : null
    );
  }
}

export default MasqueradingHeader;
