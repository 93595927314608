import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import MUIAutocompleteSelect from "../../../components/SelectV2/MUIAutocompleteSelect";
import { compose } from "redux";
import { patientRegisterReducer} from "./../reducers";
import validate from "common/validator";
import { procedureDetailsValidation } from "./../validators";
import { setSignUpData, setSignUpDataInStore } from "./../action";
import DisclaimerView from "./DisclaimerView";
import { SERVICE_AGREEMENT_VIEW } from "./../constants";
import { withRouter } from "react-router";
import { createFilterOptions } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector, useDispatch } from "react-redux";
import strings from "../../../strings";
import {
  getAllLocationsRequest,
  getAllProceduresModifiersRequest,
} from "common/actions";

const ProcedureDetailsView = props => {

  const modifierIdentifier = useSelector((store) => store.register.patientRegisterReducer.modifierIdentifier)
  const facilityId = useSelector((store) => store.register.patientRegisterReducer.facilityId)
  const educationPreference = useSelector((store) => store.register.patientRegisterReducer.educationPreference)
  const allLocationsReducer = useSelector((store) => store.register.allLocationsReducer)
  const allProceduresModifiersReducer = useSelector((store) => store.register.allProceduresModifiersReducer)

  const dispatch = useDispatch()

  const getModifierIdentifier = () => {
    if (allProceduresModifiersReducer.allModifiers.length > 0) {
      let modifierIdentifierParam = allProceduresModifiersReducer.allModifiers.find((modifier) => {
        return (
          modifier.modifierIdentifier === props.match.params.procedure
        );
      });
      if (modifierIdentifierParam) {
          setState({...state, modifierIdentifier: {label: modifierIdentifierParam.value,
            value: modifierIdentifierParam.modifierIdentifier}})
        }else{
          setState({...state, modifierIdentifier })
        }
      }
    }

  const [state, setState] = useState({
    modifierIdentifier,
    modifierIdentifierModal: false,
    facilityId,
    educationPreferenceModal: false,
    educationPreference,
    errors: [],
  })

  useEffect(()=>{
    getModifierIdentifier()
    if(allProceduresModifiersReducer.allModifiers.length <= 0 ) dispatch(getAllProceduresModifiersRequest())
    if(allLocationsReducer.allLocations.length <= 0) dispatch(getAllLocationsRequest())
  },[])

  useEffect(() => {
    return () => {
      dispatch(
        setSignUpDataInStore(state)
      );
    };
  }, [state]);

 

  const handleChange = (name, value) => {
    let errors = state.errors;
    delete errors[name];
    setState({ ...state, [name]: value, errors });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validate(
      procedureDetailsValidation,
      state,
      onValidationFailure,
      onNextValidationSuccess
    );
  };

  const checkIfButtonEnabled = () => state.modifierIdentifier &&
                                     state.facilityId &&
                                     state.modifierIdentifier.value &&
                                     state.facilityId.value

  const onValidationFailure = errors => setState({ ...state, errors })

  const onNextValidationSuccess = () => dispatch(setSignUpData(state, props.nextPage, SERVICE_AGREEMENT_VIEW))

  const onInfoModalClose = () => setState({...state, modifierIdentifierModal: false, educationPreferenceModal: false })

  const filteredFacilityOptions = () => {
    let options = [];
    if(allLocationsReducer.allLocations.length > 0){
        let first_element = allLocationsReducer.allLocations.find(location => location.code ==='NOTSUREFC');
        let last_element = allLocationsReducer.allLocations.find(location => location.code ==='OTHERFC');
        if(first_element) options.push({label: first_element.name, value: first_element.id, city: first_element.city, state: first_element.state})
        if(last_element) options.push({label: last_element.name, value: last_element.id, city: last_element.city, state: last_element.state})
    }
    return options;
 }

 const filterFacilityOptionsList = () =>{
    let _filterOptions = createFilterOptions({
        stringify: (option) =>
          option.label + " " + option.city + " " + option.state,
      })
      const localFilterOptions = (options, state) => {
        let results = [filteredFacilityOptions()[0], ..._filterOptions(options, state),filteredFacilityOptions()[1]];
        return results;
      };
      return localFilterOptions;
 }

    return (
      <div style={{ paddingBottom: "-6%" }}>
        <DisclaimerView
          title={state.educationPreferenceModal ?  strings.EDUCATION_PREFERENCE_INFO_MODAL_HEADING : strings.PROCEDURE_INFO_MODAL_HEADING}
          body={<div dangerouslySetInnerHTML={{__html: state.educationPreferenceModal ?  strings.EDUCATION_PREFERENCE_INFO_MODAL_BODY : strings.PROCEDURE_INFO_MODAL_BODY}} />}
          primaryButtonText="Got it!"
          open={state.educationPreferenceModal || state.modifierIdentifierModal}
          onClose={onInfoModalClose}
          onAgree={onInfoModalClose}
        />
        <form type="post">
          <div> 
            <Stack sx={{ zIndex: "0" }} spacing={"24px"}>
              <div className="procedure-detail-input-wrapper">
                <div className="procedure-details-label">
                  Search by Facility Name or city and state
                  <span className="app-red">
                    *<></>
                  </span>
                </div>
                <MUIAutocompleteSelect
                  multiline
                  getOptionLabel={ 
                    option => { 
                       return option.label 
                     
                    } 
                  }
                  isOptionEqualToValue={(option, value) => { if(option){ return option.value || null || undefined === value}}}
                  placeholder={"Select your facility"}
                  filterOptions={
                    filterFacilityOptionsList()
                  }
                  required={true}
                  name="facilityId"
                  options={
                    allLocationsReducer.allLocations.length > 0 ? allLocationsReducer.allLocations.filter(loc=>loc.code!=='NOTSUREFC' && loc.code!=='OTHERFC').map(location=>{
                      return {
                        label: location.name,
                        value: location.id,
                        city: location.city,
                        state: location.state
                        }
                    }):[]
                  }
                  value={state.facilityId}
                  onChange={handleChange}
                  autoCompleteTheOptions
                />
              </div>
              <div className="procedure-detail-input-wrapper">
                <div className="procedure-details-label">
                  Procedure Details<span className="app-red">*</span>
                  <InfoOutlinedIcon sx={{height: "25px",width: "25px"}} onClick={()=>setState({...state, modifierIdentifierModal: true})} />
                </div>
                <MUIAutocompleteSelect
                  getOptionLabel={ 
                    option => { 
                       return option.label 
                    
                    } 
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label && option.value === value.value
                  }
                  required={true}
                  placeholder="Select your procedure"
                  name="modifierIdentifier"
                  options={
                    allProceduresModifiersReducer.allModifiers.length > 0 && allProceduresModifiersReducer.allModifiers.map((modifier) => {
                    return {
                      label: modifier.value,
                      value: modifier.modifierIdentifier,
                    };
                  })
                  }
                  value={state.modifierIdentifier}
                  onChange={handleChange}
                />
              </div>
              <div className="procedure-detail-input-wrapper">
                <div className="procedure-details-label">
                  Education Preferences
                  <InfoOutlinedIcon sx={{height: "25px",width: "25px"}} onClick={()=>setState({...state, educationPreferenceModal: true})} />
                </div>
                <MUIAutocompleteSelect
                  placeholder="Select your education preference"
                  required={true}
                  name="educationPreference"
                  options={[
                    { label: "View for my procedure only", value: false },
                    { label: "View for all stoma types", value: true },
                  ]}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label && option.value === value.value
                  }
                  value={state.educationPreference}
                  onChange={handleChange}
                />
              </div>
            </Stack>
            <div className="continue-button-for-form">
              <PrimaryButton
                disabled={!checkIfButtonEnabled()}
                fullWidth
                text={props.nextButtonText}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    );
}

export default compose(withRouter)(ProcedureDetailsView);
