import React from 'react';
import InputMask from 'react-input-mask';

class PhoneInput extends React.PureComponent {// eslint-disable-line react/prefer-stateless-function
    countryMasks = {
        US: '+1\ 999 999 9999',
        AU: '+61\ 09 9999 9999',
    }

    render() {
        return <InputMask {...this.props} mask={this.countryMasks[this.props.country]} maskChar={null} type="tel"/>;
    }
}

export default PhoneInput;
