import axios from 'axios';
import {getAuthHeaders} from 'services/storage';
import {
    GET_DOWNLOAD_DATA_FILE,
    GET_DOWNLOAD_DATA_REQUEST,
    POST_DOWNLOAD_DATA_REQUEST,
    SHOW_DOWNLOAD_DATA_REQUEST,
} from '../constants';
import {nonMasqueradingInstance} from '../base';

export const getDownloadDataRequest = () => nonMasqueradingInstance.get(GET_DOWNLOAD_DATA_REQUEST);

export const postDownloadDataRequest = (params) => nonMasqueradingInstance.post(POST_DOWNLOAD_DATA_REQUEST, params);

export const getDownloadFileRequest = () => nonMasqueradingInstance.get(GET_DOWNLOAD_DATA_FILE);

export const showDownloadDataRequest = (params) => axios.post(SHOW_DOWNLOAD_DATA_REQUEST, params);

export const downloadFile = () => {
    const authHeaders = getAuthHeaders();
    return axios.get(GET_DOWNLOAD_DATA_FILE, {headers: authHeaders});
};
