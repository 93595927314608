/*
 *
 * CONVERSTAION MESSAGES constants
 *
 */

export const GET_CONVERSATION_MESSAGES_REQUEST_SENT = 'app/messages/GET_CONVERSATION_MESSAGES_REQUEST_SENT';
export const GET_CONVERSATION_MESSAGES_REQUEST_SUCCEED = 'app/messages/GET_CONVERSATION_MESSAGES_REQUEST_SUCCEED';
export const GET_CONVERSATION_MESSAGES_REQUEST_FAILED = 'app/messages/GET_CONVERSATION_MESSAGES_REQUEST_FAILED';

export const SEND_MESSAGE_REQUEST_SENT = 'app/messages/SEND_MESSAGE_REQUEST_SENT';
export const SEND_MESSAGE_REQUEST_SUCCEED = 'app/messages/SEND_MESSAGE_REQUEST_SUCCEED';
export const SEND_MESSAGE_REQUEST_FAILED = 'app/messages/SEND_MESSAGE_REQUEST_FAILED';
