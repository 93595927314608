import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

import AuthenticatedRoute, {
  MedStaffAuthorizedRoute,
  PatientAuthorizedRoute,
} from "containers/AuthRoute";

import Header from "components/Header";
import * as Sentry from "@sentry/react";
import Toasters from "components/Toasters";
import Media from "react-media";

import Dashboard from "containers/Dashboard/Loadable";
import Login from "containers/Login/Loadable";
import ForgotPassword from "containers/ForgotPassword/Loadable";
import NotFoundPage from "containers/NotFoundPage/Loadable";
import PatientDetails from "containers/PatientDetails/Loadable";
import Profile from "containers/Profile/Loadable";
import PatientSearch from "containers/PatientSearch/Loadable";
import NotifyPatient from "containers/NotifyPatient/Loadable";
import LookupSearch from "containers/LookupSearch/loadable";
import ChangePassword from "containers/ChangePassword/loadable";
import Notifications from "containers/Notifications/loadable";
import ManagePatientAdvocate from "containers/ManagePatientAdvocate/loadable";
import ManagePatients from "containers/ManagePatients/loadable";
import SurveyFeedback from "containers/SurveyFeedback/Loadable";
import CommunicationLogDetails from "containers/CommunicationLogDetails/Loadable";
import UnauthorizedUser from "containers/UnauthorizedUser/loadable";
import SelectProcedure from "patientApp/containers/SelectProcedure";
import CompletedProcedure from "patientApp/containers/CompletedProcedure";
import PatientDashboard from "patientApp/containers/Dashboard";
import ManageProcedures from "patientApp/containers/ManageProcedures";
import QuestionSearch from "patientApp/containers/Questions/QuestionSearch";
import SurveyFeedbackPatient from "patientApp/containers/SurveyFeedback";
import CreateQuestion from "patientApp/containers/CreateQuestion/Loadable";
import { getItemFromStorage } from "services/storage";
import ManageCaregivers from "containers/ManageCaregivers/loadable";
import RegistrationSuccess from "containers/RegistrationSuccessContainer";
import PassiveMode from "patientApp/containers/PassiveMode/loadable";
import PassiveModeInfo from "patientApp/containers/PassiveModeInfo/index";
import PassiveModeInfoWithOTP from "patientApp/containers/PassiveModeInfoWithOTP/index";
import ViewDataDownloadRequest from "patientApp/containers/ViewDataDownloadRequest/index";
import ViewShareOutcomesRequest from "patientApp/containers/ViewShareOutcomesRequest/index";
import ForceResetPassword from "containers/ForceResetPassword/loadable";
import DepartmentSurveys from "containers/DepartmentSurveys/loadable";
import Logout from "containers/Logout";
import ManageProcedureManagers from "containers/ManageProcedureManagers/loadable";
import PatientQuestionsDetails from "patientApp/containers/Questions/QuestionDetails/Loadable";
import SelectPatient from "patientApp/Caregiver/SelectPatient";
import UnSupportedPageView from "containers/UnSupportedPageView";
import EhrMessageFormInterface from "containers/EhrMessageFormInterface/loadable";
import EhrMessage from "containers/EhrMessage/loadable";
import EhrSummary from "containers/EhrSummary/loadable";
import XealthPatientDetails from "containers/Xealth/V1/index";
import Conversations from "containers/Conversations";
import TelemedicineAppointment from "containers/TelemedicineAppointment/Lodable";
import PatientRegister from "containers/PatientRegister";
import UserAdministration from "containers/UserAdministration";
import LocationAdministration from "containers/LocationAdministration";

import { USER_TYPE_PATIENT, USER_TYPE_CAREGIVER } from "../../constants";

{
  /* <div className={(this.props.location.pathname.indexOf('telemedicine-appointment') === -1)?"app-container":""}>
{!getItemFromStorage('xealth') && this.props.location.pathname.indexOf('telemedicine-appointment') === -1 ?
  (
    <Media query={{ maxWidth: 767 }}>
      {
        (screenIsSmall) => screenIsSmall
          ? (this.showHeader()) : <Header />
      }
    </Media>) : null } */
}

class App extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  showHeader = () => {
    let currentUser = null;

    if (getItemFromStorage("currentUser")) {
      currentUser = JSON.parse(getItemFromStorage("currentUser"));
      return currentUser &&
        (currentUser.type === USER_TYPE_PATIENT ||
          currentUser.type === USER_TYPE_CAREGIVER) ? (
        <Header />
      ) : null;
    }
    return <Header />;
  };

  render() {
    return (
      <div>
        <Sentry.ErrorBoundary
          /* eslint-disable no-unused-vars */
          fallback={({ error, componentStack, resetError }) => (
            <React.Fragment>
              <div className="error-container"></div>
            </React.Fragment>
          )}
          /* eslint-enable no-unused-vars */
        >
          <div
            className={
              this.props.location.pathname.indexOf("patient-signup") === -1
                ? "app-container"
                : ""
            }
          >
            {/* !getItemFromStorage('xealth') && this.props.location.pathname.indexOf('telemedicine-appointment') === -1 */}
            {!getItemFromStorage("xealth") &&
            this.props.location.pathname.indexOf("patient-signup") === -1 ? (
              <Media queries={{ screenIsSmall: "(max-width: 767px)", deviceOrientation: "(orientation: landscape)", screenIsMedium: "(max-width: 1023px)" }}>
                {(matches) =>
                  (matches.deviceOrientation && matches.screenIsMedium) || matches.screenIsSmall ? this.showHeader() : <Header />
                }
              </Media>
            ) : null}
            <Toasters />
            <Switch>
              <Route exact path="/signin" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route
                exact
                path={["/patient-signup","/patient-signup/:procedure"]}
                component={PatientRegister}
              />
              <Route
                path="/account-creation-confirmation"
                component={RegistrationSuccess}
              />
              <Route
                exact
                path="/passive-mode/interactions/:key"
                component={PassiveMode}
              />
              <Route
                exact
                path="/passive-mode/interactions/survey/:eventId([0-9]+)"
                component={SurveyFeedbackPatient}
              />
              <Route
                exact
                path="/passive-mode/info"
                component={PassiveModeInfo}
              />
              <Route
                exact
                path="/passive-mode/otp"
                component={PassiveModeInfoWithOTP}
              />
              <Route
                exact
                path="/view-data-download-request/:key"
                component={ViewDataDownloadRequest}
              />
              <Route
                exact
                path="/view-share-outcomes-request/:key"
                component={ViewShareOutcomesRequest}
              />
              <Route
                exact
                path="/xealth/v1/programs"
                component={XealthPatientDetails}
              />

              <MedStaffAuthorizedRoute exact path="/users" component={UserAdministration} />
              <MedStaffAuthorizedRoute path="/locations" component={LocationAdministration} />

              <MedStaffAuthorizedRoute exact path="/" component={Dashboard} />
              <MedStaffAuthorizedRoute
                exact
                path="/patients/:patientId/procedures/:procedureId"
                component={PatientDetails}
              />
              <MedStaffAuthorizedRoute
                path="/patients/:patientId/procedures/:procedureId/conversations/:conversationId"
                component={Conversations}
              />
              <MedStaffAuthorizedRoute
                path="/search/patients"
                component={PatientSearch}
              />
              <MedStaffAuthorizedRoute
                path="/lookup/:key/:category"
                component={LookupSearch}
              />
              <MedStaffAuthorizedRoute
                path="/notify/:procedureId/patients/:patientId"
                component={NotifyPatient}
              />
              <MedStaffAuthorizedRoute
                path="/manage-patient-advocates"
                component={ManagePatientAdvocate}
              />
              <MedStaffAuthorizedRoute
                path="/manage-patient-advocate/:patientAdvocateId/patients"
                component={ManagePatients}
              />
              <MedStaffAuthorizedRoute
                path="/patients/:patientId/procedures/:procedureId/communication-log/:logId"
                component={CommunicationLogDetails}
              />
              <MedStaffAuthorizedRoute
                path="/manage-caregivers/:patientId/caregivers"
                component={ManageCaregivers}
              />
              <MedStaffAuthorizedRoute
                exact
                path="/surveys"
                component={DepartmentSurveys}
              />
              <MedStaffAuthorizedRoute
                exact
                path="/patients/:patientId/procedures/:procedureId/managers"
                component={ManageProcedureManagers}
              />
              <MedStaffAuthorizedRoute
                exact
                path="/unSupportedPageView"
                component={UnSupportedPageView}
              />
              <MedStaffAuthorizedRoute
                path="/patients/:patientId/procedures/:procedureId/surveys/:eventId"
                exact
                component={SurveyFeedback}
              />
              <MedStaffAuthorizedRoute
                path="/patients/:patientId/procedures/:procedureId/telemedicine-appointment/:roomSid/call-title/:callTitle/name/:patientName"
                exact
                component={TelemedicineAppointment}
              />
              <MedStaffAuthorizedRoute
                path="/telemedicine_appointment_rooms/:id/remind_participant/:user_id"
                exact
                component={TelemedicineAppointment}
              />

              <AuthenticatedRoute path="/profile" component={Profile} />
              <AuthenticatedRoute
                path="/change-password"
                component={ChangePassword}
              />
              <AuthenticatedRoute
                path="/notifications"
                component={Notifications}
              />
              <AuthenticatedRoute
                path="/reset-password"
                component={ForceResetPassword}
              />

              <MedStaffAuthorizedRoute
                path="/ehr-messages/:tab"
                component={EhrMessage}
              />
              <MedStaffAuthorizedRoute
                path="/view-ehr-message/:messageId([0-9]+)"
                component={EhrMessageFormInterface}
              />
              <MedStaffAuthorizedRoute
                path="/ehr-message-summary/:messageId([0-9]+)"
                component={EhrSummary}
              />

              {/* Commenting out patient routes as we dont have patient login on web for holister */}

              {/* <PatientAuthorizedRoute
                path="/select-procedure"
                component={SelectProcedure}
              /> */}
              {/* <PatientAuthorizedRoute
                path="/mydashboard/:compositeProcedureId([0-9]+)"
                component={PatientDashboard}
              /> */}
              {/* <PatientAuthorizedRoute
                path="/manageprocedures"
                component={ManageProcedures}
              />
              <PatientAuthorizedRoute
                exact
                path="/search/questions"
                component={QuestionSearch}
              />
              <PatientAuthorizedRoute
                path="/questions/:compositeProcedureId([0-9]+)/details/:questionId"
                exact
                component={PatientQuestionsDetails}
              />
              <PatientAuthorizedRoute
                path="/questions/:compositeProcedureId([0-9]+)/ask-question"
                component={CreateQuestion}
              />
              <PatientAuthorizedRoute
                path="/surveys/:compositeProcedureId([0-9]+)/feedback/:eventId([0-9]+)"
                component={SurveyFeedbackPatient}
              />
              <PatientAuthorizedRoute
                path="/select-patient"
                component={SelectPatient}
              />
              <PatientAuthorizedRoute
                exact
                path="/post-complete-procedure"
                component={CompletedProcedure}
              /> */}

              <Route exact path="/logout" component={Logout} />
              <Route path="/unauthorized-access" component={UnauthorizedUser} />
              <Route path="/not-found" component={NotFoundPage} />
              <Redirect to="/not-found" />
            </Switch>
          </div>
        </Sentry.ErrorBoundary>
      </div>
    );
  }
}

export default withRouter(App);
