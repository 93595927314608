import React from "react"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {combineReducers, compose} from "redux";
import injectReducer from "utils/injectReducer";
import {
    onAddNewProviderRequest,
    onfetchAllUsersOfTheDepartmentRequest,
    onfetchPerformersOfUserTypeMasterRequest,
    providerAccountStatusChangeAction,
    updateProviderInfoAction
} from "./actions";

import {userAdministrationReducer} from "./reducer";
import Select, {MultiSelect} from "components/SelectV2";
import PhoneInput from 'components/PhoneInput';
import LightboxModal from 'components/LightboxModal';
import HelpBlock from 'components/HelpBlock';
import {Modal} from 'react-bootstrap/lib';
import validate, {clearErrorsForField} from 'common/validator';
import validationConfig from './validator';
import './user_administration.scss';
import Pagination from 'components/Pagination';
import {getItemFromStorage} from 'services/storage';
import Tab from 'react-bootstrap/lib/Tab';
import Nav from 'react-bootstrap/lib/Nav';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import NavItem from 'react-bootstrap/lib/NavItem';
import Emptyview from 'components/Emptyview';
import NoProviders from '../../assets/images/no_providers.png';
import RadioButton from 'components/RadioButton';
import ConfirmationModal from 'patientApp/components/ConfirmationModal';

const userTypeOptions = [
    {value: "master", label: "Advisor/Provider", type: "PatientAdvocate"},
    {value: "support", label: "Advisor/Clinical user", type: "PatientAdvocate"},
];

class UserAdministration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUserDetails: null,
            selectedProvider: {isActive: false, id: null},
            showConfirmResolutionModal: false,
            currentUserDetails: null,
            showAddEditUserInfoModal: false,
            userId: null,
            userFirstName: '',
            userLastName: '',
            userEmail: '',
            contactNumber: '',
            ehrIdentifier: '',
            providerType: '',
            userType: '',
            emailDisabled: false,
            contactNumberDisabled: false,
            userTypeDisabled: false,
            users: [],
            primaryPhysicians: [],
            errors: {},
            page: 1,
            filterNameOrEmail: '',
            previousFilterData: {
                previousFilterNameOrEmail: '',
            }
        };
    }

    componentDidMount = () => {

        const currentUser = JSON.parse(getItemFromStorage('currentUser'));

        this.setState({currentUserDetails: currentUser});

        if (!(currentUser.hasSuperUserPrivileges && currentUser.isAdmin)) {
            this.props.history.push('/not-found');
        } else {
            this.props.fetchAllUsersOfTheDepartment({'page': this.state.page})
            this.props.fetchPerformersOfUserTypeMaster();
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isRequestInProgress === false && prevProps.isRequestInProgress === true) {
            this.setState({
                showAddEditUserInfoModal: this.props.show
            })
        }
    }

    extractIdsFromObjects = (objects) => objects.map((item) => item.value)

    onSetFilter = (isPagination) => {
        const {
            filterNameOrEmail
        } = this.state;

        const page = isPagination ? this.state.page : 1

        let currentData = {filterNameOrEmail, 'page': this.state.page}
        if (this.isFilterUpdated(currentData, this.state.previousFilterData)) {
            this.props.fetchAllUsersOfTheDepartment({'page': page, name: filterNameOrEmail});
            this.setState({
                page: page,
                previousFilterData: {
                    previousPage: this.state.page,
                    previousFilterNameOrEmail: this.state.filterNameOrEmail,
                }
            })
        }
    }

    onResetFilter = () => {
        this.setState({
            filterNameOrEmail: '',
            resetTriggered: true,
            page: 1,
            errors: {},
            previousFilterData: {
                previousPage: 1,
                previousFilterNameOrEmail: '',
            }
        }, () => {
            this.props.fetchAllUsersOfTheDepartment({'page': this.state.page})
        });
    }

    onAddEditUserInfoModal = (event, user) => {
        const {
            id,
            firstName,
            lastName,
            email,
            contactNumber,
            ehrIdentifier,
            type,
            userType,
        } = user || {};

        const selectedPrimaryPhysicians = this.getSelectedPrimaryPhysicians(id);

        this.setState({
            userId: id ? id : null,
            userFirstName: firstName ? firstName : "",
            userLastName: lastName ? lastName : "",
            userEmail: email ? email : "",
            contactNumber: contactNumber ? contactNumber : "",
            ehrIdentifier: ehrIdentifier ? ehrIdentifier : "",
            providerType: type ? type : "",
            userType: userType && type ? userTypeOptions.find((item) => item.value === userType && item.type === type) : "",
            showAddEditUserInfoModal: true,
            primaryPhysicians: selectedPrimaryPhysicians ? selectedPrimaryPhysicians : [],
            emailDisabled: email ? true : false,
            contactNumberDisabled: contactNumber ? true : false,
            userTypeDisabled: userType ? true : false,
            errors: {},
        });
    }

    handlePagination = (page) => {
        this.setState({page}, () => {
            this.onSetFilter(true);
        });
    }

    getSelectedPrimaryPhysicians = (id) => {
        const selectedUser = this.props.users.find((item) => item.id === id);
        const selectedItems = selectedUser && selectedUser.primaryPhysicians.map((item) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
        }));
        return selectedItems;
    }

    changeFieldHandler = (e) => {
        const obj = {};
        obj[e.target.name] = e.target.value;
        obj.errors = clearErrorsForField(this.state.errors, e.target.name);
        this.setState(obj);
    };

    changeContactNumberHandler = (e) => {
        const contactNumber = e.target.value.trim();
        this.setState({
            contactNumber,
            errors: clearErrorsForField(this.state.errors, 'contactNumber'),
        });
    }

    selectUserType = (selectedOption) => {
        this.setState({
            userType: selectedOption,
            providerType: selectedOption.type,
            primaryPhysicians: selectedOption.value === 'master' ? [] : this.state.primaryPhysicians,
            errors: clearErrorsForField(this.state.errors, 'userType'),
            errors: clearErrorsForField(this.state.errors, 'providerType')
        });
    }

    selectPrimaryPhysicians = (selectedValues) => {
        this.setState({
            primaryPhysicians: selectedValues,
            errors: clearErrorsForField(this.state.errors, 'primaryPhysicians')
        });
    }

    onAddProviderInfo = () => {
        this.validateAddEditProviderFormValues()
    }

    onEditProviderInfo = () => {
        this.validateAddEditProviderFormValues()
    }

    validateAddEditProviderFormValues = () => {
        validate(validationConfig, this.state, this.onFormValidationFailure, this.onFormValidationSuccess);
    }

    onFormValidationFailure = (errors) => {
        this.setState({errors});
    }

    changeFilterNameOrEmail = (e) => {
        this.setState({filterNameOrEmail: e.target.value});
    }

    isFilterUpdated = (currentData, previousData) => {
        return (currentData.page !== previousData.previousPage) ||
            (currentData.filterNameOrEmail.trim().toUpperCase() !== previousData.previousFilterNameOrEmail.trim().toUpperCase())
    }


    onFormValidationSuccess = () => {
        const {primaryPhysicians} = this.state;
        let contactNumber = this.props.country === 'AU' ?
            this.state.contactNumber.replace('+61', '') : this.props.country === 'UK' ? this.state.contactNumber.replace('+44', '') : this.state.contactNumber.replace('+1', '');
        contactNumber = contactNumber.replace(/ /g, '');

        const params = {
            first_name: this.state.userFirstName,
            last_name: this.state.userLastName,
            email: this.state.userEmail,
            contact_number: contactNumber ? contactNumber : null,
            ehr_identifier: this.state.ehrIdentifier ? this.state.ehrIdentifier : null,
            provider_type: this.state.providerType,
            user_type: this.state.userType.value,
            pagination: {
                page: this.state.page,
                name: this.state.filterNameOrEmail,
            }
        };

        if (primaryPhysicians) {
            params.performer_ids = primaryPhysicians.map((item) => item.value)
        }

        if (this.state.userId) {
            params.id = this.state.userId;

            this.props.updateProviderInfo(
                params,
            );
        } else {
            this.props.addNewProvider(
                params,
            );
        }
    }

    hideAddEditUserInfoModal = () => {
        this.setState({
            showAddEditUserInfoModal: false,
            errors: {},
        });
    };

    handleProviderAccountStatus = (id, isActive) => {
        this.setState({
            showConfirmResolutionModal: true,
            selectedProvider: {...this.state.selectedProvider, isActive: isActive, id}
        });
    }

    onModalClose = () => {
        this.setState({
            showConfirmResolutionModal: false,
            selectedProvider: {...this.state.selectedProvider, isActive: false}
        });
    }

    onConfirmResolveConversation = () => {
        this.setState({showConfirmResolutionModal: false},
            () => {
                this.props.providerAccountStatusChange({
                    'page': this.state.page,
                    id: this.state.selectedProvider.id,
                    is_active: this.state.selectedProvider.isActive,
                    should_refetch_performers: this.props.users.filter((user) => user.id == this.state.selectedProvider.id && user.userType == 'master').length > 0
                });
            });
    }

    renderFilters = () => {
        return (
            <div className="filters-container">
                <div className="filters-label">
                    Filters
                </div>
                <div className="row filter-wrapper">

                    <div className="col-xs-12">
                        <label htmlFor="filterNameOrEmail">Name/Email</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type Name or Email here"
                            onChange={this.changeFilterNameOrEmail}
                            value={this.state.filterNameOrEmail}
                            name="nameOrEmail"
                        />
                    </div>
                    <div className="col-xs-12 btn-wrapper">
                        <button
                            className={`btn btn-default clear-all ${this.state.isFilterApplied ? '' : 'cursor-pointer'}`}
                            onClick={this.onResetFilter}
                        >
                            Clear All
                        </button>
                        <button
                            className="btn btn-primary apply-button"
                            onClick={() => this.onSetFilter(false)}
                        >
                            Apply Filters
                        </button>
                    </div>
                </div>
            </div>
        );
    }


    renderData = () => {
        return (
            <>
                {this.props.users.length > 0 ?
                    <div>
                        <div>
                            <div className="ui-table">
                                <table className="table">
                                    <thead className="sticky-header">
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>User type</th>
                                        <th>Primary Users</th>
                                        <th>Edit</th>
                                        <th>Account Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.props.users.map((user) => (
                                            <tr key={user.id}>
                                                <td>{user.firstName} {user.lastName}</td>
                                                <td>{user.email}</td>
                                                <td>
                                                    {userTypeOptions.find((item) => item.value === user.userType && item.type === user.type).label}
                                                </td>
                                                <td>
                                                    {
                                                        user.primaryPhysicians.length > 0 ? user.primaryPhysicians.map((item) => (
                                                            <p key={item.id}>{item.firstName} {item.lastName}</p>
                                                        )) : '- -'

                                                    }
                                                </td>
                                                <td>
                                                    <span className="icon icon-font-a-write-message cursor-pointer"
                                                          onClick={(event) => this.onAddEditUserInfoModal(event, user)}/>
                                                </td>
                                                <td className="account-status">
                                                    <RadioButton
                                                        onToggle={() => this.handleProviderAccountStatus(user.id, user.isActive)}
                                                        value={user.isActive}
                                                        defaultValue={user.isActive}
                                                        disabled={
                                                            this.state.currentUserDetails && this.state.currentUserDetails.id === user.id
                                                                ? true
                                                                : false}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div style={{marginTop: '20px'}}>
                                {this.props.pagination &&
                                <Pagination pagination={this.props.pagination} title="Providers"
                                            handlePagination={this.handlePagination}/>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Emptyview imgSrc={NoProviders} className="" message="No Users"/>
                }
            </>
        )
    }

    renderChildComponents = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-3 col-md-3 col-lg-3 filter-container-wrapper" style={{position: 'fixed'}}>
                        {this.renderFilters()}
                    </div>
                    <div className="col-sm-9 col-md-9 col-lg-9 record-list-wrapper" style={{float: 'right'}}>
                        <div className="col-sm-12 col-md-12 col-lg-12" style={{marginBottom: '25px'}}>
              <span className="add-new-link" onClick={this.onAddEditUserInfoModal}>
                <span className="icon icon-font-a-add-new-task"></span>
                Add New User
              </span>
                            <span className="pagination">
                {this.props.pagination &&
                <Pagination pagination={this.props.pagination} title="Providers"
                            handlePagination={this.handlePagination}/>
                }
              </span>
                        </div>
                        <div className="clr"></div>
                        {this.renderData()}
                    </div>
                </div>
            </React.Fragment>)
    }

    render() {
        return (
            <>
                {this.props.manageUsersEnabled &&
                <div className="col-xs-12 content-administration">
                    <div className="admin-title-container container-fluid">

                        <LightboxModal
                            show={
                                this.props.isRequestInProgress ||
                                this.props.isGetAllPerformersRequestInProgress
                            }
                        />

                        <Tab.Container id="admin-activity-tabs">
                            <React.Fragment>
                                <div className='admin-navigation-div'>
                                    <div className="admin-navigation-section">
                                        <Nav bsStyle="pills" justified>
                                            <LinkContainer to="/users" replace>
                                                <NavItem eventKey="providers">
                                                    <React.Fragment>
                                                        <i className="icon icon-font-a-patient-under-physician"></i>
                                                        <span className="icon-label">Users</span>
                                                    </React.Fragment>
                                                </NavItem>
                                            </LinkContainer>
                                            <LinkContainer to="/locations" replace>
                                                <NavItem eventKey="locations">
                                                    <React.Fragment>
                                                        <i className="icon icon-font-a-location"></i>
                                                        <span className="icon-label">Treatment Centers</span>
                                                    </React.Fragment>
                                                </NavItem>
                                            </LinkContainer>
                                        </Nav>
                                    </div>
                                </div>
                                <div className="tab-content-wrapper">
                                    <Tab.Content>
                                        {
                                            this.renderChildComponents()
                                        }
                                    </Tab.Content>
                                </div>
                            </React.Fragment>
                        </Tab.Container>
                    </div>

                    <Modal
                        show={this.state.showAddEditUserInfoModal}
                        onHide={this.hideAddEditUserInfoModal}
                        container={document.body}
                        autoFocus
                        aria-labelledby="contained-modal-title"
                        backdrop="static"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title">
                                {this.state.userId ? `Edit User Info` : `Add New User`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div id="edit-user-modal">
                                <div
                                    className={`form-group ${this.state.errors.userFirstName !== undefined ? 'has-error' : ''}`}>
                                    <label htmlFor="">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        name="userFirstName"
                                        value={this.state.userFirstName}
                                        onChange={this.changeFieldHandler}
                                    />
                                    <HelpBlock value={this.state.errors.userFirstName}/>
                                </div>

                                <div
                                    className={`form-group ${this.state.errors.userLastName !== undefined ? 'has-error' : ''}`}>
                                    <label htmlFor="">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        name="userLastName"
                                        value={this.state.userLastName}
                                        onChange={this.changeFieldHandler}
                                    />
                                    <HelpBlock value={this.state.errors.userLastName}/>
                                </div>

                                <div
                                    className={`form-group ${this.state.errors.userEmail !== undefined ? 'has-error' : ''}`}>
                                    <label htmlFor="">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="userEmail"
                                        readOnly={this.state.emailDisabled}
                                        value={this.state.userEmail}
                                        onChange={this.changeFieldHandler}
                                    />
                                    <HelpBlock value={this.state.errors.userEmail}/>
                                </div>

                                <div
                                    className={`form-group ${this.state.errors.contactNumber !== undefined ? 'has-error' : ''}`}>
                                    <label htmlFor="phone">Mobile Phone Number</label>
                                    <PhoneInput
                                        className="form-control"
                                        id="phone"
                                        name="contactNumber"
                                        readOnly={this.state.contactNumberDisabled}
                                        placeholder={this.props.country === 'AU' ? '+61 02 3456 7890' : this.props.country === 'UK' ? '+44 345 6789012' : '+1 123 456 7890'}
                                        value={this.state.contactNumber}
                                        onChange={this.changeContactNumberHandler}
                                        country={this.props.country}
                                    />
                                    <HelpBlock value={this.state.errors.contactNumber}/>
                                </div>

                                <div
                                    className={`form-group ${this.state.errors.userType !== undefined ? 'has-error' : ''}`}>
                                    <label htmlFor="userType">Select User Type</label>
                                    <Select
                                        id="select-user-type"
                                        placeholder="Select User Type"
                                        isDisabled={this.state.userTypeDisabled}
                                        onChange={this.selectUserType}
                                        value={userTypeOptions.filter((item) => item.value === this.state.userType.value && item.type === this.state.providerType)}
                                        options={userTypeOptions}
                                    />
                                    <HelpBlock value={this.state.errors.userType}/>
                                </div>

                                {this.props.isEhrSupported &&
                                <div className={`form-group`}>
                                    <label htmlFor="">EHR Identifier</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="EHR Identifier"
                                        name="ehrIdentifier"
                                        value={this.state.ehrIdentifier}
                                        onChange={this.changeFieldHandler}
                                    />
                                </div>
                                }

                                {
                                    this.state.userType && this.state.userType.value !== "master" && this.state.providerType ?
                                        (
                                            <div
                                                className={`form-group ${this.state.errors.primaryPhysicians !== undefined ? 'has-error' : ''}`}>
                                                <label htmlFor="userType">Primary Users</label>
                                                <MultiSelect
                                                    placeholder={`Select Primary Users`}
                                                    id="select-primary-physicians"
                                                    isDisabled={(this.state.userType === '' || this.state.userType.value === 'master') || this.state.providerType === ''}
                                                    onChange={this.selectPrimaryPhysicians}
                                                    options={
                                                        this.props.performersOfUserTypeMaster.filter((item) => item.type === this.state.providerType).map((item) => ({
                                                            value: item.id, label: `${item.firstName} ${item.lastName}`,
                                                        }))
                                                    }
                                                    value={this.state.primaryPhysicians}
                                                />
                                                <HelpBlock value={this.state.errors.primaryPhysicians}/>
                                            </div>
                                        ) : (null)
                                }

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                this.state.userId ?
                                    (
                                        <button className="btn btn-primary"
                                                onClick={this.onEditProviderInfo}>Save</button>
                                    ) : (
                                        <button className="btn btn-primary" onClick={this.onAddProviderInfo}>Save</button>
                                    )
                            }
                        </Modal.Footer>
                    </Modal>
                    <ConfirmationModal
                        show={this.state.showConfirmResolutionModal}
                        text={this.state.selectedProvider.isActive ? "Do you want to mark this user as Inactive?" : "Do you want to mark this user as Active?"}
                        title="Confirm"
                        onModalClose={this.onModalClose}
                        onConfirmed={() => this.onConfirmResolveConversation()}
                        cancelBtnText="No"
                        okBtnText="Yes"
                        isDeleteConfirmation={false}
                    />
                </div>
                }
            </>
        )
    }
}

UserAdministration.propTypes = {
    users: PropTypes.array,
    show: PropTypes.bool,
    isRequestInProgress: PropTypes.bool,
    isGetAllPerformersRequestInProgress: PropTypes.bool,
    fetchAllUsersOfTheDepartment: PropTypes.func,
    addNewProvider: PropTypes.func,
    updateProviderInfo: PropTypes.func,
    fetchPerformersOfUserTypeMaster: PropTypes.func,
    performersOfUserTypeMaster: PropTypes.array,
    pagination: PropTypes.object,
};

const mapStateToProps = state => ({
    users: state.userAdministration.userAdministrationReducer.users,
    isRequestInProgress: state.userAdministration.userAdministrationReducer.isRequestInProgress,
    isGetAllPerformersRequestInProgress: state.userAdministration.userAdministrationReducer.isGetAllPerformersRequestInProgress,
    show: state.userAdministration.userAdministrationReducer.show,
    error: state.userAdministration.userAdministrationReducer.error,
    performersOfUserTypeMaster: state.userAdministration.userAdministrationReducer.performersOfUserTypeMaster,
    manageUsersEnabled: state.currentUser.attributes.hasSuperUserPrivileges && state.currentUser.attributes.isAdmin,
    pagination: state.userAdministration.userAdministrationReducer.pagination,
    country: state.currentUser.attributes.country,
    isEhrSupported: state.currentUser.attributes.isEhrSupported,
});

const mapDispatchToProps = dispatch => ({
    fetchAllUsersOfTheDepartment: (page = null) => dispatch(onfetchAllUsersOfTheDepartmentRequest(page)),
    addNewProvider: (params) => dispatch(onAddNewProviderRequest(params)),
    updateProviderInfo: (params) => dispatch(updateProviderInfoAction(params)),
    fetchPerformersOfUserTypeMaster: () => dispatch(onfetchPerformersOfUserTypeMasterRequest()),
    providerAccountStatusChange: (params) => dispatch(providerAccountStatusChangeAction(params))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
    key: "userAdministration",
    reducer: combineReducers({
        userAdministrationReducer,
    }),
});

export default compose(withReducer, withConnect)(UserAdministration);
