export default {
    fields: [
        "userFirstName",
        "userLastName",
        "userEmail",
        "contactNumber",
        "userType",
        "providerType",
        "primaryPhysicians",
    ],

    rules: {
        userFirstName: [{rule: "isRequired", message: "First Name is required"}],
        userLastName: [{rule: "isRequired", message: "Last Name is required"}],
        userEmail: [
            {rule: "isRequired", message: "Email is required"},
            {rule: "isEmail", message: "Email is invalid"},
        ],

        providerType: [
            {rule: "isRequired", message: "Provider Type is required"},
        ],

        userType: [{rule: "isRequired", message: "User Type is required"}],

        primaryPhysicians: [
            {
                rule: "isRequiredWith",
                withKey: "userType",
                withValue: "support",
                message: "Atleast one Primary User needs to be selected",
            },
        ],

        contactNumber: [
            {
                rule: 'isPhone',
                message: 'Contact number is invalid',
            }
        ],
    },
}

