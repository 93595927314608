import {
    ADD_NEW_LOCATION_REQUEST_FAILED,
    ADD_NEW_LOCATION_REQUEST_SENT,
    ADD_NEW_LOCATION_REQUEST_SUCCEED,
    GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_FAILED,
    GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SENT,
    GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SUCCEED,
    GET_TIMEZONES_REQUEST_FAILED,
    GET_TIMEZONES_REQUEST_SENT,
    GET_TIMEZONES_REQUEST_SUCCEED,
    UPDATE_LOCATION_REQUEST_FAILED,
    UPDATE_LOCATION_REQUEST_SENT,
    UPDATE_LOCATION_REQUEST_SUCCEED,
    UPLOAD_FILE_REQUEST_SENT,
    UPLOAD_FILE_REQUEST_SUCCEED,
    UPLOAD_FILE_REQUEST_FAILD
} from "./constants";

const initialState = {
    show: false,
    isRequestInProgress: false,
    infoUpdated: false,
    locations: [],
    timezones: [],
    error: "",
    pagination: null,
};

export const locationAdministrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SENT:
            return {
                ...state,
                isRequestInProgress: true,
                error: "",
                show: false,
            };
        case GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_SUCCEED:
            return {
                ...state,
                isRequestInProgress: false,
                locations: action.data.locations,
                pagination: action.data.pagination,
            };
        case GET_ALL_LOCATIONS_OF_THE_DEPARTMENT_REQUEST_FAILED:
            return {
                ...state,
                isRequestInProgress: false,
                error: action.data.error,
            };
        case ADD_NEW_LOCATION_REQUEST_SENT:
            return {
                ...state,
                show: true,
                isRequestInProgress: true,
            };
        case ADD_NEW_LOCATION_REQUEST_SUCCEED:
            return {
                ...state,
                show: false,
                isRequestInProgress: false,
            };
        case ADD_NEW_LOCATION_REQUEST_FAILED:
            return {
                ...state,
                show: true,
                isRequestInProgress: false,
            };
        case UPDATE_LOCATION_REQUEST_SENT:
            return {
                ...state,
                isRequestInProgress: true,
                show: true,
            };
        case UPDATE_LOCATION_REQUEST_SUCCEED:
            return {
                ...state,
                isRequestInProgress: false,
                show: false,
            };
        case UPDATE_LOCATION_REQUEST_FAILED:
            return {
                ...state,
                isRequestInProgress: false,
                show: true,
            };
        case GET_TIMEZONES_REQUEST_SENT:
            return {
                ...state,
                isRequestInProgress: true,
                error: "",
            };
        case GET_TIMEZONES_REQUEST_SUCCEED:
            return {
                ...state,
                isRequestInProgress: false,
                timezones: action.data.timezones,
            };
        case GET_TIMEZONES_REQUEST_FAILED:
            return {
                ...state,
                isRequestInProgress: false,
                error: "Error",
            };
        case UPLOAD_FILE_REQUEST_SENT:
            return {
                ...state,
                isRequestInProgress: true,
            };
        case UPLOAD_FILE_REQUEST_SUCCEED:
            return {
                ...state,
                isRequestInProgress: false,
            };
        case UPLOAD_FILE_REQUEST_FAILD:
            return {
                ...state,
                isRequestInProgress: false,
            };
        default:
            return state;
    }
};
