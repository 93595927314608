import axios from '../base';
import {
    DELETE_COMPOSITE_PROCEDURE,
    GET_ALL_TASKS,
    GET_APPOINTMENTS,
    GET_RESOURCES_DETAILS,
    GET_RESOURCES_METADATA,
    GET_USER_CONSENT_TEXT,
    POST_CREATE_TASK,
    POST_DECLINE_USER_CONSENT,
    POST_MARK_TASK_COMPLETE,
    POST_SUBMIT_SURVEY_FEEDBACK,
    POST_USER_CONSENT,
    PUT_UPDATE_TASK_ATTACHMENTS,
} from '../constants';
import {currentTimezoneName} from '../../utils/dateUtils';

export const getAllTaskService = () => {
    const params = {
        timezone: currentTimezoneName,
    };
    return axios.get(GET_ALL_TASKS, {params});
};

export const deleteProcedure = (compositeProcedureIds) => {
    const params = {
        composite_procedure_ids: compositeProcedureIds,
    };
    return axios.put(DELETE_COMPOSITE_PROCEDURE, params);
};

export const getResourcesMetadataService = () => axios.get(GET_RESOURCES_METADATA);

export const getResourceDetailsService = (userProcedureId, resourceType) => {
    const params = {
        user_procedure_id: userProcedureId,
        resource_type: resourceType,
    };
    return axios.get(`${GET_RESOURCES_METADATA}${userProcedureId}`, {params});
};

export const getResourceDetailsServiceForActiveProcedure = (resourceType) => {
    const params = {
        resource_type: resourceType,
    };
    return axios.get(GET_RESOURCES_DETAILS, {params});
};

export const postMarkTaskCompleteService = (reqObj) => axios.post(POST_MARK_TASK_COMPLETE, reqObj);

export const getSurveyFeedback = (eventId) => axios.get(`/v2/events/${eventId}/expected_questions`);

export const submitSuveryFeedback = (params) => axios.post(POST_SUBMIT_SURVEY_FEEDBACK, params);

export const getAppointments = (timezone) => {
    const params = {
        timezone,
    };

    return axios.get(GET_APPOINTMENTS, {
        params,
    });
};

export const createTask = (reqObj) => axios.post(POST_CREATE_TASK, reqObj);

export const updateTaskAttachment = (taskId, reqObj) => axios.put(`${PUT_UPDATE_TASK_ATTACHMENTS}/${taskId}`, reqObj);

export const getUserConsentForm = (procedureId) => {
    const params = {
        procedure_id: procedureId,
    };
    return procedureId ? axios.get(GET_USER_CONSENT_TEXT, {params}) : axios.get(GET_USER_CONSENT_TEXT);
};

export const agreeUserConsent = (params) => axios.post(POST_USER_CONSENT, params);

export const disAgreeUserConsent = (params) => axios.post(POST_DECLINE_USER_CONSENT, params);
