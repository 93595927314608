import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import SpinnerImage from '../../assets/images/spinner.gif';
import './lightbox_modal.scss';


class LightboxModal extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
    render() {
        return (
            <Modal
                show={this.props.show}
                container={document.body}
                keyboard={false}
                aria-labelledby="lightbox-modal"
                className="lightbox-modal"
            >
                <Modal.Body>
                    <img src={SpinnerImage} alt="Loading..." className="spinner-image"/>
                </Modal.Body>
            </Modal>
        );
    }
}

LightboxModal.propTypes = {
    show: PropTypes.bool,
};

export default LightboxModal;
