import {patientsForCaregiverService, startCaregiverMasqueradingService} from 'services/users';
import {showToaster} from 'common/toasterActions';
import * as StorageService from 'services/storage';
import {clearPatientProcedures} from 'patientApp/common/actions';

import history from '../../configureHistory';
import * as Constants from './constants';

export const getPatientsForCaregiverRequestSent = () => ({
  type: Constants.GET_PATIENTS_FOR_CAREGIVER_REQUEST_SENT,
});

export const getPatientsForCaregiverRequestSucceeded = (data) => ({
  type: Constants.GET_PATIENTS_FOR_CAREGIVER_REQUEST_SUCCEEDED,
  data,
});

export const getPatientsForCaregiverRequestFailed = () => ({
  type: Constants.GET_PATIENTS_FOR_CAREGIVER_REQUEST_FAILED,
});

export const startCaregiverMasqueradingRequestSent = () => ({
  type: Constants.POST_START_CAREGIVER_MASQUERADING_REQUEST_SENT,
});

export const startCaregiverMasqueradingRequestSucceeded = (data) => ({
  type: Constants.POST_START_CAREGIVER_MASQUERADING_REQUEST_SUCCEEDED,
  data,
});

export const startCaregiverMasqueradingRequestFailed = () => ({
  type: Constants.POST_START_CAREGIVER_MASQUERADING_REQUEST_FAILED,
});

export const clearMasqueradingFlags = () => ({
  type: Constants.CLEAR_MASQUERADING_FLAGS,
});


// THUNK ACTIONS

export const getPatientsForCaregiverRequest = () => (dispatch) => {
  dispatch(getPatientsForCaregiverRequestSent());
  patientsForCaregiverService()
    .then((response) => {
      const { patients } = response.data;
      // if (patients && patients.length === 1 && shouldAutoSelectPatient) {
      //   dispatch(startCaregiverMasqueradingRequest(patients[0].info.id));
      // } else {
      //   dispatch(getPatientsForCaregiverRequestSucceeded(patients));
      // }
      dispatch(getPatientsForCaregiverRequestSucceeded(patients));
    })
    .catch((error) => {
      dispatch(getPatientsForCaregiverRequestFailed());
      dispatch(showToaster({ message: error.response.data.errors[0], type: 'error' }));
    });
};

export const startCaregiverMasqueradingRequest = (userId) => (dispatch) => {
  dispatch(startCaregiverMasqueradingRequestSent());
  startCaregiverMasqueradingService(userId)
    .then((response) => {
      dispatch(clearPatientProcedures());
      StorageService.persistProxyAuthHeadersInDeviceStorage(response.headers);
      StorageService.setItemToStorage('proxyUser', JSON.stringify(response.data.patient.info));
      dispatch(startCaregiverMasqueradingRequestSucceeded(response.data.patient.info));
      history.replace('/');
    })
    .catch((error) => {
      dispatch(startCaregiverMasqueradingRequestFailed());
      dispatch(showToaster({ message: error.response.data.errors[0], type: 'error' }));
    });
};

export const clearMasqueradingFlagsRequest = () => (dispatch) => {
  dispatch(clearMasqueradingFlags());
};
