/*
 *
 * NavigationBar View Reducers
 *
 */

import {PERFORMER_FILTER_CHANGE, RESET_PERFORMER_FILTER} from './constants';

const initialState = {
    performer: null,
};

export const navBarSelectedPerformerReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERFORMER_FILTER_CHANGE:
            return {
                ...state,
                performer: action.performer,
            };
        case RESET_PERFORMER_FILTER:
            return initialState;
        default:
            return state;
    }
};
