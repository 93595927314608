import React from "react";
import Stack from "@mui/material/Stack";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton } from "@material-ui/core";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import { generateOtp, sendTempData } from "./../action";
import DisclaimerView from "./DisclaimerView";
import { trimCountryCodeFromPhoneNumber } from 'utils/stringUtils'
import {
PHONE_VERIFY_VIEW, ADDRESS_VIEW, NAME_VIEW, SUCCESS_VIEW, PROCEDURE_DATES_VIEW, PROCEDURE_DETAILS_VIEW, 
} from "./../constants";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const DataConfirmView = props => {

  const patientRegister = useSelector((store) => store.register.patientRegisterReducer)
  const dispatch = useDispatch()

  const [state, setState] = useState({
    flowId: patientRegister.flowId || null,
    flowType: patientRegister.flowType || "sign_up",
    firstName: patientRegister.firstName || "",
    lastName: patientRegister.lastName || "",
    email: patientRegister.email || "",
    phone: patientRegister.phone || "",
    addressLine1: patientRegister.addressLine1 || "",
    addressLine2: patientRegister.addressLine2 || "",
    city: patientRegister.city || "",
    state: patientRegister.state || "",
    zipCode: patientRegister.zipCode || "",
    stomaMeasuringAppt: patientRegister.stomaMeasuringAppt? moment(new Date(patientRegister.stomaMeasuringAppt)).format('MM/DD/YYYY') : null,
    surgeryDate: patientRegister.surgeryDate? moment(new Date(patientRegister.surgeryDate)).format('MM/DD/YYYY') : null,
    procedure: patientRegister.procedure || "",
    facilityName: patientRegister.facilityName || "",
    marketingConsent: patientRegister.marketingConsent || false,
    open: false
  })

  const onDisagreeOfDisclaimer = () => {
    setState({...state, open: false });
  };

  const onDisclaimerClose = () => {
    setState({...state, open: false });
  };

  const openConsentModal = () =>{
    setState({...state, open:true})
  }

  const handleOTP = () => {
    const params = {
      first_name: patientRegister.firstName,
      last_name: patientRegister.lastName,
      email: patientRegister.email,
      contact_number: trimCountryCodeFromPhoneNumber(patientRegister.phone),
      location_code: patientRegister.facilityCode,
      procedure_modifier: patientRegister.modifierIdentifier.value,
      procedure_date: patientRegister.surgeryDate ? moment(new Date(patientRegister.surgeryDate)).format('YYYY-MM-DD') : null,
      stoma_marking_date: patientRegister.stomaMeasuringAppt ? moment(new Date(patientRegister.stomaMeasuringAppt)).format('YYYY-MM-DD') : null,
      email_verification_code: patientRegister.emailOtp,
      contact_number_verification_code: patientRegister.phoneOtp,
      flow_type: patientRegister.flowType,
      flow_id: patientRegister.flowId,
      password: state.password,
      password_confirmation: state.password,
      has_marketing_consent: patientRegister.marketingConsent,
      has_user_consented: patientRegister.serviceEnrollment,
      is_procedure_valid: patientRegister.isProcedureValid,
      facility_id: patientRegister.facilityId.value,
      education_preference: patientRegister.educationPreference.value,

      address:{
        line_1: patientRegister.addressLine1,
        line_2: patientRegister.addressLine2,
        city: patientRegister.city,
        state: patientRegister.state,
        zipcode: patientRegister.zipCode
      }
    };
    if(patientRegister.flowType === "mobile_sign_up"){
      dispatch(sendTempData(params,props.nextPage,SUCCESS_VIEW))
    }
    else{
      dispatch(generateOtp(params, props.nextPage,PHONE_VERIFY_VIEW))
    }
  };

  const onAgreeOfDisclaimer = () => {
    setState({...state, open: false },()=>{
      handleOTP();
    })
  };

  const fullAddress = () => {
    let address = "";
    address = address !="" ? ( state.addressLine1 ? `${address}, ${state.addressLine1}` : `${address}`) : `${state.addressLine1}`;
    address = address !="" ? ( state.addressLine2 ? `${address}, ${state.addressLine2}` : `${address}`) : `${state.addressLine2}`;
    address = address !="" ? ( state.city ? `${address}, ${state.city}` : `${address}`) : `${state.city}`;
    address = address !="" ? ( state.state ? `${address}, ${state.state}` : `${address}`) : `${state.state}`;
    address = address !="" ? ( state.zipCode ? `${address}, ${state.zipCode}` : `${address}`) : `${state.zipCode}`;
    return address
  }

    return (

          <div className="data-confirm-view">

            <div  className="text-left data-confirm-body">
              <Stack spacing={"24px"}>
              <div style={{
                    display:'flex',
                    alignItems: "center",
                    justifyContent: 'space-between'
                  }}>
                  <div className="data-verification-section-title">Basic Information</div>
                    <div className="float-right">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => props.nextPage(NAME_VIEW, true)}
                        label="edit"
                      >
                        <EditOutlinedIcon className="edit-data-icon" size="large"  />
                      </IconButton>
                    </div>
                  </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Full Name</div>

                    <div className="data-verification-value">
                      {patientRegister.firstName} {patientRegister.lastName}
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">
                      Mobile Phone Number
                    </div>
                    <div className="data-verification-value">
                      {patientRegister.phone}
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Email Address</div>
                    <div className="data-verification-value">
                      {patientRegister.email}
                    </div>
                  </Stack>
                </div>
                  <hr className="hr-line-light"/>
                <div style={{
                    display:'flex',
                    alignItems: "center",
                    justifyContent: 'space-between'
                  }}>
                  <div className="data-verification-section-title">Address</div>
                    <div className="float-right">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => props.nextPage(ADDRESS_VIEW, true)}
                        label="edit"
                      >
                        <EditOutlinedIcon className="edit-data-icon" size="large"  />
                      </IconButton>
                    </div>
                  </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Address Line</div>
                    <div className="data-verification-value">
                    {fullAddress()}
                    </div>
                  </Stack>
                </div>
                    <hr className="hr-line-light"/>
                <div style={{
                    display:'flex',
                    alignItems: "center",
                    justifyContent: 'space-between'
                  }}>
                  <div className="data-verification-section-title">Surgery Dates</div>
                    <div className="float-right">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => props.nextPage(PROCEDURE_DATES_VIEW,true)}
                        label="edit"
                      >
                        <EditOutlinedIcon className="edit-data-icon" size="large"  />
                      </IconButton>
                    </div>
                  </div>
                {
                <div>
                 <Stack spacing={"16px"}>
                    <div className="data-verification-title">
                      Stoma Measuring Appointment Date
                    </div>
                    <div className="data-verification-value">
                      {state.stomaMeasuringAppt
                        ? state.stomaMeasuringAppt
                        : <span className="not-available-data">Not Entered</span>}
                    </div>
                  </Stack>

                </div>
  }
   {
                <div>

                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Surgery Date</div>
                    <div className="data-verification-value">
                      {state.surgeryDate
                        ? state.surgeryDate
                        : <span className="not-available-data">Not Entered</span>}
                    </div>
                  </Stack>

                </div>

                      }
                    <hr className="hr-line-light"/>
                <div style={{
                    display:'flex',
                    alignItems: "center",
                    justifyContent: 'space-between'
                  }}>
                  <div className="data-verification-section-title">Procedure Information</div>
                    <div className="float-right">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => props.nextPage(PROCEDURE_DETAILS_VIEW,true)}
                        label="edit"
                      >
                        <EditOutlinedIcon className="edit-data-icon" size="large"  />
                      </IconButton>
                    </div>
                  </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Procedure</div>
                    <div className="data-verification-value">
                      {patientRegister.modifierIdentifier.label}
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Facility Name</div>
                    <div className="data-verification-value">
                      {patientRegister.facilityId.label}
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack spacing={"16px"}>
                    <div className="data-verification-title">Education Preference</div>
                    <div className="data-verification-value">
                      {patientRegister.educationPreference && patientRegister.educationPreference.label || "View for my procedure only"}
                    </div>
                  </Stack>
                </div>
                <div>
                  <Stack sx={{ alignSelf: "center" }} spacing={"16px"}>
                    <PrimaryButton
                      sx={{ alignSelf: "center" }}
                      onClick={handleOTP}
                      text="Yes, It’s correct"
                      fullWidth
                    />
                  </Stack>
                </div>
              </Stack>
            </div>
            <DisclaimerView
          open={state.open}
          onClose={onDisclaimerClose}
          onAgree={onAgreeOfDisclaimer}
          onDisagree={onDisagreeOfDisclaimer}
        />
          </div>
      //   </div>
      // </div>
    );
  }

export default DataConfirmView;
