import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Paper from "@material-ui/core/Paper";
import { useEffect } from 'react';
import { ThemeProvider } from "@mui/material/styles";
import { InputTextFieldTheme } from 'components/StyleGuideComponents/common/Themes';
import { dropdownTheme } from 'components/StyleGuideComponents/common/Themes';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";


const CustomizedDropdown =(props) =>{
    return <Paper  variant="outlined" elevation={0} {...props} style={{ fontSize: "16px",lineHeight: "32px",borderWidth:"2px"}} >
        {props.children}
    </Paper>;
}

export default function MUIAutocompleteSelect(props) {

  useEffect(()=>{},[props.options,props.filterOptions]);

  return (
    <ThemeProvider theme={dropdownTheme}>
      {props.options && props.options.length > 0 && (props.filterOptions ? props.filterOptions.length > 0 : true) && (
      <Autocomplete
        classes={props.className}
        getOptionLabel={props.getOptionLabel}
        filterOptions={ props.filterOptions && props.filterOptions } 
        id={props.id || "mui-select"}
        isOptionEqualToValue={props.isOptionEqualToValue}
        options={props.options || []}
        value={props.value || null}
        onChange={(event, value) =>{
            props.onChange(props.name, value);
          }}
        PaperComponent={(props)=><CustomizedDropdown  {...props} />}
        disableClearable = {!props.autoCompleteTheOptions}
        clearIcon={ props.value && props.value.label && 
          <CancelOutlinedIcon
            fontSize="small"
            sx={{ color: "#575558", height: "20px", width: "20px", marginRight:"0px"}}
          />
        }
        renderInput={(params) => (
          <ThemeProvider theme={InputTextFieldTheme}>
            {props.autoCompleteTheOptions ? (
              <TextField 
                multiline = {props.multiline} 
                required={ props.required }
                placeholder={props.placeholder} 
                label={props.label}
                variant= {props.variant}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                {...params}  
              />
            ) : (
              <TextField 
                placeholder={props.placeholder}
                required={ props.required }
                onKeyPress={(e) => {
                  e.preventDefault();
                }}
                sx={{ caretColor: "white" }}
                label={props.label}
                {...params}
              />
            )}
          </ThemeProvider>
        )}
      />)}
    </ThemeProvider>
  );
}