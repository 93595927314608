import axios from 'axios';
import {getAuthHeaders} from 'services/storage';
import {
    GET_PASSIVE_MODE_CONTENT,
    GET_PASSIVE_MODE_METADATA,
    INITIATE_LOGIN_WITH_OTP,
    POST_TASK_RESPONSE,
    VERIFY_LOGIN_CODE,
} from './constants';

export const getPassiveModeMetadata = (key) => axios.get(`${GET_PASSIVE_MODE_METADATA}/${key}`);

export const getPassiveModeContent = (key) => {
    const authHeaders = getAuthHeaders();
    return axios.get(`${GET_PASSIVE_MODE_CONTENT}/${key}`, {headers: authHeaders});
};

export const submitTaskResponse = (reqObj) => axios.post(POST_TASK_RESPONSE, reqObj);

export const verifyLoginCode = (params) => axios.post(VERIFY_LOGIN_CODE, params);

export const initiateLoginWithOtp = (linkKey) => {
    const params = {
        link_key: linkKey,
    };
    return axios.get(`${INITIATE_LOGIN_WITH_OTP}`, {params});
};
