/*
 *
 * NavigationBar actions
 *
 */
import {PERFORMER_FILTER_CHANGE, RESET_PERFORMER_FILTER} from './constants';

export const performerChangeTrigger = (performer) => ({
    type: PERFORMER_FILTER_CHANGE,
    performer,
});

export const resetPerformerFilter = () => ({
    type: RESET_PERFORMER_FILTER,
});
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const navBarPerformerChangeAction = (performer) => (dispatch) => {
    dispatch(performerChangeTrigger(performer));
};
