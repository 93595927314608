import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import customStyles from './DefaultStyle';
import ClearIndicator from './Indicators';
import Option from './Options';

const MultiSelect = ({className: classes, components, ...props}) => {
    if (props.allowSelectAll) {
        return (
            <Select
                {...props}
                styles={customStyles}
                classNamePrefix="multi-select"
                className={`multi-select-container ${classes || ''}`}
                components={{ClearIndicator, Option, ...components}}
                isMulti
                isClearable
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                options={[props.allOption, ...props.options]}
                onChange={(selected) => {
                    if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === props.allOption.value) {
                        return props.onChange(props.options);
                    }
                    return props.onChange(selected);
                }}
            />
        );
    }
    return (
        <Select
            {...props}
            styles={customStyles}
            classNamePrefix="multi-select"
            className={`multi-select-container ${classes || ''}`}
            components={{ClearIndicator, Option, ...components}}
            isMulti
            hideSelectedOptions={false}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
        />
    );
};

MultiSelect.propTypes = {
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
};

MultiSelect.defaultProps = {
    allOption: {
        label: 'Select all',
        value: '*',
    },
};

export default MultiSelect;
