import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './radioButton.scss';

class RadioButton extends Component {
    constructor(props) {
        super(props);
        this.onToggle = this.onToggle.bind(this);
        this.state = {
            isActive: props.defaultValue || false,
            isDisabled: props.disabled || false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== undefined) {
            this.setState({
                isActive: nextProps.value,
            });
        }
    }

    onToggle = (event) => {
        const isChecked = event.target.checked;
        this.setState({isActive: isChecked}, () => {
            this.props.onToggle(isChecked);
        });
    }

    render() {
        return (
            <label className={`switch ${this.state.isDisabled ? 'disabled' : ''}`}>
                <input type="checkbox" checked={this.state.isActive} onChange={this.onToggle}
                       disabled={this.state.isDisabled}/>
                <span className="slider round"></span>
            </label>
        );
    }
}

RadioButton.propTypes = {
    onToggle: PropTypes.func.isRequired,
    defaultValue: PropTypes.bool,
    value: PropTypes.any,
    disabled: PropTypes.bool,
};

export default RadioButton;
