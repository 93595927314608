import {setItemToStorage} from 'services/storage';
import filter from 'lodash/filter';
import {RESET_GLOBAL_REDUCERS} from '../../constants';
import {
    CLEAR_PATIENT_PROCEDURES,
    DELETE_PROCEDURE_REQUEST_FAILED,
    DELETE_PROCEDURE_REQUEST_SENT,
    DELETE_PROCEDURE_REQUEST_SUCCEED,
    GET_PATIENT_PROCEDURES_REQUEST_FAILED,
    GET_PATIENT_PROCEDURES_REQUEST_SENT,
    GET_PATIENT_PROCEDURES_REQUEST_SUCCEED,
    PUT_SWITCH_ACTIVE_PROCEDURE_FAILED,
    PUT_SWITCH_ACTIVE_PROCEDURE_SENT,
    PUT_SWITCH_ACTIVE_PROCEDURE_SUCCEED,
    VERIFY_INVITE_CODE_CLEAR_STATE,
    VERIFY_INVITE_CODE_REQUEST_FAILED,
    VERIFY_INVITE_CODE_REQUEST_SENT,
    VERIFY_INVITE_CODE_REQUEST_SUCCEED,
} from './constants';

const getInitialState = () => (
  {
    isLoading: true,
    isDeleteProcedureRequestInProgress: false,
    isInviteCodeValid: false,
    isVerifyInviteCodeRequestInProgress: false,
    compositeProcedures: [],
    inviteCodeProcedures: [],
    userType: '',
    departmentConfiguration: '',
    selectedProvider: '',
  }
);

const patientProceduresReducer = (state = getInitialState(), action) => {
  let activeCompositeProcedure;
  switch (action.type) {
    case RESET_GLOBAL_REDUCERS:
    case CLEAR_PATIENT_PROCEDURES:
      return getInitialState();
    case GET_PATIENT_PROCEDURES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PATIENT_PROCEDURES_REQUEST_SUCCEED:
      if (action.shouldRedirect) {
        activeCompositeProcedure = action.compositeProcedures.find((item) => item.isActive);
        if (activeCompositeProcedure) {
          setItemToStorage('activeCompositeProcedureId', activeCompositeProcedure.id);
        }
      }
      return {
        ...state,
        isLoading: false,
        compositeProcedures: action.compositeProcedures,
      };
    case GET_PATIENT_PROCEDURES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_PROCEDURE_REQUEST_SENT:
      return {
        ...state,
        isDeleteProcedureRequestInProgress: true,
        error: '',
      };
    case DELETE_PROCEDURE_REQUEST_SUCCEED:
      return {
        ...state,
        compositeProcedures: filter(state.compositeProcedures, (compositeProcedure) => compositeProcedure.id !== action.compositeProcedureIds[0]),
        isDeleteProcedureRequestInProgress: false,
      };
    case DELETE_PROCEDURE_REQUEST_FAILED:
      return {
        ...state,
        isDeleteProcedureRequestInProgress: false,
        error: 'Error',
      };
    case VERIFY_INVITE_CODE_REQUEST_SENT:
      return {
        ...state,
        isVerifyInviteCodeRequestInProgress: true,
        inviteCodeProcedures: [],
        isInviteCodeValid: false,
      };
    case VERIFY_INVITE_CODE_REQUEST_SUCCEED:
      setItemToStorage('inviteCode', action.inviteCode);
      return {
        ...state,
        isInviteCodeValid: true,
        isVerifyInviteCodeRequestInProgress: false,
        inviteCode: action.inviteCode,
        inviteCodeProcedures: action.data.procedures,
        userType: action.data.userType,
        departmentConfiguration: action.data.departmentConfiguration,
        selectedProvider: action.data.selectedProvider,
      };
    case VERIFY_INVITE_CODE_REQUEST_FAILED:
      return {
        ...state,
        isVerifyInviteCodeRequestInProgress: false,
        inviteCodeProcedures: [],
        isInviteCodeValid: false,
      };
    case VERIFY_INVITE_CODE_CLEAR_STATE:
      return {
        ...state,
        isInviteCodeValid: false,
        inviteCodeProcedures: [],
        isVerifyInviteCodeRequestInProgress: false,
      };
    default:
      return state;
  }
};

export default patientProceduresReducer;

const switchProcedureInitialState = {
  isLoading: false,
  compositeProcedureId: 0,
  departmentConfigurations: [],
};
export const switchProcedureReducer = (state = switchProcedureInitialState, action) => {
  switch (action.type) {
    case PUT_SWITCH_ACTIVE_PROCEDURE_SENT:
      return {
        ...state,
        isLoading: true,
        departmentConfigurations: [],
      };
    case PUT_SWITCH_ACTIVE_PROCEDURE_SUCCEED:
      setItemToStorage('activeCompositeProcedureId', action.compositeProcedureId);
      setItemToStorage('departmentConfigurations', JSON.stringify(action.departmentConfigurations));
      return {
        ...state,
        isLoading: false,
        compositeProcedureId: action.compositeProcedureId,
        departmentConfigurations: action.departmentConfigurations,
      };
    case PUT_SWITCH_ACTIVE_PROCEDURE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return switchProcedureInitialState;
    default:
      return state;
  }
};
