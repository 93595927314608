import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import queryString from 'query-string';
import LightboxModal from 'components/LightboxModal';
import {combineReducers, compose} from 'redux';
import injectReducer from 'utils/injectReducer';
import {getProgram} from './actions';
import {xealthV1ProgramReducer} from './reducer';

class XealthPatientDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            url: null,
        };
    }

    componentDidMount() {
        const isLoading = true;
        this.setState({
            isLoading,
            url: null,
        });
        this.props.onGetProgram(this.getToken());
    }

    getToken = () => queryString.parse(this.props.location.search).token

    render() {
        return <LightboxModal show={this.state.isLoading}/>;
    }
}

XealthPatientDetails.propTypes = {
    onGetProgram: PropTypes.func,
};
const mapStateToProps = (state, props) => ({
    isLoading: state.isLoading.xealthV1ProgramReducer.isLoading,
    url: state.isLoading.xealthV1ProgramReducer.url,
});
const mapDispatchToProps = (dispatch) => ({
    onGetProgram: (token) => dispatch(getProgram(token)),
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
    key: 'isLoading',
    reducer: combineReducers({
        xealthV1ProgramReducer,
    }),
});
export default compose(
    withReducer,
    withConnect,
)(XealthPatientDetails);
