export const GET_OSTOMY_INFO_REQUEST_SENT =
    "app/DashboardProfile/GET_OSTOMY_INFO_REQUEST_SENT";
export const GET_OSTOMY_INFO_REQUEST_SUCCEED =
    "app/DashboardProfile/GET_OSTOMY_INFO_REQUEST_SUCCEED";
export const GET_OSTOMY_INFO_REQUEST_FAILED =
    "app/DashboardProfile/GET_OSTOMY_INFO_REQUEST_FAILED";

export const GET_CARE_TEAM_INFO_REQUEST_SENT =
    "app/DashboardProfile/GET_CARE_TEAM_INFO_REQUEST_SENT";
export const GET_CARE_TEAM_INFO_REQUEST_SUCCEED =
    "app/DashboardProfile/GET_CARE_TEAM_INFO_REQUEST_SUCCEED";
export const GET_CARE_TEAM_INFO_REQUEST_FAILED =
    "app/DashboardProfile/GET_CARE_TEAM_INFO_REQUEST_FAILED";

export const GET_PERSONAL_INFO_REQUEST_SENT =
    "app/DashboardProfile/GET_PERSONAL_INFO_REQUEST_SENT";
export const GET_PERSONAL_INFO_REQUEST_SUCCEED =
    "app/DashboardProfile/GET_PERSONAL_INFO_REQUEST_SUCCEED";
export const GET_PERSONAL_INFO_REQUEST_FAILED =
    "app/DashboardProfile/GET_PERSONAL_INFO_REQUEST_FAILED";
