import React from "react";
import "../patientRegister.scss";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Password from "../../../components/StyleGuideComponents/Password";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import validate from "common/validator";
import { passwordValidationConfig } from "./../validators";
import { createPatient } from "../action";
import { trimCountryCodeFromPhoneNumber } from 'utils/stringUtils'
import {
  SUCCESS_VIEW
  } from "./../constants";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
  
const PasswordView = props => {

  const patientRegister = useSelector((store) => store.register.patientRegisterReducer)
  const dispatch = useDispatch()

  const [state, setState] = useState({
    password: "",
    timer: 6,
    errors: [],
    upperCaseTestHover: false,
    lowerCaseTestHover: false,
    numericalTestHover: false,
    passwordLengthTestHover: false,
    showPasswordValidations: false
  })

  const handleChange = (name, value) => {
    setState({...state, [name]: value });
  };
  const onValidationFailure = (errors) => {
    setState({...state, errors });
  };

  const onNextValidationSuccess = () => {
    const params = {
      first_name: patientRegister.firstName,
      last_name: patientRegister.lastName,
      email: patientRegister.email,
      contact_number: trimCountryCodeFromPhoneNumber(patientRegister.phone),
      location_code: patientRegister.facilityCode,
      procedure_modifier: patientRegister.modifierIdentifier.value,
      procedure_date: patientRegister.surgeryDate ? moment(new Date(patientRegister.surgeryDate)).format('YYYY-MM-DD') : null,
      stoma_marking_date: patientRegister.stomaMeasuringAppointment ? moment(new Date(patientRegister.stomaMeasuringAppointment)).format('YYYY-MM-DD') : null,
      email_verification_code: patientRegister.emailOtp,
      contact_number_verification_code: patientRegister.phoneOtp,
      flow_type: patientRegister.flowType,
      flow_id: patientRegister.flowId,
      password: state.password,
      password_confirmation: state.password,
      has_marketing_consent: patientRegister.marketingConsent,
      has_user_consented: patientRegister.serviceEnrollment,
      is_procedure_valid: patientRegister.isProcedureValid,
      facility_id: patientRegister.facilityId.value,
      education_preference: patientRegister.educationPreference.value,

      address:{
        line_1: patientRegister.addressLine1,
        line_2: patientRegister.addressLine2,
        city: patientRegister.city,
        state: patientRegister.state,
        zipcode: patientRegister.zipCode
      }
      
    };
    dispatch(createPatient(params,props.nextPage,SUCCESS_VIEW))
  };

  const checkIfDisabled = () =>{
    return state.password.length >= 10 && /[A-Z]/.test(state.password) && /[a-z]/.test(state.password) && /[0-9]/.test(state.password)
  }

  const validatePassword = () => {
    validate(
      passwordValidationConfig,
      state,
      onValidationFailure,
      onNextValidationSuccess
    );
  };

    return (

          <div className="text-center">
            <Stack spacing={"8px"}>


              <div>
                <div className="dark-highlight otp-message">
                  Please set your Password.
                </div>
              </div>

              <Password
                onFocus={()=>{setState({...state,showPasswordValidations:true})}}
                id="password"
                label="Password"
                name="password"
                value={state.password}
                onChange={(e) => handleChange("password", e.target.value)}
                required
                error={"password" in state.errors}
                errorText={
                  "password" in state.errors
                    ? state.errors.password
                    : ""
                }
              />

              <div className={`${state.showPasswordValidations ? "password-tests-visible":"password-tests-hidden"} resend-message password-tests`}>
                <div className="password-must-message">Password must have at least</div>

                <div className="resend-code password-verify-check-box-wrapper">
                  <div className="password-verify-check-box-inner-wrapper">
                    <div className="password-verify-check-box">

                      {/[A-Z]/.test(state.password) ? (
                        <CheckCircleIcon
                        className="password-test-icon"
                          fontSize="large"
                          sx={{ color: "#34C759" }}

                        />
                      ) : (

                              <CancelIcon
                              fontSize="large"
                              sx={{ color: "#DB5461" }}
                            />


                      )}
                      <span>1 uppercase</span>
                    </div>
                    <div className="password-verify-check-box">
                      {/[a-z]/.test(state.password) ? (
                        <CheckCircleIcon
                          fontSize="large"
                          sx={{ color: "#34C759" }}
                        />
                      ) : (
                        <CancelIcon
                        fontSize="large"
                        sx={{ color: "#DB5461" }}
                      />
                      )}
                      <span>1 lowercase</span>
                    </div>
                  </div>

                  <div className="password-verify-check-box-inner-wrapper">
                    <div className="password-verify-check-box">
                      {/[0-9]/.test(state.password) ? (
                        <CheckCircleIcon
                          fontSize="large"
                          sx={{ color: "#34C759" }}
                        />
                      ) : (
                        <CancelIcon
                        fontSize="large"
                        sx={{ color: "#DB5461" }}
                      />
                      )}
                      <span>1 number</span>
                    </div>
                    <div className="password-verify-check-box">
                      {state.password.length >= 10 ? (
                        <CheckCircleIcon
                          fontSize="large"
                          sx={{ color: "#34C759" }}
                        />
                      ) : (
                        <CancelIcon
                        fontSize="large"
                        sx={{ color: "#DB5461" }}
                      />
                      )}
                      <span>10 characters</span>
                    </div>
                  </div>
                </div>

              </div>

              <PrimaryButton
              disabled={!checkIfDisabled()}
                fullWidth
                text="Set Password"
                onClick={validatePassword}
              />
            </Stack>
          </div>

    );
  }

export default PasswordView;