import React, {Component} from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import fileType from 'file-type';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import findIndex from 'lodash/findIndex';
import LightboxModal from 'components/LightboxModal';
import {combineReducers, compose} from 'redux';
import injectReducer from 'utils/injectReducer';
import {documentsReducer} from 'common/reducer';
import {showToaster} from 'common/toasterActions';
import {uploadDocumentClearState, uploadDocumentRemoveDocument, uploadDocumentWithAuthUrl} from 'common/actions';
import {getFileNameFromPath} from 'utils/stringUtils';
import Attachments from 'containers/Attachments';
import {getItemFromStorage} from 'services/storage';
import isEmpty from 'lodash/isEmpty';

import {
    ALL_FILE_FORMATS,
    IMAGE_FILE_FORMATS,
    MS_DOC_EXTENSIONS,
    USER_TYPE_CAREGIVER,
    USER_TYPE_PATIENT,
} from '../../constants';
import './addAttachmentModal.scss';

// TODO: 1. Refactoring the component which is implemented incorrectly.
class AddAttachmentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: props.showModal ? props.showModal : false,
            selectedImage: {
                name: null,
                size: null,
                type: null,
                file: null,
            },
            percentCompleted: 0,
            mediaResources: props.mediaResources ? props.mediaResources : [],
            fileFormatSupported: true,
        };
    }

    // TODO: 1. Move media resources array to redux. 2 Use reusing reducer logic.
    componentWillReceiveProps(nextProps) {
        this.setState({show: nextProps.showModal});
        if (nextProps.addedAttachment.document !== null) {
            if (this.state.mediaResources.length === 0) {
                this.setState({
                    mediaResources: this.state.mediaResources.concat(nextProps.addedAttachment.mediaResource),
                }, () => {
                    if (this.props.isBindedWithModal !== undefined && !this.props.isBindedWithModal) {
                        this.props.onAttachmentsAdded(this.state.mediaResources);
                    }
                });
            } else if (findIndex(this.state.mediaResources, nextProps.addedAttachment.mediaResource) === -1) {
                this.setState({
                    mediaResources: this.state.mediaResources.concat(nextProps.addedAttachment.mediaResource),
                }, () => {
                    if (this.props.isBindedWithModal !== undefined && !this.props.isBindedWithModal) {
                        this.props.onAttachmentsAdded(this.state.mediaResources);
                    }
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.uploadDocumentClearState();
        if (this.props.resetLocalState) {
            this.props.resetLocalState();
        }
    }


    onAttachmentRemoved = (attachmentId) => {
        const attachments = this.state.mediaResources.filter((item) => item.id !== attachmentId);
        this.setState({
            mediaResources: attachments,
        }, () => {
            if (this.props.onAttachmentRemoved1) {
                this.props.onAttachmentRemoved1(attachmentId);
            }
            if (this.props.onAttachmentRemoved) {
                this.props.onAttachmentRemoved(this.state.mediaResources);
            }
        });
        this.props.uploadDocumentRemoveDocument(attachmentId);
    }

    onImageChange = (e) => {
        this.setState({fileFormatSupported: true});
        e.preventDefault();
        let files;
        const readerArrayBuffer = new FileReader();

        if (e.dataTransfer) {
            ({files} = e.dataTransfer);
        } else if (e.target) {
            ({files} = e.target);
        }

        if (files && files[0]) {
            const blob = files[0];
            readerArrayBuffer.readAsArrayBuffer(blob);

            readerArrayBuffer.onloadend = (event) => {
                if (event.target.readyState === readerArrayBuffer.DONE) {
                    const uint = new Uint8Array(event.target.result);
                    const mimeType = fileType(uint);

                    if (this.isValidFileFormat(mimeType, blob.type)) {
                        this.setState({
                            selectedImage: {
                                name: files[0].name,
                                size: files[0].size,
                                type: files[0].type,
                                file: files[0],
                            },
                            percentCompleted: 0,
                        }, this.uploadDocument);
                    } else {
                        this.setState({fileFormatSupported: false});
                    }
                }
            };
        }
    }

    onClick = (e) => {
        e.target.value = null;
    }

    onModalClose = () => {
        if (!this.props.addedAttachment.isUploadInProgress) {
            this.props.onAttachmentsAdded(this.state.mediaResources);
            this.resetDefaultState();
            if (this.props.onModalClose) {
                this.props.onModalClose();
            }
        }
    }

    onDoneClick = () => {
        const {mediaResources} = this.state;
        if (!this.props.addedAttachment.isUploadInProgress) {
            this.props.onAttachmentsAdded(this.state.mediaResources);
            this.resetDefaultState();
            if (this.props.callbackOnDone) {
                this.props.callbackOnDone(mediaResources);
                this.setState({mediaResources: []});
            }
        }
    }

    getFileType(mimeType) {
        return mimeType.includes('audio') ? 'audio' : mimeType.includes('image') ? 'image' : mimeType.includes('video') ? 'video' : 'document';
    }

    isValidFileFormat = (mimeType, fileTypeExtension) => {
        let result = false;
        if (mimeType) {
            const {mime} = mimeType;
            if (mime) {
                const formats = this.props.fileFormats.split(',');
                if (mime === 'application/x-msi') {
                    result = MS_DOC_EXTENSIONS.includes(fileTypeExtension);
                    return result;
                }
                if (formats.length === 1) {
                    if (formats[0] === '*/*') {
                        result = ALL_FILE_FORMATS.some((m) => m.includes(mime));
                    }
                } else {
                    result = formats.some((m) => m.includes(mime));
                }
            }
        }
        return result;
    }

    validateAttachments = () => {
        if (this.state.mediaResources.length < 2) {
            return true;
        }
        this.props.showToaster('error', 'You can add only 2 attachments');
        return false;
    }

    uploadDocument = () => {
        let percentCompleted = 0;
        const onUploadProgress = (progressEvent) => {
            percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.setState({
                percentCompleted,
            });
        };
        this.props.uploadDocument(this.state.selectedImage.name, this.state.selectedImage.size, 'attachment', this.getFileType(this.state.selectedImage.type), 0, this.state.selectedImage.file, onUploadProgress, !!this.props.isExtra);
    }

    resetDefaultState = () => {
        this.setState({
            selectedImage: {},
            show: false,
            fileFormatSupported: true,
        });
    }

    renderAttachmentContent = () => (
        <div className="attachment-container">
            {
                <div>
                    <div
                        className={`${this.state.mediaResources.length < 2 ? 'select-attachment' : 'select-attachment-disabled'}`}>
                        <label htmlFor="answer_attachment"
                               className={`${this.state.mediaResources.length < 2 ? 'cursor-pointer' : ''}`}>
                            Click to attach a file
                            <input
                                type="file"
                                name="answer_attachment"
                                id="answer_attachment"
                                disabled={this.props.addedAttachment.isUploadInProgress || this.state.mediaResources.length >= 2}
                                accept={this.props.fileFormats}
                                onChange={this.onImageChange}
                                onClick={this.state.mediaResources.length < 2 ? this.onClick : null}
                            />
                        </label>
                    </div>
                    <label className="message-container">
                        You can add upto 2 attachments
                    </label>
                    {
                        !this.state.fileFormatSupported && (
                            <label className="file-support-msg-container">
                                File Format not supported
                            </label>
                        )
                    }

                </div>
            }
            <div className="clr"></div>
            {this.state.selectedImage.name && this.state.selectedImage.name !== '' && (
                <div className="selected-file-name-container">
                    {this.state.percentCompleted <= 100 && this.props.addedAttachment.isUploadInProgress && (
                        <div>
                            <span className="selected-file-name">{this.state.selectedImage.name}</span>
                            <ProgressBar className="progressbar" now={this.state.percentCompleted}
                                         label={`${this.state.percentCompleted}%`}/>
                        </div>
                    )}
                    <LightboxModal
                        show={this.state.percentCompleted >= 100 && this.props.addedAttachment.isUploadInProgress}/>
                </div>
            )}

            {
                this.state.mediaResources.map((mediaResource) => (
                    <div key={mediaResource.id} className="add-attachment-row cursor-pointer">
                        <div className="add-attachment-container">
                            <span
                                className="add-attachment-name">{getFileNameFromPath(mediaResource.originalFileName || mediaResource.name)}</span>
                            <span className="remove-attachment-icon icon icon-font-a-close add-attachment-remove-icon"
                                  onClick={() => this.onAttachmentRemoved(mediaResource.id)}></span>
                        </div>
                    </div>
                ))
            }
            <div className="clr"></div>
        </div>
    )

    render() {
        const currentUser = isEmpty(getItemFromStorage('currentUser')) ? null : JSON.parse(getItemFromStorage('currentUser'));
        if (this.props.isBindedWithModal !== undefined && !this.props.isBindedWithModal) {
            return (this.renderAttachmentContent());
        }
        return (
            <React.Fragment>
                {
                    this.state.show && (
                        <Modal
                            show={this.state.show}
                            onHide={this.onModalClose}
                            container={document.body}
                            autoFocus
                            backdrop="static"
                            aria-labelledby="contained-modal-title"
                            className={`${currentUser && (currentUser.type === USER_TYPE_PATIENT || currentUser.type === USER_TYPE_CAREGIVER) ? 'primary-modal' : ''}`}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title">Add Attachment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.renderAttachmentContent()}
                                <button className="btn btn-primary float-right button-done"
                                        disabled={this.props.addedAttachment.isUploadInProgress}
                                        onClick={this.onDoneClick}>Done
                                </button>
                                <div className="clr"></div>
                            </Modal.Body>
                        </Modal>
                    )
                }
                {
                    this.props.showAddedAttachments && <Attachments mediaResources={this.state.mediaResources}
                                                                    onAttachmentRemoved={this.onAttachmentRemoved}
                                                                    isAdded/>
                }
            </React.Fragment>
        );
    }
}

AddAttachmentModal.propTypes = {
    uploadDocumentClearState: PropTypes.func,
    uploadDocumentRemoveDocument: PropTypes.func,
    showToaster: PropTypes.func,
    uploadDocument: PropTypes.func,
    addedAttachment: PropTypes.object,
    onAttachmentsAdded: PropTypes.func.isRequired,
    onAttachmentRemoved: PropTypes.func,
    showModal: PropTypes.bool,
    isBindedWithModal: PropTypes.bool,
    mediaResources: PropTypes.array,
    isExtra: PropTypes.bool,
    fileFormats: PropTypes.string,
    showAddedAttachments: PropTypes.bool,
    callbackOnDone: PropTypes.func,
    onModalClose: PropTypes.func,
    resetLocalState: PropTypes.func,
    onAttachmentRemoved1: PropTypes.func,
};

AddAttachmentModal.defaultProps = {
    fileFormats: IMAGE_FILE_FORMATS.toString(),
    showAddedAttachments: true,
};

const mapStateToProps = (state, ownProps) => {
    const attachment = {
        isUploadInProgress: state.attachments.addedAttachment.isUploadInProgress,
        isUploadSuccess: state.attachments.addedAttachment.isUploadSuccess,
        document: ownProps.isExtra ? state.attachments.addedAttachment.extraDoc.document : state.attachments.addedAttachment.document,
        mediaResource: ownProps.isExtra ? state.attachments.addedAttachment.extraDoc.mediaResource : state.attachments.addedAttachment.mediaResource,
        error: state.attachments.addedAttachment.error,
    };
    return {
        addedAttachment: attachment,
    };
};

const mapDispatchToProps = (dispatch) => ({
    uploadDocument: (fileName, fileSize, fromType, mediaType, duration, file, onUploadProgress, isExtra) => dispatch(uploadDocumentWithAuthUrl(fileName, fileSize, fromType, mediaType, duration, file, false, onUploadProgress, isExtra)),
    uploadDocumentClearState: () => dispatch(uploadDocumentClearState()),
    uploadDocumentRemoveDocument: (id) => dispatch(uploadDocumentRemoveDocument(id)),
    showToaster: (type, message) => dispatch(showToaster({type, message})),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
    key: 'attachments',
    reducer: combineReducers({
        addedAttachment: documentsReducer,
    }),
});
export default compose(
    withReducer,
    withConnect
)(AddAttachmentModal);
