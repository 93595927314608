import {
  GET_LOCATION_PROVIDERS_REQUEST_SENT,
  GET_LOCATION_PROVIDERS_REQUEST_SUCCEED,
  GET_LOCATION_PROVIDERS_REQUEST_FAILED,
  GENERATE_OTP_REQUEST_SENT,
  GENERATE_OTP_REQUEST_SUCCEED,
  GENERATE_OTP_REQUEST_FAILED,
  VERIFY_OTP_REQUEST_SENT,
  VERIFY_OTP_REQUEST_SUCCEED,
  VERIFY_OTP_REQUEST_FAILED,
  CREATE_PATIENT_REQUEST_SENT,
  CREATE_PATIENT_REQUEST_SUCCEED,
  CREATE_PATIENT_REQUEST_FAILED,
  GET_FLOW_ID_REQUEST_SENT,
  GET_FLOW_ID_REQUEST_SUCCEED,
  GET_FLOW_ID_REQUEST_FAILED,
  WELCOME_VIEW,
  PAGE_CHANGE,
  SET_QR_DETAILS,
  SET_SIGNUP_DATA,

  SAVE_PROCEDURE_REQUEST_SENT,
  SAVE_PROCEDURE_REQUEST_SUCCEEDED,
  SAVE_PROCEDURE_REQUEST_FAILED,
  SET_EMAIL_OTP_IN_STATE, SET_PHONE_OTP_IN_STATE, SET_USER_CONSENT, SET_PROCEDURE_CONFIRMATION, SET_SIGNUP_DATA_FOR_EXISTING_USER, SET_EXISTING_USER_FLAG, CLEAR_SIGNUP_DATA,
  GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SENT,
  GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SUCCEEDEED,
  GET_MODIFIER_AND_FACILITY_DATA_REQUEST_FAILED,
  NAME_VIEW,
  DISPATCH_DEEPLINK_URL,
  SET_IS_LOADING,
  VALIDATE_CONTACT_NUMBER_REQUEST_SENT,
  VALIDATE_CONTACT_NUMBER_REQUEST_SUCCEEDEED,
  VALIDATE_CONTACT_NUMBER_REQUEST_FAILED,
} from "./constants";

import history from "../../configureHistory";

const getQueryParam = (name) => {
  let q = window.location.search.match(new RegExp('[?&]' + name + '=([^&#]*)'));
  return q && q[1];
}

const formDataInitialValues={
  hasUserConsented: false,
  firstName:"",
  lastName:"",
  email:"",
  phone:"",
  addressLine1:"",
  addressLine2:"",
  city:"",
  state:"",
  zipCode:"",
  stomaMeasuringAppt: null,
  surgeryDate: null,
  procedure: null,
  modifierIdentifier: {label: "", value: ""},
  facilityId: {label: "", value: ""},
  educationPreference: {label: "", value: false},
  facilityName: null,
  facilityCode: null,
  marketingConsent: false,
  serviceEnrollment: false,
  emailOtp:"",
  phoneOtp: "",
  isProcedureValid: true,
  existingUser: null
}

const initialState = {
  isLoading: false,
  page: getQueryParam('flow_type') === 'mobile_sign_up' ? NAME_VIEW : window.location.hash ? parseInt(window.location.hash.replace("#", "")) : WELCOME_VIEW,
  surgeonOptions: [],
  hasUserConsented: false,
  //procedure: {},
  generalCardiologists: [],
  flowId: "",
  flowType: getQueryParam('flow_type') || "sign_up",
  isMfaEnabled: true,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipCode: "",
  stomaMeasuringAppt: null,
  surgeryDate: null,

  procedure: null,
  modifierIdentifier: {label: "", value: ""},
  facilityId: {label: "", value: ""},
  educationPreference: {label: "", value: false},
  facilityName: null,
  facilityCode: null,

  marketingConsent: false,
  serviceEnrollment: false,
  emailOtp:"",
  phoneOtp: "",
  isProcedureValid: true,
  existingUser: null,
  isValidQR: false,
  deepLinkUrl: ""
};

export const patientRegisterReducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_LOCATION_PROVIDERS_REQUEST_FAILED:
    case GET_LOCATION_PROVIDERS_REQUEST_SENT:
    case GENERATE_OTP_REQUEST_SENT:
    case VERIFY_OTP_REQUEST_SENT:
    case CREATE_PATIENT_REQUEST_SENT:
    case GET_FLOW_ID_REQUEST_SENT:
    case SAVE_PROCEDURE_REQUEST_SENT:
    case GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SENT:
    case VALIDATE_CONTACT_NUMBER_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case GENERATE_OTP_REQUEST_FAILED:
    case VERIFY_OTP_REQUEST_FAILED:
    case CREATE_PATIENT_REQUEST_FAILED:
    case GET_FLOW_ID_REQUEST_FAILED:
    case SAVE_PROCEDURE_REQUEST_FAILED:
    case GET_MODIFIER_AND_FACILITY_DATA_REQUEST_FAILED:
    case VALIDATE_CONTACT_NUMBER_REQUEST_SUCCEEDEED:
    case VALIDATE_CONTACT_NUMBER_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_LOCATION_PROVIDERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        surgeonOptions: action.locationProviders.providers,
        procedure: action.locationProviders.procedure,
        generalCardiologists: action.locationProviders.generalCardiologists,
        isMfaEnabled: action.locationProviders.isMfaEnabled,
      };
    case GET_FLOW_ID_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        flowType: action.data.flowType,
        flowId: action.data.flowId,
      };
    case DISPATCH_DEEPLINK_URL:
      return {
        ...state,
        deepLinkUrl: action.data
      }
    case GENERATE_OTP_REQUEST_SUCCEED:
    case VERIFY_OTP_REQUEST_SUCCEED:
    case CREATE_PATIENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
      };
    case SAVE_PROCEDURE_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
      };
    case PAGE_CHANGE:
      return {
        ...state,
        page: action.page,
      };
    case SET_QR_DETAILS:
      return {
        ...state,
        modifierIdentifier: action.procedure,
        facilityCode: action.facilityName,
      };

    case SET_SIGNUP_DATA:
      return {
        ...state,
        ...action.data,
      };

      case SET_SIGNUP_DATA_FOR_EXISTING_USER:
      return {
        ...state,
        ...action.data,
      };

      case CLEAR_SIGNUP_DATA:
        return{
          ...state,
          ...formDataInitialValues
        }
      
      case SET_IS_LOADING:
        return{
          ...state,
          isLoading: action.data
        }

      case GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SUCCEEDEED:
        return {
          ...state,
          isLoading: false,
          procedure: action.data.modifierData.value,
          modifierIdentifier: action.data.modifierData.modifierIdentifier,
          facilityName: action.data.facilityData.name,
          facilityCode: action.data.facilityData.code,
          isValidQR: ((action.data.modifierData.modifierIdentifier != null) && (action.data.facilityData.name != null))
        }

      case SET_EMAIL_OTP_IN_STATE:
      return {
        ...state,
        emailOtp: action.data
      };
      case SET_PHONE_OTP_IN_STATE:
      return {
        ...state,
        phoneOtp: action.data
      };
      case SET_USER_CONSENT:
      return {
        ...state,
        hasUserConsented: true
      };
      case SET_PROCEDURE_CONFIRMATION:
        return {
          ...state,
          isProcedureValid: action.data
        }
      case SET_EXISTING_USER_FLAG:
        return{
          ...state,
          existingUser: action.data
        }
      default:
        return state;
  }
};
