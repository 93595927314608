import axios from "./base";
import {ADD_NEW_LOCATION, GET_ALL_LOCATIONS_OF_THE_DEPARTMENT, GET_TIMEZONES, UPDATE_LOCATION, UPLOAD_FILE} from "./constants";

export const fetchAllLocationsOfTheDepartment = (params) =>
    axios.get(GET_ALL_LOCATIONS_OF_THE_DEPARTMENT, {params});

export const addNewLocation = (params) => axios.post(ADD_NEW_LOCATION, params);

export const updateLocation = (params) => {
    const url = UPDATE_LOCATION.replace(":id", params.id);
    return axios.put(url, params);
};

export const fetchTimezones = () =>
    axios.get(GET_TIMEZONES);

export const uploadFile = (params) => {
    const config = {
        timeout: 60000,
        headers: {
            'content-type': 'multipart/form-data',
        },
    };
    return axios.post(UPLOAD_FILE, params, config);
}