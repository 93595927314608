/*
 *
 * CONVERSATIONS constants
 *
 */

export const GET_CONVERSATIONS_REQUEST_SENT = 'app/conversations/GET_CONVERSATIONS_REQUEST_SENT';
export const GET_CONVERSATIONS_REQUEST_SUCCEED = 'app/conversations/GET_CONVERSATIONS_REQUEST_SUCCEED';
export const GET_CONVERSATIONS_REQUEST_FAILED = 'app/conversations/GET_CONVERSATIONS_REQUEST_FAILED';

export const SHOW_CONVERSATION_DETAILS = 'app/conversations/SHOW_CONVERSATION_DETAILS';
export const SHOW_CONVERSATIONS_LIST = 'app/conversations/SHOW_CONVERSATIONS_LIST';

export const START_CONVERSATION_REQUEST_SENT = 'app/conversations/START_CONVERSATION_REQUEST_SENT';
export const START_CONVERSATION_REQUEST_SUCCEED = 'app/conversations/START_CONVERSATION_REQUEST_SUCCEED';
export const START_CONVERSATION_REQUEST_FAILED = 'app/conversations/START_CONVERSATION_REQUEST_FAILED';

export const RESOLVE_CONVERSATION_REQUEST_SENT = 'app/conversations/RESOLVE_CONVERSATION_REQUEST_SENT';
export const RESOLVE_CONVERSATION_REQUEST_SUCCEED = 'app/conversations/RESOLVE_CONVERSATION_REQUEST_SUCCEED';
export const RESOLVE_CONVERSATION_REQUEST_FAILED = 'app/conversations/RESOLVE_CONVERSATION_REQUEST_FAILED';

export const RESET_DATA_REQUEST = 'app/conversations/RESET_DATA_REQUEST';

