import React from 'react';
import {components} from 'react-select';

const getIconClass = (isSelected) => {
    return isSelected ? 'icon icon-2a-checked' : 'icon icon-2a-unchecked'
}

const Option = (props) => {
    if (props.value === '*') {
        return (
            <components.Option {...props}>
                <label>
                    {props.label}
                </label>
            </components.Option>
        );
    }
    return (
        <components.Option {...props}>
            <label>
                <span className={getIconClass(props.isSelected)}/>
                <span>{props.label}</span>
            </label>
        </components.Option>
    );
};

export default Option;
