/**
 *
 * Attachment Card
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {doGetDownloadLink} from 'services/documents';
import DownloadFile from 'components/DownloadFile';
import './attachment_card.scss';
import {getFileNameFromPath} from '../../utils/stringUtils';


class AttachmentCard extends React.PureComponent {
    onAttachmentRemoved = (attachmentId) => {
        this.props.onAttachmentRemoved(attachmentId);
    }

    getAttachmentIcon(attachmentType) {
        switch (attachmentType) {
            case 'image':
                return (<span className="icon icon-font-a-attachment-jpeg primary-text-color"></span>);
            case 'audio':
                return (<span className="icon icon-font-a-sound-file primary-text-color"></span>);
            case 'video':
                return (<span className="icon icon-font-b-video-file primary-text-color"></span>);
            default:
                return (<span className="icon icon-font-b-generic-document primary-text-color"></span>);
        }
    }

    render() {
        const {attachment, isAdded, index} = this.props;
        return (
            <div className={`separation-${index} attachment-row row cursor-pointer`} key={attachment.id}>
                {
                    isAdded && (
                        <span className="remove-attachment-container float-left">
              <DownloadFile
                  args={[attachment.id]}
                  serviceCall={doGetDownloadLink}
              >
                <span role="button" tabIndex="0" className="attachment-name">
                  {getFileNameFromPath(attachment.originalFileName && attachment.originalFileName !== '' ? attachment.originalFileName : attachment.name)}
                </span>
              </DownloadFile>
              <span
                  role="button"
                  tabIndex="0"
                  className="remove-attachment-icon icon icon-font-a-close remove-icon"
                  onClick={() => this.onAttachmentRemoved(attachment.id)}
              >
              </span>
            </span>
                    )}
                {
                    !isAdded && (
                        <DownloadFile
                            args={[attachment.id]}
                            serviceCall={doGetDownloadLink}
                        >
                            <div role="button" tabIndex="0" className="float-left">
                                <span className="attachment-icon">{this.getAttachmentIcon(attachment.mediaType)}</span>
                                <span
                                    className="attachment-name">{getFileNameFromPath(attachment.originalFileName && attachment.originalFileName !== '' ? attachment.originalFileName : attachment.name)}</span>
                            </div>
                        </DownloadFile>
                    )
                }
            </div>
        );
    }
}


AttachmentCard.propTypes = {
    attachment: PropTypes.object,
    isAdded: PropTypes.bool,
    onAttachmentRemoved: PropTypes.func,
    index: PropTypes.number,
};

export default AttachmentCard;
