import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {combineReducers, compose} from "redux";
import injectReducer from "utils/injectReducer";
import Cookies from 'universal-cookie';
import {getCookieConsentText, postCookieConsent,} from './actions';
import {getCookieConsentReducer} from "./reducer";
import "./cookieConsent.scss";

const UserCookieConsent = (props) => {
    const cookies = new Cookies();
    const [isConsentProvided, setIsConsentProvided] = useState(cookies.get('cookieConsentProvided') || false);

    useEffect(() => {
        props.getCookieConsent()
    }, [])

    const onAgreeClick = () => {
        if (!isConsentProvided) {
            setIsConsentProvided(true);
            cookies.set('cookieConsentProvided', true);
            props.postCookieConsent(
                !isConsentProvided
            );
        }
    }

    const RenderConsentText = () => (
        <div className="consent-text" dangerouslySetInnerHTML={{__html: props.cookieConsentText}}/>
    )

    const RenderUserConsentPopUp = () => {
        return (
            <div className="consent-footer">
                <div className="consent-text-wrapper">
                    <span className="pop-up-header">This website uses cookies</span>
                    <br/>
                    <RenderConsentText/>
                </div>
                <div className="consent-buttons-wrapper">
                    <span role="button" className="agree" onClick={onAgreeClick}>Accept</span>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {
                (props.isLoading || isConsentProvided) ?
                    null
                    : <RenderUserConsentPopUp/>
            }
        </React.Fragment>
    );
}

UserCookieConsent.propTypes = {
    isLoading: PropTypes.bool,
    consentProvided: PropTypes.bool,
    postCookieConsent: PropTypes.func
};

const mapStateToProps = (state) => ({
    cookieConsentText: state.cookieConsent.getCookieConsentReducer.cookieConsentText,
    isLoading: state.cookieConsent.getCookieConsentReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    getCookieConsent: () => dispatch(getCookieConsentText()),
    postCookieConsent: (params) => dispatch(postCookieConsent(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
    key: "cookieConsent",
    reducer: combineReducers({
        getCookieConsentReducer,
    }),
});

export default compose(withReducer, withConnect)(UserCookieConsent);
