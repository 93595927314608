import React from "react";
import Stack from "@mui/material/Stack";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import InputTextField from "./../../../components/StyleGuideComponents/InputTextField";
import validate from "common/validator";
import { addressViewValidation } from "./../validators";
import { setSignUpData, setSignUpDataInStore } from "./../action";
import { PROCEDURE_DATES_VIEW, NAME_VIEW } from "./../constants";
import { validateZipCode } from "../../../services/patientDetails";
import { SET_IS_LOADING } from "./../constants";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const AddressView = props => {

  const patientRegister = useSelector((store) => store.register.patientRegisterReducer)
  const dispatch = useDispatch()

  const [ state, setState ] = useState({
    addressLine1: patientRegister.addressLine1 || "",
    addressLine2: patientRegister.addressLine2 || "",
    city: patientRegister.city || "",
    state: patientRegister.state || "",
    zipCode: patientRegister.zipCode || "",
    errors: []});

    useEffect(() => {
      return () => {
        dispatch(
          setSignUpDataInStore({
            ...state,
            zipCode: state.zipCode.length === 6 ? state.zipCode.trim().replace('-', '') : state.zipCode
          })
        );
      };
    }, [state]);

  const handleChange = (name, value) => {
    let errors = state.errors
    delete errors[name]
    if(value) value = name === 'zipCode' && value.length === 7 ? value.replace("-",''):value
    setState({...state, [name]: value,errors });
  };

  const onValidationFailure = (errors) => {
    setState({...state, errors });
  };

  const onNextValidationSuccess = () => {
    dispatch(setSignUpData({...state,zipCode: state.zipCode.length === 6 ? state.zipCode.trim().replace('-','') : state.zipCode}, props.nextPage, PROCEDURE_DATES_VIEW))           
  };

  const checkIfButtonEnabled = ()  => state.addressLine1 && 
                                      state.addressLine1.length > 0 && 
                                      state.city && 
                                      state.city.length > 0 && 
                                      state.state && 
                                      state.state.length > 0 && 
                                      state.zipCode && state.zipCode.length > 0

  const handleSubmit = async (e) => {
    e.preventDefault();
    const initErrors = {};
    dispatch({type: SET_IS_LOADING, data: true})
    try {
      const { data } = await validateZipCode(state.zipCode.length === 6 ? state.zipCode.replace("-",'') : state.zipCode);
      dispatch({type: SET_IS_LOADING, data: false})
      if (!data.valid) {
        initErrors.zipCode = "Zipcode is invalid. Supported format is 12345 (5 digit)";
        dispatch({type: SET_IS_LOADING, data: false})
      }
    } catch (error) {
      initErrors.zipCode = "Zipcode is invalid. Supported format is 12345 (5 digit)";
      dispatch({type: SET_IS_LOADING, data: false})
    }

    validate(
      addressViewValidation,
      state,
      onValidationFailure,
      onNextValidationSuccess,
      initErrors,
    );
  };

    return (
      <div style={{paddingBottom:"-6%"}}>

        <form type="post">
          <div>
            <Stack sx={{zIndex:"0"}} spacing={"24px"}>

              <InputTextField
                id="addressLine1"
                value={state.addressLine1}
                label="Address Line 1"
                name="addressLine1"
                onChange={ handleChange }
                required
                error={"addressLine1" in state.errors}
                errorText={
                  "addressLine1" in state.errors
                    ? state.errors.addressLine1
                    : ""
                }
              />

              <InputTextField
                value={state.addressLine2}
                id="addressLine2"
                label="Address Line 2"
                name="addressLine2"
                onChange={ handleChange }
              />

              <InputTextField
                value={state.city}
                id="city"
                label="City"
                name="city"
                onChange={ handleChange }
                required
                error={"city" in state.errors}
                errorText={
                  "city" in state.errors ? state.errors.city : ""
                }
              />

              <InputTextField
                value={state.state}
                id="state"
                label="State"
                name="state"
                onChange={ handleChange }
                required
                error={"state" in state.errors}
                errorText={
                  "state" in state.errors ? state.errors.state : ""
                }
              />

              <InputTextField
                value={state.zipCode}
                id="zipCode"
                label="Zipcode"
                name="zipCode"
                onChange={ handleChange }
                required
                maxLength={10}
                error={"zipCode" in state.errors}
                errorText={
                  "zipCode" in state.errors
                    ? state.errors.zipCode
                    : ""
                }
              />

            </Stack>
            <div className="continue-button-for-form">
              <PrimaryButton
              disabled={!checkIfButtonEnabled()}
                fullWidth
                text={props.nextButtonText}
                onClick={handleSubmit}
              />
              </div>
          </div>
        </form>

      </div>
    );
}

export default AddressView;

