/*
 *
 * UserCookieConsent actions
 *
 */
import {getCookieConsentTextApiCall, postCookieConsentProvided} from '../../services/common';

import {
    GET_COOKIE_CONSENT_REQUEST_FAILED,
    GET_COOKIE_CONSENT_REQUEST_SENT,
    GET_COOKIE_CONSENT_REQUEST_SUCCEED,
    POST_COOKIE_CONSENT_PROVIDED_FAILED,
    POST_COOKIE_CONSENT_PROVIDED_SENT,
    POST_COOKIE_CONSENT_PROVIDED_SUCCEED,
} from './constants';
import { showToaster } from 'common/toasterActions';

export const getCookieConsentRequestSent = () => ({
    type: GET_COOKIE_CONSENT_REQUEST_SENT,
});

export const getCookieConsentRequestSucceeded = (data) => ({
    type: GET_COOKIE_CONSENT_REQUEST_SUCCEED,
    data,
});

export const getCookieConsentRequestFailed = () => ({
    type: GET_COOKIE_CONSENT_REQUEST_FAILED,
});

export const cookieConsentProvidedSent = () => ({
    type: POST_COOKIE_CONSENT_PROVIDED_SENT,
});

export const cookieConsentProvidedSucceeded = (data) => ({
    type: POST_COOKIE_CONSENT_PROVIDED_SUCCEED,
    data,
});

export const cookieConsentProvidedFailed = () => ({
    type: POST_COOKIE_CONSENT_PROVIDED_FAILED,
});


// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getCookieConsentText = () => (dispatch) => {
    dispatch(getCookieConsentRequestSent());
    getCookieConsentTextApiCall()
        .then((response) => {
            dispatch(getCookieConsentRequestSucceeded(response.data));
        })
        .catch(() => {
            dispatch(getCookieConsentRequestFailed());
        });
};

export const postCookieConsent = (params) => (dispatch) => {
    dispatch(cookieConsentProvidedSent());
    postCookieConsentProvided(params)
        .then((response) => {
            dispatch(cookieConsentProvidedSucceeded(response.data));
        })
        .catch((error) => {
            dispatch(cookieConsentProvidedFailed(error.response.data));
            dispatch(
                showToaster({type: "error", message: error.response.data.error})
            );
        });
};
