import React from "react";
import "../patientRegister.scss";
import PropTypes from "prop-types";
import DateTime from "react-datetime";
import moment from "moment";
import FloatingLabelInput from "components/FloatingLabelInput";
import HelpBlock from "components/HelpBlock";
import StringUtils from "../../../strings";
import CheckedLogo from "../../../assets/images/eds_logos/greenchecked.png";
import Stack from "@mui/material/Stack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import { patientRegisterReducer } from "../reducers";

import Password from "../../../components/StyleGuideComponents/Password";
import InputTextField from "../../../components/StyleGuideComponents/InputTextField";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import { generateOtp, verifyOtp } from "../action";
import validate, { clearErrorsForField } from "common/validator";
import { emailOtpValidationConfig } from "./../validators";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Media from "react-media";
import { setOtpInState} from './../action'
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { getItemFromStorage } from "services/storage";
import isEmpty from "lodash/isEmpty";
import { Redirect } from "react-router";
import { onForgotPasswordRequest } from './../../ForgotPassword/actions';
import reducer from './../../ForgotPassword/reducer';
import Modal from 'react-bootstrap/lib/Modal';

import includes from 'lodash/includes';
import Strings from '../../../strings';

import {
  signInUser,
} from "common/authThunkActions";

import {
  LOGGED_IN_USER_PROCEDURE_VIEW, FORGOT_PASSWORD_VIEW, PASSWORD_RESET_SUCCESS_VIEW
  } from "./../constants";
class ForgotPasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flowId: props.flowId || null,
      flowType: props.flowType || "sign_up",
      firstName: props.firstName || "",
      emailOtp: props.emailOtp || "",
      email: props.email || "",
      errors: [],
      phone: props.phone || "",
      zipCode: props.zipCode || "",
      emailOrMobileNumber: "",
      password: "",
      isSignedIn: props.isSignedIn,
      page: props.page,
      forgotPasswordRequestSent: props.forgotPasswordSent,
      showLoginHelpModal: false

    };
  }

  phoneRule = [
    { rule: "isPhone", message: "Mobile Number or Email is invalid" },
    { rule: "isRequired", message: "Mobile Number or Email is required" },
  ];

  emailRule = [
    { rule: "isEmail", message: "Mobile Number or Email is invalid" },
    { rule: "isRequired", message: "Mobile Number or Email is required" },
  ];

  validationConfig = {
    fields: ["emailOrMobileNumber"],
    rules: {
      emailOrMobileNumber: [
        { rule: "isEmail", message: "Mobile Number or Email is invalid" },
        { rule: "isRequired", message: "Mobile Number or Email is required" },
      ],
    },
  };

  handleChange = (name, value) => {
    let errors = this.state.errors
    delete errors[name]

this.setState({ [name]: value,errors:errors },()=>{
});
  };

  onSubmitClick = () => {

    const { emailOrMobileNumber, password, otp } = this.state;
    const params = {
      password,
      code: otp,
    };
    if (emailOrMobileNumber.includes("@")) {
      params.email = emailOrMobileNumber;
    } else {
      const contactNumber = emailOrMobileNumber.replace(/[^a-zA-z0-9]/g, "");
      params.contact_number = contactNumber;
    }
    params.has_accepted_terms_and_condition = true;
    params.tnc_url = this.props.termsAndConditionsUrl;
    this.props.signInUser(params);

  }


  onFormValidationSuccess = () => {
    this.setState({
      errors: {},
    });
    const { emailOrMobileNumber, password, otp } = this.state;
    const params = {
      password,
      code: otp,
    };
    if (emailOrMobileNumber.includes("@")) {
      params.email = emailOrMobileNumber;
    } else {
      const contactNumber = emailOrMobileNumber.replace(/[^a-zA-z0-9]/g, "");
      params.contact_number = contactNumber;
    }
    const key = includes(this.state.emailOrMobileNumber, '@') ? 'email' : 'contact_number';
    const data = {
      [key]: this.state.emailOrMobileNumber,
    };
    this.props.forgotPasswordRequest(data);
  };

  onFormValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };
  submitForm = (e) => {
    e.preventDefault();
    if (!this.state.emailOrMobileNumber.includes("@")) {
      this.validationConfig.rules.emailOrMobileNumber = this.phoneRule;
    } else {
      this.validationConfig.rules.emailOrMobileNumber = this.emailRule;
    }
    validate(
      this.validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };


  componentDidMount() {

  }

  componentDidUpdate() {
    if(this.state.forgotPasswordRequestSent && this.state.page == 11){
      this.props.nextPage(PASSWORD_RESET_SUCCESS_VIEW);
    }
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.isSignedIn != this.state.isSignedIn){
      this.setState({isSignedIn: nextProps.isSignedIn});
    }
    if(nextProps.page != this.state.page){
      this.setState({page: nextProps.page});
    }
    if(nextProps.forgotPasswordSent != this.state.forgotPasswordRequestSent){
      this.setState({forgotPasswordRequestSent: nextProps.forgotPasswordSent});
    }
  }

  // handleChange = (name, value) => {
  //   this.setState({ [name]: value });
  // };

  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onForgotPasswordEvent = () => {
    this.props.nextPage(FORGOT_PASSWORD_VIEW);
  }

  onNextValidationSuccess = () => {
    const params = {
      verification_code: this.state.emailOtp,
      flow_type: this.state.flowType,
      flow_id: this.state.flowId,
      email: this.state.email,
    };
    this.props.setOtpInReduxState({emailOtp: this.state.emailOtp})
    this.props.verifyEmailOtp(params, this.props.nextPage);

    // this.props.nextPage();
  };

  render() {
    return (
      <div className="no-padding">
                <Modal
          show={this.state.showLoginHelpModal}
          onHide={()=>{this.setState({showLoginHelpModal:false})}}
          container={document.body}
          aria-labelledby="contained-modal-title"
          className="primary-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="footer-content-container">
              <div className="text">Feel free to contact us via email or phone number.</div>
              <div className="text">We would be happy to address your queries.</div>
              <div className="phone-container">
                <i className="icon icon-font-a-contact"></i>
                <span className="phone">1-866-704-4447</span>
              </div>
              <div className="email-container">
                <i className="icon icon-font-a-email"></i>
                <span className="email">{Strings.SUPPORT_EMAIL}</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Container maxWidth="lg">
        <div className="otp-message">
                Please enter your{" "}
              <span class="login-view-header">Email or Mobile Number</span>
            </div>
          <Box className="login-page-container forgot-password-wrapper">
            <div className="login-form">
            <Stack sx={{zIndex:"0", width:"100%"}} spacing={"24px"}>
                  <InputTextField
                    id="email"
                    label="Mobile Number or Email Address"
                    name="emailOrMobileNumber"
                    value={this.state.emailOrMobileNumber}
                    onChange={ this.handleChange }
                    required
                    error={"emailOrMobileNumber" in this.state.errors}
                    errorText={
                      "emailOrMobileNumber" in this.state.errors
                        ? this.state.errors.emailOrMobileNumber
                        : ""
                    }
                  />
                </Stack>
            </div>
            <div>
            <PrimaryButton disabled={!(this.state.emailOrMobileNumber.length>0 )} onClick={this.submitForm} text="Continue"  />
            </div>
          </Box>
          <div>
            <div onClick={()=>this.setState({showLoginHelpModal:true})} className="login-help-text text-align-center cursor-pointer">Login Help?</div>
            </div>
        </Container>
      </div>

    );
  }
}

ForgotPasswordView.propTypes = {
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  email: PropTypes.string,
  isSignedIn: PropTypes.bool.isRequired,
  forgotPasswordRequest: PropTypes.func,
};

const mapStateToProps = (state) => ({
  firstName: state.patientRegister.patientRegisterReducer.firstName,
  email: state.register.patientRegisterReducer.email || state.register.reducer.email,
  phone: state.patientRegister.patientRegisterReducer.phone,
  zipCode: state.patientRegister.patientRegisterReducer.zipCode,
  page: state.patientRegister.patientRegisterReducer.page,
  flowType: state.patientRegister.patientRegisterReducer.flowType,
  forgotPasswordSent: state.patientRegister.reducer.forgotPasswordSent,
  flowId:
    state.patientRegister.patientRegisterReducer.flowId,
  isSignedIn:
      state.currentUser.isSignedIn || !isEmpty(getItemFromStorage("token")),
});

const mapDispatchToProps = (dispatch) => ({
  signInUser: (params) => dispatch(signInUser(params)),
  generateEmailOtp: (params, nextPage, redirect = true) =>
    dispatch(generateOtp(params, nextPage, redirect)),
  setOtpInReduxState: (params) =>
    dispatch(setOtpInState(params)),
  forgotPasswordRequest: (params) => dispatch(onForgotPasswordRequest(params)),
  generatePhoneOtp: (params, nextPage) =>
    dispatch(generateOtp(params, nextPage)),
  verifyEmailOtp: (params, nextPage) => dispatch(verifyOtp(params, nextPage)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: "patientRegister",
  reducer: combineReducers({
    patientRegisterReducer,
    reducer
  }),
});

export default compose(withReducer, withConnect)(ForgotPasswordView);
