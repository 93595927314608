/*
 *
 * Profile Page reducer
 *
 */
import { RESET_GLOBAL_REDUCERS } from '../constants';
import * as Constants from './constants';
import camelcaseKeys from 'change-case-object';

export const docInitState = {
  isUploadInProgress: false,
  isUploadSuccess: false,
  document: null,
  mediaResource: {},
  error: '',
  extraDoc: {
    document: null,
    mediaResource: {},
  },
};

export const documentsReducer = (state = docInitState, action) => {
  switch (action.type) {
    case Constants.GET_DOC_AUTHORIZED_URL_REQUEST_SENT:
    case Constants.GET_DOC_AUTHORIZED_URL_REQUEST_SUCCEED:
    case Constants.DOCUMENT_UPLOAD_REQUEST_SENT:
      return {
        ...state,
        isUploadInProgress: true,
        error: '',
      };
    case Constants.DOCUMENT_UPLOAD_REQUEST_SUCCEED:
      if (action.data.isExtra) {
        return {
          ...state,
          isUploadInProgress: false,
          isUploadSuccess: true,
          extraDoc: {
            document: action.data.url,
            mediaResource: action.data.mediaResource,
          },
          error: '',
        };
      }
      return {
        ...state,
        isUploadInProgress: false,
        isUploadSuccess: true,
        document: action.data.url,
        mediaResource: action.data.mediaResource,
        error: '',
      };
    case Constants.DOCUMENT_UPLOAD_REQUEST_FAILED:
    case Constants.GET_DOC_AUTHORIZED_URL_REQUEST_FAILED:
      return {
        ...state,
        isUploadInProgress: false,
        isUploadSuccess: false,
        error: 'Could not upload the document, please try again.',
      };
    case Constants.DOCUMENT_UPLOAD_CLEAR_STATE:
      return docInitState;
    case Constants.DOCUMENT_UPLOAD_REMOVE_DOCUMENT:
      // return {
      //   ...state,
      //   isUploadInProgress: false,
      //   mediaResource: state.mediaResources.filter((item) => item.id !== action.id),
      //   error: '',
      // };
      return docInitState;
    case RESET_GLOBAL_REDUCERS:
      return docInitState;
    default:
      return state;
  }
};

const locationsInitialState = {
  isLoading: false,
  locations: [],
};

export const getProcedureLocationsReducer = (state = locationsInitialState, action) => {
  switch (action.type) {
    case Constants.GET_PROCEDURE_LOCATIONS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_PROCEDURE_LOCATIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        locations: action.data.locations,
      };
    case Constants.GET_PROCEDURE_LOCATIONS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return locationsInitialState;
    default:
      return state;
  }
};

export const getProcedureProvidersReducer = (state = locationsInitialState, action) => {
  switch (action.type) {
    case Constants.GET_PROCEDURE_PROVIDERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_PROCEDURE_PROVIDERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        providers: action.data.providers,
      };
    case Constants.GET_PROCEDURE_PROVIDERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return locationsInitialState;
    default:
      return state;
  }
};

const procedureModifiersInitialState = {
  isLoading: false,
  procedureModifiers: [],
};

export const getProcedureModifiersReducer = (state = procedureModifiersInitialState, action) => {
  switch (action.type) {
    case Constants.GET_PROCEDURE_MODIFIERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_PROCEDURE_MODIFIERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        procedureModifiers: action.data.modifiers,
      };
    case Constants.GET_PROCEDURE_MODIFIERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return procedureModifiersInitialState;
    default:
      return state;
  }
};

const updateProcedureModifiersInitState = {
  isLoading: false,
  message: null,
};

export const updateProcedureModifiersReducer = (state = updateProcedureModifiersInitState, action) => {
  switch (action.type) {
    case Constants.UPDATE_PROCEDURE_MODIFIERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        message: null,
      };
    case Constants.UPDATE_PROCEDURE_MODIFIERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        message: 'Procedure Updated Successfully',
      };
    case Constants.UPDATE_PROCEDURE_MODIFIERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getDepartmentProceduresInitialState = {
  isLoading: false,
  procedures: [],
};

export const getDepartmentProceduresReducer = (state = getDepartmentProceduresInitialState, action) => {
  switch (action.type) {
    case Constants.GET_DEPARTMENT_PROCEDURES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_DEPARTMENT_PROCEDURES_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        procedures: action.data.procedures,
      };
    case Constants.GET_DEPARTMENT_PROCEDURES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};


const getDepartmentProvidersInitialState = {
  isLoading: false,
  providers: [],
};

export const getDepartmentProvidersReducer = (state = getDepartmentProvidersInitialState, action) => {
  switch (action.type) {
    case Constants.GET_DEPARTMENT_PROVIDERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_DEPARTMENT_PROVIDERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        providers: action.data.providers,
      };
    case Constants.GET_DEPARTMENT_PROVIDERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};


const getDepartmentOnlyProvidersInitialState = {
  isLoading: false,
  onlyProviders: [],
};

export const getDepartmentOnlyProvidersReducer = (state = getDepartmentOnlyProvidersInitialState, action) => {
  switch (action.type) {
    case Constants.GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        onlyProviders: action.data.providers,
      };
    case Constants.GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};


const getDepartmentOnlyGeneralCardiologistsInitialState = {
  isLoading: false,
  onlyGeneralCardiologists: [],
  isMfaEnabled: false,
};

export const getDepartmentOnlyGeneralCardiologistsReducer = (state = getDepartmentOnlyGeneralCardiologistsInitialState, action) => {
  switch (action.type) {
    case Constants.GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        onlyGeneralCardiologists: action.data.generalCardiologists,
        isMfaEnabled: action.data.isMfaEnabled,
      };
    case Constants.GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};


// Save Search Filters
const saveSearchFiltersInitState = {
  isSaving: false,
  message: null,
  error: null,
};

export const saveSearchFiltersReducer = (state = saveSearchFiltersInitState, action) => {
  switch (action.type) {
    case Constants.SAVE_SEARCH_FILTERS_SENT:
    case Constants.UPDATE_SEARCH_FILTERS_SENT:
      return {
        ...state,
        isSaving: true,
        message: null,
        error: null,
      };
    case Constants.SAVE_SEARCH_FILTERS_SUCCEED:
      return {
        ...state,
        isSaving: false,
        message: 'Filter saved successfully',
      };
    case Constants.UPDATE_SEARCH_FILTERS_SUCCEED:
      return {
        ...state,
        isSaving: false,
        message: 'Filter updated successfully',
      };
    case Constants.SAVE_SEARCH_FILTERS_FAILED:
    case Constants.UPDATE_SEARCH_FILTERS_FAILED:
      return {
        ...state,
        isSaving: false,
        error: action.data.error,
      };
    default:
      return state;
  }
};


// Save Search Filters
const deleteCustomFiltersInitState = {
  isDeleting: false,
  message: null,
  error: null,
};

export const deleteCustomFiltersReducer = (state = deleteCustomFiltersInitState, action) => {
  switch (action.type) {
    case Constants.DELETE_SEARCH_FILTERS_SENT:
      return {
        ...state,
        isDeleting: true,
        message: null,
        error: null,
      };
    case Constants.DELETE_SEARCH_FILTERS_SUCCEED:
      return {
        ...state,
        isDeleting: false,
        message: 'Filter deleted successfully',
      };
    case Constants.DELETE_SEARCH_FILTERS_FAILED:
      return {
        ...state,
        isDeleting: false,
        error: 'Something went wrong',
      };
    default:
      return state;
  }
};

const initStateAddProcedure = {
  hasError: false,
  isAdded: false,
  isLoading: false,
};

export const addNewProcedureReducer = (state = initStateAddProcedure, action) => {
  switch (action.type) {
    case Constants.ADD_NEW_PROCEDURE_REQUEST_SENT:
      return {
        isLoading: true,
        hasError: false,
        isAdded: false,
      };
    case Constants.ADD_NEW_PROCEDURE_REQUEST_SUCCEED:
      return {
        hasError: false,
        isAdded: true,
        isLoading: false,
      };
    case Constants.ADD_NEW_PROCEDURE_REQUEST_FAILED:
      return {
        hasError: true,
        isAdded: false,
        isLoading: false,
      };
    case Constants.CLEAR_ADD_PROCEDURE_STATES_REQUEST:
      return {
        hasError: false,
        isAdded: false,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return initStateAddProcedure;
    default:
      return state;
  }
};


// Mobile Number Verification
const codeInitialState = {
  isFetchingCode: false,
  verificationCodeFetched: false,
  hasCodeError: false,
};

export const sendVerificationCodeReducer = (state = codeInitialState, action) => {
  switch (action.type) {
    case Constants.GET_VERIFICATION_CODE_REQUEST_SENT:
      return {
        ...state,
        isFetchingCode: true,
        verificationCodeFetched: false,
        hasCodeError: false,
      };
    case Constants.GET_VERIFICATION_CODE_REQUEST_SUCCEED:
      return {
        ...state,
        isFetchingCode: false,
        verificationCodeFetched: true,
      };
    case Constants.GET_VERIFICATION_CODE_REQUEST_FAILED:
      return {
        ...state,
        isFetchingCode: false,
        verificationCodeFetched: false,
        hasCodeError: true,
      };
    case Constants.CLEAR_VERIFICATION_CODE:
      return {
        ...state,
        isFetchingCode: false,
        verificationCodeFetched: false,
        hasCodeError: false,
      };
    default:
      return state;
  }
};


const verifyInitialState = {
  isVerifyingCode: false,
  hasVerifyError: false,
  isVerifiedPhoneNumber: false,
};

export const verifyOTPReducer = (state = verifyInitialState, action) => {
  switch (action.type) {
    case Constants.VERIFY_OTP_REQUEST_SENT:
      return {
        ...state,
        isVerifyingCode: true,
        hasVerifyError: false,
        isVerifiedPhoneNumber: false,
      };
    case Constants.VERIFY_OTP_REQUEST_SUCCEED:
      return {
        ...state,
        isVerifyingCode: false,
        isVerifiedPhoneNumber: true,
      };
    case Constants.VERIFY_OTP_REQUEST_FAILED:
      return {
        ...state,
        isVerifyingCode: false,
        hasVerifyError: true,
      };
    case Constants.CLEAR_VERIFY_OTP:
      return {
        ...state,
        isVerifyingCode: false,
        hasVerifyError: false,
        isVerifiedPhoneNumber: false,
      };
    default:
      return state;
  }
};

const locationInitialState = {
  isLoading: false,
  result: null,
  hasError: false,
  errorMsg: '',
};

export const locationReducer = (state = locationInitialState, action) => {
  switch (action.type) {
    case Constants.GET_LOCATION_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMsg: '',
      };
    case Constants.GET_LOCATION_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        result: action.data,
        errorMsg: '',
      };
    case Constants.GET_LOCATION_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.data && action.data.error ? action.data.error : 'Something went wrong',
      };
    case Constants.CLEAR_DATA:
    case RESET_GLOBAL_REDUCERS:
      return locationInitialState;
    default:
      return state;
  }
};


const allLocationsInitialState = {
  isLoading: false,
  allLocations: [],
  hasError: false,
  errorMsg: '',
};

export const allLocationsReducer = (state = allLocationsInitialState, action) => {
  switch (action.type) {
    case Constants.GET_ALL_LOCATIONS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_ALL_LOCATIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        allLocations: action.data.allLocations,
        errorMsg: '',
      };
    case Constants.GET_ALL_LOCATIONS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.data && action.data.error ? action.data.error : 'Something went wrong',
      };
    case Constants.CLEAR_DATA:
    case RESET_GLOBAL_REDUCERS:
      return allLocationsInitialState;
    default:
      return state;
  }
};

const allProceduresModifiersInitialState = {
  isLoading: false,
  allModifiers: [],
  hasError: false,
  errorMsg: '',
};

export const allProceduresModifiersReducer = (state = allProceduresModifiersInitialState, action) => {
  switch (action.type) {
    case Constants.GET_ALL_PROCEDURES_MODIFIERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_ALL_PROCEDURES_MODIFIERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        allModifiers: action.data.allModifiers,
        errorMsg: '',
      };
    case Constants.GET_ALL_PROCEDURES_MODIFIERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.data && action.data.error ? action.data.error : 'Something went wrong',
      };
    case Constants.CLEAR_DATA:
    case RESET_GLOBAL_REDUCERS:
      return allProceduresModifiersInitialState;
    default:
      return state;
  }
};

const getDepartmentSupportedKeysInitialState = {
  isLoading: false,
  supportedKeys: [],
};

export const getDepartmentSupportedKeysReducer = (state = getDepartmentSupportedKeysInitialState, action) => {
  switch (action.type) {
    case Constants.GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        supportedKeys: action.data.supportedKeys,
      };
    case Constants.GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const validateEmailUniquenessInitialState = {
  isEmailUnique: false,
}

export const validateEmailUniquenessReducer = (state = validateEmailUniquenessInitialState, action) => {
  switch (action.type) {
    case Constants.VALIDATE_EMAIL_UNIQUENESS_REQUEST_SENT:
      return {
        ...state,
      };
    case Constants.VALIDATE_EMAIL_UNIQUENESS_REQUEST_SUCCEED:
      return {
        ...state,
        isEmailUnique: action.data.isEmailUnique,
      };
    case Constants.VALIDATE_EMAIL_UNIQUENESS_REQUEST_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

const validatePhoneUniquenessInitialState = {
  isPhoneUnique: false,
}

export const validatePhoneUniquenessReducer = (state = validatePhoneUniquenessInitialState, action) => {
  switch (action.type) {
    case Constants.VALIDATE_PHONE_UNIQUENESS_REQUEST_SENT:
      return {
        ...state,
      };
    case Constants.VALIDATE_PHONE_UNIQUENESS_REQUEST_SUCCEED:
      return {
        ...state,
        isPhoneUnique: action.data.isPhoneUnique,
      };
    case Constants.VALIDATE_PHONE_UNIQUENESS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      };
      default:
        return state;
    }
  }
const getSearchCategoryListInitialState = {
  isLoading: false,
  categoryList: {},
};

export const getSearchCategoryListReducer = (state = getSearchCategoryListInitialState, action) => {
  switch (action.type) {
    case Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        categoryList: camelcaseKeys.snakeCase(action.data.categoryList),
      };
    case Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
