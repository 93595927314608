import React from 'react';
import MessagesListCard from 'components/MessagesListCard';
import MessageResponse from 'containers/MessageResponse';
import LightboxModal from 'components/LightboxModal';
import Emptyview from 'components/Emptyview';
import NoQuestions from 'assets/images/noquestions.png';
import {getConversationMessages, sendMessage} from 'containers/Messages/actions';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class Messages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attachments: [],
        };
    }

    componentDidMount() {
        this.props.fetchConversationMessages(this.props.conversationId);
    }

    componentDidUpdate() {
        if (this.props.redirectToConversations) {
            this.props.goToConversations();
        }
    }

    sendMessage = (message) => {
        const mediaResources = [...this.state.attachments];
        this.props.sendMessage(this.props.conversationId, message, mediaResources);
    }

    onAttachmentsAdded = (attachments) => {
        this.setState({
            attachments,
        });
    }

    render() {
        return (
            <div>
                <div className="tab-details-title hidden-lg hidden-md">
                    Messages for
                    {this.props.conversationId}
                </div>
                <div className="row" style={{marginBottom: '20px', marginTop: '10px'}}>
                    <div className="col-xs-10">
                        Subject:&nbsp;
                        {this.props.conversation ? this.props.conversation.subject : ''}
                        {this.props.conversation && this.props.conversation.startedWith &&
                        <span>&nbsp;(Started With: {this.props.conversation.startedWith.fullName})</span>
                        }
                    </div>
                </div>
                <MessageResponse
                    onSentMessage={(message) => this.sendMessage(message)}
                    onAttachmentsAdded={this.onAttachmentsAdded}
                />
                <LightboxModal show={this.props.isLoading}/>
                <div style={{marginTop: '50px'}}>
                    {
                        this.props.messages && this.props.messages.length > 0 ?
                            this.props.messages.map((item) => (
                                <MessagesListCard
                                    key={item.id}
                                    item={item}
                                    sent_by={item.sent_by}
                                />
                            )) : <Emptyview imgSrc={NoQuestions} className="" message="No Messages"/>
                    }
                </div>
            </div>
        );
    }
}

Messages.propTypes = {
    isLoading: PropTypes.bool,
    messages: PropTypes.array,
    conversation: PropTypes.object,
    conversationId: PropTypes.number,
    fetchConversationMessages: PropTypes.func,
    sendMessage: PropTypes.func,
    redirectToConversations: PropTypes.bool,
    goToConversations: PropTypes.func,
};

const mapStateToProps = (state) => ({
    isLoading: state.patientDetails.messages.isLoading,
    messages: state.patientDetails.messages.messages,
    conversation: state.patientDetails.messages.conversation,
    redirectToConversations: state.patientDetails.messages.redirect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchConversationMessages: (conversationId) => dispatch(getConversationMessages(conversationId)),
    sendMessage: (conversationId, message, attachments) => dispatch(sendMessage(conversationId, message, attachments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
