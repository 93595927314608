import React, {Component, useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { signOutUser } from 'common/authThunkActions';
import LightBoxModal from 'components/LightboxModal';
import {useHistory, withRouter} from "react-router-dom";

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSignedIn } = useSelector(state => state.currentUser);

  useEffect(() => {
    dispatch(signOutUser());
  }, []);

  useEffect(() => {
    if (!isSignedIn) {
      history.replace("/signin");
    }
  }, [isSignedIn]);

  return (
    <LightBoxModal show />
  );
};

export default Logout;