import axios from './base';
import {
    ADD_NEW_PROVIDER,
    CHANGE_PROVIDER_ACCOUNT_STATUS,
    GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER,
    GET_ALL_USERS_OF_THE_DEPARTMENT,
    UPDATE_PROVIDER_INFO
} from "./constants";

export const fetchAllUsersOfTheDepartment = (params) =>
    axios.get(GET_ALL_USERS_OF_THE_DEPARTMENT, {params});


export const addNewProvider = (params) => axios.post(ADD_NEW_PROVIDER, params);

export const updateProviderInfo = (params) => {
    const url = UPDATE_PROVIDER_INFO.replace(':id', params.id);
    return axios.put(url, params);
};

export const fetchAllPerformersOfUserTypeMaster = () => {
    return axios.get(GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER);
}

// export const deactivateProviderAccount = (params) => {
export const providerAccountStatusChange = (params) => {
    const url = CHANGE_PROVIDER_ACCOUNT_STATUS + "/" + params.id + "/change_account_status";
    return axios.put(url, params)
}
