/*
 *
 * Profile Page reducer
 *
 */
import {
    GET_NOTIFICATIONS_REQUEST_FAILED,
    GET_NOTIFICATIONS_REQUEST_SENT,
    GET_NOTIFICATIONS_REQUEST_SUCCEED,
    GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SUCCEED,
} from './constants';

const initialState = {
    isLoading: false,
    notifications: [],
    unreadCount: 0,
    total: 0,
    page: 1,
    isLast: false,
};

export const notificationsReducer = (state = initialState, action) => {
    let notifications;
    switch (action.type) {
        case GET_NOTIFICATIONS_REQUEST_SENT:
            return {
                ...state,
                isLoading: true,
            };
        case GET_NOTIFICATIONS_REQUEST_SUCCEED:
            if (action.data) {
                notifications = action.data.page === 1 ? action.data.notifications : state.notifications.concat(action.data.notifications);
                return {
                    ...state,
                    isLoading: false,
                    notifications,
                    total: action.data.total,
                    page: action.data.page,
                    unreadCount: action.data.unreadCount,
                    isLast: action.data.isLastResultSet,
                };
            }
            return state;
        case GET_NOTIFICATIONS_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SUCCEED:
            if (action.data) {
                return {
                    ...state,
                    unreadCount: action.data.unreadCount,
                };
            }
            return state;
        default:
            return state;
    }
};
