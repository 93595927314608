import React from "react";
import Stack from "@mui/material/Stack";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import InputTextField from "./../../../components/StyleGuideComponents/InputTextField";
import PropTypes from "prop-types";
import validate from "common/validator";
import { nameViewValidation } from "./../validators";
import { ADDRESS_VIEW } from "./../constants";
import { removeToaster } from "../../../common/toasterActions";
import { validateContactNumberRequest } from "../action";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const NameView = (props) => {
  
  const patientRegister = useSelector((store) => store.register.patientRegisterReducer);
  const toasters = useSelector((store) => store.toasters);
  const dispatch = useDispatch();

  const [ state, setState ] = useState({
    firstName: patientRegister.firstName || "",
    lastName: patientRegister.lastName || "",
    email: patientRegister.email || "",
    phone: patientRegister.phone || "",
    errors: []
  });

  const handleChange = (name, value) => {
    let errors = state.errors
    delete errors[name]
    setState({ ...state,[name]: value, errors:errors });
  };

  const onValidationFailure = (errors) => {
    setState({ ...state,errors: errors });
  };

  const onNextValidationSuccess = () => {
      toasters.map((toast) => dispatch(removeToaster(toast.id)));
      dispatch(validateContactNumberRequest(state, props.nextPage, ADDRESS_VIEW));
  };

  const checkIfButtonEnabled = ()  => {
    return state.firstName && state.firstName.length && state.lastName && state.lastName.length && state.email && state.email.length && state.phone && state.phone.length;
  }

  const handleSubmit =  (e) => {
    e.preventDefault();
      validate(
        nameViewValidation,
        state,
        onValidationFailure,
        onNextValidationSuccess
        );
  };

    return (
      <div style={{paddingBottom:"-6%"}}>

        <form type="post">
          <div>
            <Stack sx={{zIndex:"0"}} spacing={"24px"}>

              <InputTextField
                id="firstName"
                label="First Name"
                name="firstName"
                value={state.firstName}
                onChange={ handleChange }
                required
                error={"firstName" in state.errors}
                errorText={
                  "firstName" in state.errors
                    ? state.errors.firstName
                    : ""
                }
              />

              <InputTextField
                id="lastName"
                label="Last Name"
                name="lastName"
                value={state.lastName}
                onChange={ handleChange }
                required
                error={"lastName" in state.errors}
                errorText={
                  "lastName" in state.errors
                    ? state.errors.lastName
                    : ""
                }
              />

              <InputTextField
                id="email"
                label="Email Address"
                name="email"
                type="email"
                value={state.email}
                onChange={ handleChange }
                required
                error={"email" in state.errors}
                errorText={
                  "email" in state.errors ? state.errors.email : ""
                }
              />

              <InputTextField
                id="phone"
                label="Mobile Number"
                name="phone"
                type="phone"
                value={state.phone}
                onChange={ handleChange }
                required
                error={"phone" in state.errors}
                errorText={
                  "phone" in state.errors ? state.errors.phone : ""
                }
              />

            </Stack>
            <div className="continue-button-for-form">
              <PrimaryButton
              disabled={!checkIfButtonEnabled()}
                fullWidth
                text={props.nextButtonText}
                onClick={handleSubmit}
              />
              </div>
          </div>
        </form>
      </div>
    );
}

export default NameView;

