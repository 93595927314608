/**
 *
 * Lookup
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import HelpBlock from 'components/HelpBlock';
import validate from 'common/validator';
import WatchClickOutside from 'patientApp/components/WatchClickOutside';
import SelectCategory from 'components/SelectCategory';
import './lookup.scss';

const validationConfig = {
    fields: ['lookupValue'],
    rules: {
        lookupValue: [
            {rule: 'isRequired', message: 'Lookup value is required'},
            {rule: 'shouldExcludeChars', chars: ['%', '#','?','/'], message: 'Lookup value cannot have "%, #, ?, /" characters'},
        ],
    },
};

class Lookup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lookupValue: '',
            errors: {},
            category: 'all',
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextState, this.state) || !isEqual(nextProps, this.props);
    }

    onFormValidationSuccess = () => {
        this.setState({errors: {}});
        this.props.history.push(`/lookup/${this.state.lookupValue}/${this.state.category}`);
    }

    handleReset = () => {
        this.setState({errors: {}});
    }

    onFormValidationFailure = (errors) => {
        this.setState({errors});
    }

    onLookupValueChange = (e) => {
        this.setState({
            lookupValue: e.target.value,
            errors: {},
        });
    }

    submitForm = () => {
        validate(validationConfig, this.state, this.onFormValidationFailure, this.onFormValidationSuccess);
    }


    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitForm();
        }
    }

    selectCategory = ({value}) => {
        if (value !== null && value !== '' && value !== undefined) {
            this.setState({
                category: value,
            });
        }
    }

    render() {
        return (
            <div
                className={`navbar-form navbar-left lookup ${this.state.errors.lookupValue !== undefined ? 'has-error' : ''}`}>
                <WatchClickOutside onClickOutside={this.handleReset}>
                    <React.Fragment>

                        <div className="form-group">
                            <SelectCategory
                                selectCategory={this.selectCategory}
                                category={this.state.category}
                            />
                            <input type="text" className="form-control" value={this.state.lookupValue}
                                   onChange={this.onLookupValueChange} placeholder="Lookup Patients by ID, MRN or Name"
                                   onKeyPress={this.handleKeyPress} autoComplete="new-password"/>
                            <HelpBlock value={this.state.errors.lookupValue}/>
                        </div>
                        <button className="btn btn-primary" onClick={this.submitForm}>Lookup</button>
                    </React.Fragment>
                </WatchClickOutside>
            </div>
        );
    }
}

Lookup.propTypes = {
    history: PropTypes.object,
};

export default Lookup;
