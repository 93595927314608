import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {combineReducers, compose} from 'redux';
import injectReducer from 'utils/injectReducer';

import AddPatientView from 'components/AddPatientView';
import LightboxModal from 'components/LightboxModal';
import AvatarImage from 'assets/images/securestartlogo.png';

import {getDepartmentProceduresReducer, getDepartmentProvidersReducer} from 'common/reducer';
import {getDepartmentProceduresRequest, getDepartmentProvidersRequest} from 'common/actions';
import {redirectToLogout,} from 'common/authGuard';
import {onSaveNewPatientRequest,} from '../Dashboard/actions';

import '../Dashboard/dashboard.scss';
import {addNewPatientReducer} from '../Dashboard/reducer';

class UnSupportedPageView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            checkFromProps: false,
            isRequestInProgress: false,
        };
    }

    componentWillMount() {
        this.props.getDepartmentProceduresRequest();
        this.props.getDepartmentProvidersRequest();
    }

    componentWillReceiveProps = (nextProps) => {
        const state = {
            procedures: nextProps.procedures,
            isRequestInProgress: nextProps.isRequestInProgress,
        };

        if (this.state.checkFromProps) {
            state.show = nextProps.show;
        }
        this.setState(state);
    }


    onModalClose = () => {
        this.setState({show: false});
        this.checkFromPropsCall(false);
    }

    checkFromPropsCall = (value) => {
        this.setState({
            checkFromProps: value,
        });
    }

    signOut = () => {
        redirectToLogout();
    }

    render() {
        return (
            <div className="app-smaller-container">
                <div className="image-container">
                    <img
                        src={AvatarImage}
                        alt=""
                    />
                </div>
                <div className="info-container">To access the full feature, please Sign in from a tablet or desktop.
                </div>

                <div className="log-out-btn-container">
                    <div className="btn-separator"></div>
                    <button className="btn btn-default" onClick={this.signOut}>Log out</button>
                </div>

                <LightboxModal show={this.state.isRequestInProgress || this.props.isDeletingCustomFilter}/>
                {this.state.show && (
                    <AddPatientView
                        show={this.state.show}
                        onSaveNewPatientRequest={this.props.onSaveNewPatientRequest}
                        procedures={this.props.procedures}
                        checkFromPropsCall={this.checkFromPropsCall}
                        onModalClose={this.onModalClose}
                        hasSuperUserPrivileges={this.props.hasSuperUserPrivileges}
                        patientAdvocates={this.props.patientAdvocates}
                        isSupportUser={this.props.isSupportUser}
                    />
                )}
            </div>
        );
    }
}

UnSupportedPageView.propTypes = {
    getDepartmentProvidersRequest: PropTypes.func,
    onSaveNewPatientRequest: PropTypes.func,
    hasSuperUserPrivileges: PropTypes.bool,
    show: PropTypes.bool,
    procedures: PropTypes.array,
    getDepartmentProceduresRequest: PropTypes.func,
    isRequestInProgress: PropTypes.bool,
    patientAdvocates: PropTypes.array,
    isSupportUser: PropTypes.any,
    isDeletingCustomFilter: PropTypes.any,
};

const mapStateToProps = (state) => ({
    show: state.unSupportedPageView.addNewPatientReducer.show,
    isRequestInProgress: state.unSupportedPageView.addNewPatientReducer.isRequestInProgress,
    procedures: state.unSupportedPageView.getDepartmentProceduresReducer.procedures,
    toasts: state.unSupportedPageView.toasts,
    userType: state.currentUser.attributes.type,
    hasSuperUserPrivileges: state.currentUser.attributes.hasSuperUserPrivileges,
    isSupportUser: state.currentUser.attributes.isSupportUser,
    patientAdvocates: state.unSupportedPageView.getDepartmentProvidersReducer.providers,
});

const mapDispatchToProps = (dispatch) => ({
    onSaveNewPatientRequest: (params) => dispatch(onSaveNewPatientRequest(params)),
    getDepartmentProceduresRequest: () => dispatch(getDepartmentProceduresRequest()),
    getDepartmentProvidersRequest: () => dispatch(getDepartmentProvidersRequest()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
    key: 'unSupportedPageView',
    reducer: combineReducers({
        addNewPatientReducer, getDepartmentProceduresReducer, getDepartmentProvidersReducer,
    }),
});

export default compose(
    withReducer,
    withConnect,
)(UnSupportedPageView);
