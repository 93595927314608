/*
 *
 * ForgotPassword constants
 *
 */

export const GET_FORGOT_PASSWORD_REQUEST_SENT = 'app/User/GET_FORGOT_PASSWORD_REQUEST_SENT';
export const GET_FORGOT_PASSWORD_REQUEST_SUCCEED = 'app/User/GET_FORGOT_PASSWORD_REQUEST_SUCCEED';
export const GET_FORGOT_PASSWORD_REQUEST_FAILED = 'app/User/GET_FORGOT_PASSWORD_REQUEST_FAILED';
export const ALTER_FORGOT_PASSWORD_REQUEST_SENT_FLAG = 'app/User/ALTER_FORGOT_PASSWORD_REQUEST_SENT_FLAG';
