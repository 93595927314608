import React from 'react';
import {components} from 'react-select';

const Menu = ({children, ...props}) => (
    <components.Menu {...props}>
        {children}
        <footer>
            <button
                className="btn btn-plain-text hidden-xs hidden-sm hidden-md"
                onClick={() => handleClearButton(props)}
                onTouchEnd={() => handleClearButton(props)}
                onMouseDown={() => handleClearButton(props)}
            >
                Clear
            </button>

            <button
                className="btn btn-primary"
                onClick={() => handleApplyButton(props.selectProps)}
                onTouchEnd={() => handleApplyButton(props.selectProps)}
                onMouseDown={() => handleApplyButton(props.selectProps)}
            >
                Apply
            </button>
        </footer>
    </components.Menu>
);

const handleApplyButton = (selectedProps) => {
    selectedProps.onApply(selectedProps.value, selectedProps.facet);
};

const handleClearButton = (props) => {
    props.clearValue();
    if (props.selectProps.onClearAndApply) {
        props.selectProps.onClearAndApply();
    }
};

export default Menu;
