import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DefaultProcedureIcon from '../../../assets/images/defaultsingleprocedure.png';

import './card.scss';

class SelectProcedureCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSelectedIcon: false,
    };
  }

  onSelectProcedure = (event, compositeProcedureId) => {
    this.setState({
      showSelectedIcon: true,
    }, () => setTimeout(() => this.props.onProcedureSelect(compositeProcedureId), 500));
  }

  render() {
    const { item } = this.props;
    return (
      <div role="presentation" className="row procedure-card cursor-pointer" onClick={(e) => { this.onSelectProcedure(e, item.id); }}>
        <div className="col-sm-2 col-xs-3 icon-container align-center">
          <img src={DefaultProcedureIcon} className="" alt="procedure" />
        </div>
        <div className="col-sm-9 col-xs-8 no-padding-left content-container">
          <div className="col-sm-12 procedure-name">{item.name}</div>
          <div className="col-sm-12 procedure-date">{item.compositeProcedureDateInString}</div>
        </div>
        {
          this.state.showSelectedIcon && (
            <div className="col-sm-1 col-xs-1 no-padding-left tick-container">
              <span className="icon icon-font-a-tick float-right"></span>
            </div>
          )
        }
      </div>
    );
  }
}

SelectProcedureCard.propTypes = {
  item: PropTypes.object.isRequired,
  onProcedureSelect: PropTypes.func.isRequired,
};

export default SelectProcedureCard;
