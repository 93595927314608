import {showToaster} from 'common/toasterActions';
import {
    addNewProvider,
    fetchAllPerformersOfUserTypeMaster,
    fetchAllUsersOfTheDepartment,
    providerAccountStatusChange,
    updateProviderInfo
} from "services/userAdministration";

import {
    ADD_NEW_PROVIDER_REQUEST_FAILED,
    ADD_NEW_PROVIDER_REQUEST_SENT,
    ADD_NEW_PROVIDER_REQUEST_SUCCEED,
    GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_FAILED,
    GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SENT,
    GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SUCCEED,
    GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_FAILED,
    GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SENT,
    GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SUCCEED,
    PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_FAILED,
    PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_SENT,
    PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_SUCCEED,
    UPDATE_PROVIDER_INFO_REQUEST_FAILED,
    UPDATE_PROVIDER_INFO_REQUEST_SENT,
    UPDATE_PROVIDER_INFO_REQUEST_SUCCEED
} from "./constants";


export const PROVIDER_ACCOUNT_STATUS_CHANGE_SENT =
    "PROVIDER_ACCOUNT_STATUS_CHANGE_SENT"
export const PROVIDER_ACCOUNT_STATUS_CHANGE_SUCCEED =
    "PROVIDER_ACCOUNT_STATUS_CHANGE_SUCCEED"
export const PROVIDER_ACCOUNT_STATUS_CHANGE_FAILED =
    "PROVIDER_ACCOUNT_STATUS_CHANGE_FAILED"

export const fetchAllUsersOfTheDepartmentRequestSent = () => ({
    type: GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SENT
});

export const fetchAllUsersOfTheDepartmentSucceeded = data => ({
    type: GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SUCCEED,
    data
});

export const fetchAllUsersOfTheDepartmentRequestFailed = data => ({
    type: GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_FAILED,
    data
});

export const addNewProviderRequestSent = () => ({
    type: ADD_NEW_PROVIDER_REQUEST_SENT,
});

export const addNewProviderSucceeded = () => ({
    type: ADD_NEW_PROVIDER_REQUEST_SUCCEED,
});

export const addNewProviderRequestFailed = () => ({
    type: ADD_NEW_PROVIDER_REQUEST_FAILED,
});

export const updateProviderInfoRequestSent = () => ({
    type: UPDATE_PROVIDER_INFO_REQUEST_SENT
});

export const updateProviderInfoSucceeded = () => ({
    type: UPDATE_PROVIDER_INFO_REQUEST_SUCCEED
});

export const updateProviderInfoRequestFailed = () => ({
    type: UPDATE_PROVIDER_INFO_REQUEST_FAILED
});

export const fetchAllPerformersOfUserTypeMasterRequestSent = () => ({
    type: GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SENT,
});

export const fetchAllPerformersOfUserTypeMasterSucceeded = (data) => ({
    type: GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SUCCEED,
    data,
});

export const fetchAllPerformersOfUserTypeMasterRequestFailed = (data) => ({
    type: GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_FAILED,
    data,
});

export const providerAccountStatusIsActiveChangeSent = () => ({
    type: PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_SENT,
});

export const providerAccountStatusIsActiveChangeSucceed = () => ({
    type: PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_SUCCEED,
});

export const providerAccountStatusIsActiveChangeFailed = () => ({
    type: PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_FAILED,
});


export const onfetchAllUsersOfTheDepartmentRequest = (params) => (dispatch) => {
    dispatch(fetchAllUsersOfTheDepartmentRequestSent());
    fetchAllUsersOfTheDepartment(params)
        .then((response) => {
            dispatch(fetchAllUsersOfTheDepartmentSucceeded(response.data));
        })
        .catch((error) => {
            dispatch(fetchAllUsersOfTheDepartmentRequestFailed(error.response.data));
        });
};

export const onAddNewProviderRequest = (params) => (dispatch) => {
    dispatch(addNewProviderRequestSent());
    addNewProvider(params)
        .then(() => {
            dispatch(addNewProviderSucceeded());
            dispatch(showToaster({message: "Performer added successfully."}));
            dispatch(onfetchAllUsersOfTheDepartmentRequest(params.pagination));
            dispatch(onfetchPerformersOfUserTypeMasterRequest());
        })
        .catch(() => {
            dispatch(addNewProviderRequestFailed());
        });
};

export const updateProviderInfoAction = (params) => (dispatch) => {
    dispatch(updateProviderInfoRequestSent());
    updateProviderInfo(params)
        .then(() => {
            dispatch(updateProviderInfoSucceeded());
            dispatch(showToaster({message: 'Performer details updated successfully.'}));
            dispatch(onfetchAllUsersOfTheDepartmentRequest(params.pagination));
        })
        .catch(() => {
            dispatch(updateProviderInfoRequestFailed());
        });
};

export const onfetchPerformersOfUserTypeMasterRequest = () => (dispatch) => {
    dispatch(fetchAllPerformersOfUserTypeMasterRequestSent());
    fetchAllPerformersOfUserTypeMaster()
        .then((response) => {
            dispatch(fetchAllPerformersOfUserTypeMasterSucceeded(response.data));
        })
        .catch((error) => {
            dispatch(fetchAllPerformersOfUserTypeMasterRequestFailed(error.response.data));
        });
};

export const providerAccountStatusChangeAction = (params) => (dispatch) => {
    dispatch(providerAccountStatusIsActiveChangeSent());
    providerAccountStatusChange(params)
        .then((response) => {
            dispatch(providerAccountStatusIsActiveChangeSucceed());
            dispatch(showToaster({message: response.data.message}));
            dispatch(onfetchAllUsersOfTheDepartmentRequest(params.page));
            if (params.should_refetch_performers) {
                dispatch(onfetchPerformersOfUserTypeMasterRequest());
            }
        })
        .catch((error) => {
            dispatch(providerAccountStatusIsActiveChangeFailed());
        });
};
