import * as Constants from './authConstants';
import {RESET_GLOBAL_REDUCERS} from '../constants';

const initialState = {
    isSignedIn: false,
    isLoading: false,
    otpSent: false,
    hasVerificationBeenAttempted: false,
    attributes: {},
    isDeleteAccountTriggered: false,
    termsAndConditionsUrl: '',
    twoFactorAuthentication: false,
    shouldShowPatientLoginModal: false,
};

const currentUser = (state = initialState, action) => {
    let userAttributeKeys = null;
    let allNullUserAttributes = null;

    switch (action.type) {
        case Constants.REGISTRATION_REQUEST_SENT:
        case Constants.CURRENT_USER_INFO_REQUEST_SENT:
        case Constants.GENERATE_OTP_REQUEST_SENT:
        case Constants.SIGNIN_REQUEST_SENT:
        case Constants.SIGNOUT_REQUEST_SENT:
        case Constants.GET_TERMS_AND_CONDITION_URL_REQUEST_SENT:
            return {
                ...state,
                isLoading: true,
                shouldShowPatientLoginModal: false,
            };
        case Constants.CURRENT_USER_INFO_REQUEST_SUCCEEDED:
            return {
                ...state,
                attributes: {...action.payload.userAttributes},
                isLoading: false,
                isSignedIn: true,
                hasVerificationBeenAttempted: true,
            };
        case Constants.GENERATE_OTP_REQUEST_SUCCEEDED:
            return {
                ...state,
                isLoading: false,
                otpSent: action.payload.data.otpSent,
            };
        case Constants.REGISTRATION_REQUEST_SUCCEEDED:
        case Constants.SIGNIN_REQUEST_SUCCEEDED:
            return {
                ...state,
                attributes: {...action.payload.userAttributes},
                isLoading: false,
                isSignedIn: true,
            };

        case Constants.UPDATE_USER_ATTRIBUTE:
            return {
                ...state,
                attributes: {
                    ...state.attributes,
                    ...action.payload,
                },
            };
        case Constants.CURRENT_USER_INFO_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                isSignedIn: false,
                hasVerificationBeenAttempted: true,

            };
        case Constants.GENERATE_OTP_REQUEST_FAILED:
        case Constants.CLOSE_OTP_MODAL_REQUEST_SUCCEEDED:
            return {
                ...state,
                isLoading: false,
                isSignedIn: false,
                otpSent: false,
            };
        case Constants.PATIENT_LOGIN_PREVENTED:
            return {
                ...state,
                shouldShowPatientLoginModal: true,
            };
        case Constants.RESET_PATIENT_LOGIN_PREVENTED:
            return {
                ...state,
                shouldShowPatientLoginModal: false,
            };
        case Constants.REGISTRATION_REQUEST_FAILED:
        case Constants.SIGNIN_REQUEST_FAILED:
        case Constants.GET_TERMS_AND_CONDITION_URL_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                isSignedIn: false,
            };
        case Constants.SIGNOUT_REQUEST_SUCCEEDED:
            userAttributeKeys = Object.keys(state.attributes);
            allNullUserAttributes = userAttributeKeys.reduce(
                (accumulatedNullUserAttributes, currentUserAttributeKey) => ({
                    ...accumulatedNullUserAttributes,
                    [currentUserAttributeKey]: null,
                }),
                {},
            );
            return {
                ...state,
                attributes: allNullUserAttributes,
                isLoading: false,
                isSignedIn: false,
            };
        case Constants.SIGNOUT_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.SET_HAS_VERIFICATION_BEEN_ATTEMPTED:
            return {
                ...state,
                hasVerificationBeenAttempted: action.payload.hasVerificationBeenAttempted,
            };
        case Constants.DELETE_ACCOUNT_REQUEST_SENT:
            return {
                ...state,
                isDeleteAccountTriggered: true,
            };
        case Constants.DELETE_ACCOUNT_REQUEST_SUCCEED:
        case Constants.DELETE_ACCOUNT_REQUEST_FAILED:
            return {
                ...state,
                isDeleteAccountTriggered: false,
            };
        case Constants.GET_TERMS_AND_CONDITION_URL_REQUEST_SUCCEEDED:
            return {
                ...state,
                isLoading: false,
                termsAndConditionsUrl: action.payload.data.url,
                twoFactorAuthentication: action.payload.data.twoFactorAuthentication,
            };
        case RESET_GLOBAL_REDUCERS:
            return initialState;
        default:
            return state;
    }
};

export default currentUser;


const initialInfoState = {
    isRequestInProgress: false,
    isRequestFailed: false,
    user: {},
    userDetails: {},
    error: '',
    message: '',
    editEmailMessage: '',
    isChangingEmail: false,
    twoFactorAuthentication: false
};

export const userInfoReducer = (state = initialInfoState, action) => {
    switch (action.type) {
        case Constants.GET_USER_INFO_REQUEST_FAILED:
            return {
                ...state,
                isRequestInProgress: false,
                isRequestFailed: true,
                error: '',
                message: '',
            };
        case Constants.GET_USER_INFO_REQUEST_SENT:
            return {
                ...state,
                isRequestInProgress: true,
                isRequestFailed: false,
                error: '',
                message: '',
            };
        case Constants.GET_USER_INFO_REQUEST_SUCCEED:
            return {
                ...state,
                isRequestInProgress: false,
                isRequestFailed: false,
                user: action.data.info,
                userDetails: action.data,
                twoFactorAuthentication: action.data.twoFactorAuthentication,
                error: '',
                message: '',
            };
        case Constants.UPDATE_USER_INFO_REQUEST_FAILED:
        case Constants.UPDATE_USER_PICTURE_REQUEST_FAILED:
            return {
                ...state,
                isRequestInProgress: false,
                message: '',
                error: 'Something went wrong, please try again.',
            };
        case Constants.UPDATE_USER_INFO_REQUEST_SENT:
        case Constants.UPDATE_USER_PICTURE_REQUEST_SENT:
            return {
                ...state,
                isRequestInProgress: true,
                error: '',
                message: '',
            };
        case Constants.UPDATE_USER_INFO_REQUEST_SUCCEED:
            return {
                ...state,
                error: '',
                user: action.data.info,
                userDetails: action.data,
                isRequestInProgress: false,
                message: 'Profile updated successfully.',
            };
        case Constants.UPDATE_USER_PICTURE_REQUEST_SUCCEED:
            return {
                ...state,
                error: '',
                isRequestInProgress: false,
                message: 'Profile picture updated successfully.',
            };
        case Constants.UPDATE_USER_EMAIL_REQUEST_SENT:
            return {
                ...state,
                isChangingEmail: true,
                editEmailMessage: '',
            };
        case Constants.UPDATE_USER_EMAIL_REQUEST_SUCCEED:
            return {
                ...state,
                isChangingEmail: false,
                editEmailMessage: action.data.message,
            };
        case Constants.UPDATE_USER_EMAIL_REQUEST_FAILED:
            return {
                ...state,
                isChangingEmail: false,
                editEmailMessage: '',
            };

        default:
            return state;
    }
};


const initialNoticeState = {
    isLoading: false,
    privacyNoticeUrl: '',
};

export const privacyNoticeReducer = (state = initialNoticeState, action) => {
    switch (action.type) {
        case Constants.GET_PRIVACY_NOTICE_URL_REQUEST_SENT:
            return {
                ...state,
                isLoading: true,
            };
        case Constants.GET_PRIVACY_NOTICE_URL_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case Constants.GET_PRIVACY_NOTICE_URL_REQUEST_SUCCEEDED:
            return {
                ...state,
                isLoading: false,
                privacyNoticeUrl: action.payload.data.url,
            };
        default:
            return state;
    }
};
