import axios from './base';
import {GET_PATIENT_PROCEDURES, SWITCH_ACTIVE_PROCEDURE, VERIFY_INVITE_CODE} from './constants';

export const switchActiveProcedure = (userProcedureId) => axios.put(
    SWITCH_ACTIVE_PROCEDURE,
    {
        composite_procedure_id: userProcedureId,
    },
);

export const getPatientProceduresService = () => axios.get(GET_PATIENT_PROCEDURES);

export const verifyInviteCode = (inviteCode) => {
    const params = {
        invite_code: inviteCode,
    };
    return axios.get(VERIFY_INVITE_CODE, {params});
};
