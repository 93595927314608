import React, {PureComponent} from 'react';
import {removeItemFromStorage} from 'services/storage';
import PropTypes from 'prop-types';
import PreLoginFooter from 'components/PreLoginFooter';
import Modal from 'react-bootstrap/lib/Modal';
import './style.scss';
import Strings from '../../strings';

class RegistrationSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: this.props.location.state ? this.props.location.state.isMobile : true,
            isEmail: this.props.location.state ? this.props.location.state.isEmail : false,
            showContactviewModal: false,
        };
    }

    componentDidMount() {
        removeItemFromStorage('inviteCode');
    }

    onContactUsClick = () => {
        this.setState({
            showContactviewModal: true,
        });
    }

    hideContactviewModal = () => {
        this.setState({
            showContactviewModal: false,
        });
    }

    renderMobileLabel = () => (
        <div className="phone-verified-label">
            <i className="icon icon-font-a-contact"></i>
            <span>Your account is created successfully. Sign in with your phone number to continue.</span>
        </div>
    )

    renderEmailLabel = () => (
        <div className="phone-verified-label">
            <i className="icon icon-font-a-email"></i>
            <span>We have sent you a confirmation email. Please confirm your email before signing in.</span>
        </div>
    )

    render() {
        const {isMobile, isEmail} = this.state;
        return (
            <div className="registration-landing-page-container">
                <div className="col-md-6 hidden-sm hidden-xs image-and-process-container no-padding">
                    <div className="background-image-container">
                        <div className="col-xs-6 header-content float-right">
                            Knowing
                            <br/>
                            what&apos;s ahead.
                            <br/>
                            That&apos;s powerful.
                        </div>
                    </div>
                    <div className="col-xs-12 portal-container">
                        <div className="col-xs-12 patient-learn-more-container">
                            <i className="icon icon-font-a-profile"></i>
                            <div className="col-xs-offset-1 col-sm-11">
                                <span>As a patient or caregiver, Secure Start℠ gives you visibility and tools to navigate the process that lies ahead.</span>
                                <span className="link cursor-pointer" onClick={this.showPatientVideo}>Patients learn more</span>
                            </div>
                        </div>
                        <div className="col-xs-12 provider-learn-more-container">
                            <i className="icon icon-font-a-stethoscope"></i>
                            <div className="col-xs-offset-1 col-sm-11">
                                <span>As a provider, Secure Start℠ gives you visibility and tools to navigate which patients are on-and-off-track, so you can intervene to help them achieve healthy outcomes.</span>
                                <span className="link cursor-pointer" onClick={this.showProviderVideo}>Providers learn more</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 hidden-sm hidden-xs no-padding success-parent-container">
                    <div className="success-page-logo-container"></div>
                    <div className="success-container">
                        <div className="success-icon"><i className="icon icon-font-a-tick-unfilled"></i></div>
                        <div className="header-font">Congratulations!</div>
                        {
                            (isMobile && isEmail) ? (
                                <React.Fragment>
                                    <div className="finalize-setup-label">You are almost done</div>
                                    <div className="finalize-setup-label">To finalize your account setup, please do one
                                        of the following:
                                    </div>
                                    {
                                        this.renderMobileLabel()
                                    }
                                    <div style={{padding: '5px'}}>OR</div>
                                    {
                                        this.renderEmailLabel()
                                    }
                                </React.Fragment>
                            ) : (isMobile && !isEmail) ? this.renderMobileLabel() : (!isMobile && isEmail) ? this.renderEmailLabel() :
                                <div className="finalize-setup-label">Please signin to continue</div>
                        }

                        <button className="btn btn-primary" onClick={() => {
                            this.props.history.replace('/signin');
                        }}>Sign in
                        </button>
                    </div>

                    <PreLoginFooter/>
                </div>

                <div className="visible-sm visible-xs col-xs-12 mobile-view-container no-padding">
                    <div className="success-page-logo-container"></div>
                    <div className="success-container">
                        <div className="success-icon"><i className="icon icon-font-a-tick-unfilled"></i></div>
                        <div className="header-font">Congratulations!</div>
                        {
                            (isMobile && isEmail) ? (
                                <React.Fragment>
                                    <div className="finalize-setup-label">You are almost done</div>
                                    <div className="finalize-setup-label">To finalize your account setup, please do one
                                        of the following:
                                    </div>
                                    {
                                        this.renderMobileLabel()
                                    }
                                    <div style={{padding: '5px'}}>OR</div>
                                    {
                                        this.renderEmailLabel()
                                    }
                                </React.Fragment>
                            ) : (isMobile && !isEmail) ? this.renderMobileLabel() : (!isMobile && isEmail) ? this.renderEmailLabel() :
                                <div className="finalize-setup-label">Please signin to continue</div>
                        }

                        <button className="btn btn-primary" onClick={() => {
                            this.props.history.replace('/signin');
                        }}>Sign in
                        </button>
                    </div>
                    <div className="col-xs-12 no-padding mobile-footer">
                        <div className="contact-us cursor-pointer" onClick={this.onContactUsClick}>Contact us</div>
                        <div className="copy-right-container">
                            <div className="copy-right-label">&copy; Higgs Boson Inc, All Rights Reserved</div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.showContactviewModal}
                    onHide={this.hideContactviewModal}
                    container={document.body}
                    aria-labelledby="contained-modal-title"
                    className="primary-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">Contact Us</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="footer-content-container">
                            <div className="text">Feel free to contact us via email or phone number.</div>
                            <div className="text">We would be happy to address your queries.</div>
                            <div className="phone-container">
                                <i className="icon icon-font-a-contact"></i>
                                <span className="phone">1-866-704-4447</span>
                            </div>
                            <div className="email-container">
                                <i className="icon icon-font-a-email"></i>
                                <span className="email">{Strings.SUPPORT_EMAIL}</span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

RegistrationSuccess.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default RegistrationSuccess;
