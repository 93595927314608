import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import NavigationBar from '../../containers/NavigationBar';
import './header.scss';


class Header extends React.PureComponent {
    render() {
        const {history} = this.props;


        switch (history.location.pathname) {
            case '/not-found':
                return null;
            case '/logout':
                return null;
            case '/unauthorized-access':
                return null;
            case '/patient-signup':
                return null;
            default:
                return (
                    <div>
                        <div className="header-container">
                            <NavigationBar/>
                        </div>
                    </div>
                );
        }
    }
}

Header.propTypes = {
    history: PropTypes.object,
};

export default withRouter(Header);
