/*
 *
 * PatientDetailsPage actions
 *
 */
import { showToaster } from "common/toasterActions";
import {
  GET_PATIENT_DETAILS_REQUEST_SENT,
  GET_PATIENT_DETAILS_REQUEST_SUCCEED,
  GET_PATIENT_DETAILS_REQUEST_FAILED,
  REMOVE_PATIENT_REQUEST_SENT,
  REMOVE_PATIENT_REQUEST_SUCCEED,
  REMOVE_PATIENT_REQUEST_FAILED,
  UPDATE_NEEDS_ATTENTION_REQUEST_SENT,
  UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED,
  UPDATE_NEEDS_ATTENTION_REQUEST_FAILED,
  RESET_NEEDS_ATTENTION,
  SET_PHASE_ID,
  RESEND_INVITE_REQUEST_SENT,
  RESEND_INVITE_REQUEST_SUCCEED,
  RESEND_INVITE_REQUEST_FAILED,
  UPDATE_PATIENT_INFO_REQUEST_SENT,
  UPDATE_PATIENT_INFO_REQUEST_SUCCEED,
  UPDATE_PATIENT_INFO_REQUEST_FAILED,
  RESET_PATIENT_DETAILS,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED,
  TRACK_PATIENT_AS_VIEWED_REQUEST_SENT,
  TRACK_PATIENT_AS_VIEWED_REQUEST_SUCCEED,
  TRACK_PATIENT_AS_VIEWED_REQUEST_FAILED,
  UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_SENT,
  UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_SUCCEEDED,
  UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_FAILED,
  UPDATE_USER_PROCEDURE_LOCATION_REQUEST_SENT,
  UPDATE_USER_PROCEDURE_LOCATION_REQUEST_SUCCEEDED,
  UPDATE_USER_PROCEDURE_LOCATION_REQUEST_FAILED,
  DELETE_USER_REQUEST_SENT,
  DELETE_USER_REQUEST_SUCCEED,
  DELETE_USER_REQUEST_FAILED,
  REVOKE_USER_DELETE_REQUEST_SENT,
  REVOKE_USER_DELETE_REQUEST_SUCCEED,
  REVOKE_USER_DELETE_REQUEST_FAILED
} from "./constants";

import { getPatientAppointments } from "../Appointments/action";

import {
  getPatientDetails,
  removePatient,
  updateNeedsAttention,
  resendInvite,
  updatePatientInfo,
  updateNotMovingForward,
  getPatientNotMovingForwardReasons,
  trackPatientAsViewed,
  updateSalesforceCheckbox,
  updateUserProcedureLocation,
  deleteUser,
  revokeUser
} from "../../services/patientDetails";

export const getPatientDetailsRequestSent = () => ({
  type: GET_PATIENT_DETAILS_REQUEST_SENT,
});

export const getPatientDetailsRequestSucceeded = (
  patientInfo,
  userProcedureId
) => ({
  type: GET_PATIENT_DETAILS_REQUEST_SUCCEED,
  payload: {
    patientInfo,
    userProcedureId,
  },
});

export const getPatientDetailsRequestFailed = () => ({
  type: GET_PATIENT_DETAILS_REQUEST_FAILED,
});

export const trackPatientAsViewedRequestSent = () => ({
  type: TRACK_PATIENT_AS_VIEWED_REQUEST_SENT,
});

export const trackPatientAsViewedRequestSucceeded = () => ({
  type: TRACK_PATIENT_AS_VIEWED_REQUEST_SUCCEED,
});

export const trackPatientAsViewedRequestFailed = () => ({
  type: TRACK_PATIENT_AS_VIEWED_REQUEST_FAILED,
});

export const trackPatientAsViewedRequest =
  (id, userProcedureId) => (dispatch) => {
    dispatch(trackPatientAsViewedRequestSent());
    trackPatientAsViewed(id, userProcedureId)
      .then((response) => {
        dispatch(
          trackPatientAsViewedRequestSucceeded(response.data, userProcedureId)
        );
      })
      .catch(() => {
        dispatch(trackPatientAsViewedRequestFailed());
      });
  };

export const setPhaseId = (phaseId) => ({
  type: SET_PHASE_ID,
  phaseId,
});

export const resetNeedsAttention = () => ({
  type: RESET_NEEDS_ATTENTION,
});

export const getPatientInfo = (id, userProcedureId) => (dispatch) => {
  dispatch(getPatientDetailsRequestSent());
  getPatientDetails(id, userProcedureId)
    .then((response) => {
      dispatch(
        getPatientDetailsRequestSucceeded(response.data, userProcedureId)
      );
      dispatch(resetNeedsAttention());
    })
    .catch(() => {
      dispatch(getPatientDetailsRequestFailed());
    });
};

export const removePatientRequestSent = () => ({
  type: REMOVE_PATIENT_REQUEST_SENT,
});

export const removePatientRequestSucceeded = () => ({
  type: REMOVE_PATIENT_REQUEST_SUCCEED,
});

export const removePatientRequestFailed = () => ({
  type: REMOVE_PATIENT_REQUEST_FAILED,
});

export const removePatientAction = (params) => (dispatch) => {
  dispatch(removePatientRequestSent());
  removePatient(params)
    .then(() => {
      dispatch(removePatientRequestSucceeded());
      dispatch(showToaster({ message: "Procedure removed successfully." }));
    })
    .catch(() => {
      dispatch(removePatientRequestFailed());
    });
};

export const updateNeedsAttentionRequestSent = () => ({
  type: UPDATE_NEEDS_ATTENTION_REQUEST_SENT,
});

export const updateNeedsAttentionRequestSucceeded = (response) => ({
  type: UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED,
  response,
});

export const updateNeedsAttentionRequestFailed = (response) => ({
  type: UPDATE_NEEDS_ATTENTION_REQUEST_FAILED,
  response,
});

export const updateNeedsAttentionAction = (params) => (dispatch) => {
  dispatch(updateNeedsAttentionRequestSent());
  updateNeedsAttention(params)
    .then((response) => {
      dispatch(updateNeedsAttentionRequestSucceeded(response));
    })
    .catch((error) => {
      dispatch(updateNeedsAttentionRequestFailed(error.response));
      dispatch(
        showToaster({ message: error.response.data.message, type: "error" })
      );
    });
};

export const getNotMovingForwardReasonsRequestSent = () => ({
  type: GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT,
});

export const getNotMovingForwardReasonsRequestSucceeded = (response) => ({
  type: GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED,
  response,
});

export const getNotMovingForwardReasonsRequestFailed = () => ({
  type: GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED,
});

export const getNotMovingForwardReasons = (params) => (dispatch) => {
  dispatch(getNotMovingForwardReasonsRequestSent());
  getPatientNotMovingForwardReasons(params)
    .then((response) => {
      dispatch(getNotMovingForwardReasonsRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getNotMovingForwardReasonsRequestFailed());
    });
};

export const updateNotMovingForwardRequestSent = () => ({
  type: UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT,
});

export const updateNotMovingForwardRequestSucceeded = (response) => ({
  type: UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED,
  response,
});

export const updateNotMovingForwardRequestFailed = (response) => ({
  type: UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED,
  response,
});

export const updateNotMovingForwardAction = (params) => (dispatch) => {
  dispatch(updateNotMovingForwardRequestSent());
  updateNotMovingForward(params)
    .then((response) => {
      dispatch(updateNotMovingForwardRequestSucceeded(response));
    })
    .catch((error) => {
      dispatch(updateNotMovingForwardRequestFailed(error.response));
      dispatch(
        showToaster({ message: error.response.data.message, type: "error" })
      );
    });
};

export const resetPatientDetails = () => ({
  type: RESET_PATIENT_DETAILS,
});

export const resendInviteRequestSent = () => ({
  type: RESEND_INVITE_REQUEST_SENT,
});

export const resendInviteRequestSucceeded = (response) => ({
  type: RESEND_INVITE_REQUEST_SUCCEED,
  response,
});

export const resendInviteRequestFailed = (response) => ({
  type: RESEND_INVITE_REQUEST_FAILED,
  response,
});

export const resendInviteAction = (params) => (dispatch) => {
  dispatch(resendInviteRequestSent());
  resendInvite(params)
    .then((response) => {
      dispatch(resendInviteRequestSucceeded(response));
      dispatch(showToaster({ message: response.data.message }));
      dispatch(getPatientInfo(params.id, params.user_procedure_id));
    })
    .catch((error) => {
      dispatch(resendInviteRequestFailed(error.response));
      dispatch(
        showToaster({ message: error.response.data.message, type: "error" })
      );
    });
};

export const updatePatientInfoRequestSent = () => ({
  type: UPDATE_PATIENT_INFO_REQUEST_SENT,
});

export const updatePatientInfoRequestSucceeded = () => ({
  type: UPDATE_PATIENT_INFO_REQUEST_SUCCEED,
});

export const updatePatientInfoRequestFailed = () => ({
  type: UPDATE_PATIENT_INFO_REQUEST_FAILED,
});

export const updatePatientInfoAction =
  (id, userProcedureId, params) => (dispatch) => {
    dispatch(updatePatientInfoRequestSent());
    updatePatientInfo(params)
      .then(() => {
        dispatch(updatePatientInfoRequestSucceeded());
        dispatch(
          showToaster({ message: "Patient details updated successfully." })
        );
        dispatch(getPatientInfo(id, userProcedureId));
      })
      .catch(() => {
        dispatch(updatePatientInfoRequestFailed());
      });
  };

export const updateSalesforceAccountFlagRequestSent = () => ({
  type: UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_SENT,
});

export const updateSalesforceAccountFlagRequestSucceeded = () => ({
  type: UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_SUCCEEDED,
});

export const updateSalesforceAccountFlagRequestSentRequestFailed = () => ({
  type: UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_FAILED,
});

export const updateSalesforceAccountFlagAction =
  (procedureId) => (dispatch) => {
    dispatch(updateSalesforceAccountFlagRequestSent());
    updateSalesforceCheckbox(procedureId)
      .then(() => {
        dispatch(updateSalesforceAccountFlagRequestSucceeded());
      })
      .catch(() => {
        dispatch(updateSalesforceAccountFlagRequestSentRequestFailed());
      });
  };

export const updateUserProcedureLocationRequestSent = () => ({
  type: UPDATE_USER_PROCEDURE_LOCATION_REQUEST_SENT,
});

export const updateUserProcedureLocationRequestSucceeded = (data) => ({
  type: UPDATE_USER_PROCEDURE_LOCATION_REQUEST_SUCCEEDED,
  payload: data,
});

export const updateUserProcedureLocationRequestFailed = (error) => ({
  type: UPDATE_USER_PROCEDURE_LOCATION_REQUEST_FAILED,
  error: error,
});

export const deleteUserRequestSent = () => ({
  type: DELETE_USER_REQUEST_SENT,
});

export const deleteUserRequestSucceeded = () => ({
  type: DELETE_USER_REQUEST_SUCCEED,
});

export const deleteUserRequestFailed = () => ({
  type: DELETE_USER_REQUEST_FAILED,
});

export const revokeUserDeleteRequestSent = () => ({
  type: REVOKE_USER_DELETE_REQUEST_SENT,
});

export const revokeUserDeleteRequestSucceeded = () => ({
  type: REVOKE_USER_DELETE_REQUEST_SUCCEED,
});

export const revokeUserDeleteRequestFailed = () => ({
  type: REVOKE_USER_DELETE_REQUEST_FAILED,
});

export const updateUserProcedureLocationAction = (params) => (dispatch) => {
  dispatch(updateUserProcedureLocationRequestSent());
  updateUserProcedureLocation(params)
    .then((response) => {
      dispatch(
        updateUserProcedureLocationRequestSucceeded(response.data.userProcedure)
      );
      dispatch(getPatientInfo(params.patient_id, params.user_procedure_id));
      dispatch(
        getPatientAppointments(params.patient_id, params.composit_procedure_id)
      );
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch((error) => {
      dispatch(updateUserProcedureLocationRequestFailed());
      dispatch(
        showToaster({
          message: "The location could not be updated.",
          type: "error",
        })
      );
    });
};

export const deleteUserAction = (params) => (dispatch) => {
  dispatch(deleteUserRequestSent());
  deleteUser(params)
    .then((response) => {
      dispatch(deleteUserRequestSucceeded());
      dispatch(getPatientInfo(params.id, params.userProcedureId))
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {
      dispatch(deleteUserRequestFailed());
    });
};

export const revokeUserAction = (params) => (dispatch) => {
  dispatch(revokeUserDeleteRequestSent());
  revokeUser(params)
    .then((response) => {
      dispatch(revokeUserDeleteRequestSucceeded());
      dispatch(getPatientInfo(params.id, params.userProcedureId))
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {
      dispatch(revokeUserDeleteRequestFailed());
    });
};