import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {isiOS} from 'utils/common';
import LightboxModal from 'components/LightboxModal';
import {showToaster} from 'common/toasterActions';


class DownloadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    handleDownloadClick = () => {
        const {serviceCall, args = []} = this.props;
        let win = null;
        // initiate window object for iOS devices only
        let isLoading = true;
        if (isiOS) {
            win = window.open('');
            // Don't show loading for iOS devices as downloading will be redirected to new page
            isLoading = false;
        }
        this.setState({
            isLoading,
        });

        serviceCall(...args)
            .then((response) => {
                if (response) {
                    this.setState({isLoading: false});
                    // open in new window if not device is not running on iOS device due to limitations of iOS
                    const downloadLink = response.data.downloadLink || response.data.download_link;
                    if (isiOS && win) {
                        win.location = downloadLink;
                        win.focus();
                    } else {
                        win = window.location.assign(downloadLink, '_blank');
                        win.focus();
                    }
                }
            })
            .catch((error) => {
                if (win) {
                    win.close();
                }
                this.setState({isLoading: false});
                if (error.response && error.response.data && error.response.data.message) {
                    this.props.showToaster({message: error.response.data.message, type: 'error'});
                }
            });
    }

    render() {
        const {isLoading} = this.state;
        return (
            <span onClick={this.handleDownloadClick}>
        <LightboxModal show={isLoading}/>
                {this.props.children}
      </span>
        );
    }
}

DownloadFile.propTypes = {
    children: PropTypes.any,
    serviceCall: PropTypes.func.isRequired,
    args: PropTypes.any,
    showToaster: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    showToaster: (params) => dispatch(showToaster(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DownloadFile);
