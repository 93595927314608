import {GET_XEALTH_PROGRAM_SUCCESS,} from './constants';

const initialState = {
    isLoading: true,
    url: null
};

export const xealthV1ProgramReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_XEALTH_PROGRAM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                url: action.data,
            };
        default:
            return state;
    }
};
