import variables from "./styleGuideComponents.scss";

const buttonSizes = {
  web: {
    large: {
      width: "465px",
      height: "56px",
    },
    medium: {
      width: "465px",
      height: "48px",
    },
  },
  phone: {
    large: {
      width: "300px",
      height: "56px",
    },
    medium: {
      width: "300px",
      height: "48px",
    },
  },
};

const InputBoxSizes = {
  web: {
    width: "465px",
    height: "60px",
  },
  phone: {
    width: "300px",
    height: "50px",
  },
};

export const ButtonStyleGuide = {
  ...buttonSizes,
  background: variables.primaryBtnBackground,
  borderRadius: variables.primaryBtnBorderRadius,
  fontFamily: variables.fontName,
  fontWeight: variables.primaryBtnTextWeight,
  fontSize: variables.primaryBtnTextSize,
  lineHeight: variables.primaryBtnLineHeight,
  letterSpacing: variables.letterSpacing,
  align: "center",
  color: variables.primaryBtnTextColor,
  backgroundActive: variables.primaryBtnBackgroundActive,
  boxShadow: variables.primaryBtnBoxShadow,
};

export const PrimaryButtonStyleGuide = {
  ...buttonSizes,
  background: variables.primaryBtnBackground,
  borderRadius: variables.primaryBtnBorderRadius,
  fontFamily: variables.fontName,
  fontWeight: variables.primaryBtnTextWeight,
  fontSize: variables.primaryBtnTextSize,
  lineHeight: variables.primaryBtnLineHeight,
  letterSpacing: variables.letterSpacing,
  align: "center",
  color: variables.primaryBtnTextColor,
  backgroundActive: variables.primaryBtnBackgroundActive,
  boxShadow: variables.primaryBtnBoxShadow,
  backgroundDisabled: variables.primaryBtnDisabledBackground,
  colorDisabled: variables.primaryBtnDisabledTextColor,
};

export const SecondaryButtonStyleGuide = {
  ...buttonSizes,
  background: variables.secondaryBtnBackground,
  border: variables.secondaryBtnBorder,
  borderRadius: variables.secondaryBtnBorderRadius,
  fontFamily: variables.fontName,
  fontWeight: variables.secondaryBtnTextWeight,
  fontSize: variables.secondaryBtnTextSize,
  lineHeight: variables.secondaryBtnLineHeight,
  letterSpacing: variables.letterSpacing,
  align: "center",
  color: variables.secondaryBtnTextColor,
  backgroundActive: variables.secondaryBtnBackgroundActive,
  colorActive: variables.secondaryBtnTextColorActive,
  boxShadow: variables.secondaryBtnBoxShadow,
  backgroundDisabled: variables.secondaryBtnBorderDisabled,
  colorDisabled: variables.secondaryBtnTextColorDisabled,
};

export const TernaryButtonStyleGuide = {
  ...buttonSizes,
  background: variables.ternaryBtnBackground,
  borderRadius: variables.ternaryBtnBorderRadius,
  fontFamily: variables.fontName,
  fontWeight: variables.ternaryBtnTextWeight,
  fontSize: variables.ternaryBtnTextSize,
  lineHeight: variables.ternaryBtnLineHeight,
  letterSpacing: variables.letterSpacing,
  align: "center",
  color: variables.ternaryBtnTextColor,
  backgroundHover: variables.ternaryBtnBackgroundHover,
  backgroundActive: variables.ternaryBtnBackgroundActive,
  backgroundDisabled: variables.ternaryBtnDisabledBackground,
};

export const InputBoxStyleGuide = {
  ...InputBoxSizes,
  fontFamily: variables.fontName,
  fontWeight: variables.inputBoxTextFontWeight,
  fontSize: variables.inputBoxTextFontSize,
  lineHeight: variables.inputBoxLineHeight,
  align: "center",
  border: variables.inputBoxBorder,
  color: variables.inputBoxTextColorActive,
  borderColorDefault: variables.inputBoxBorderColor,
  borderRadius: variables.inputBoxBorderRadius,
  borderColorActive: variables.inputBoxBorderColorActive,
  borderColorError: variables.inputBoxBorderColorError,

  colorPrimaryDark: variables.colorPrimaryDark,
  colorSecondaryDark: variables.colorSecondaryDark,
  colorInactiveGrey: variables.colorInactiveGrey,
  colorAlertNegative: variables.colorAlertNegative,
  colorPrimary: variables.colorPrimary,
  colorSecondary: variables.colorSecondary,
  colorPrimaryDark: variables.colorPrimaryDark
};

// primaryBtnBackground: linear-gradient(90deg, #8A6E93 0%, #503D57 95%);

// primaryBtnBorderRadius: 4px;
// primaryBtnWidth: 300px;
// primaryBtnHeight: 56px;
// primaryBtnWeight:400px;
// primaryBtnSize: 16px;
// primaryBtnLineHeight: 16px;
// letterSpacing: 0.15px;
// colour: #FFFFFF;

export const colors = {
  colorPrimaryDark: variables.colorPrimaryDark,
  colorSecondaryDark: variables.colorSecondaryDark,
  colorInactiveGrey: variables.colorInactiveGrey,
  colorAlertNegative: variables.colorAlertNegative,
  colorPrimary: variables.colorPrimary,
  colorSecondary: variables.colorSecondary,
  colorPrimaryDark: variables.colorPrimaryDark,
  colorPrimaryLight: variables.colorPrimaryLight
};

export const passwordVisibilityIcon={
  height:"22px",
  width:"19.15px",
  color: variables.colorInactiveGrey
}
