import {forgotPasswordLink} from 'services/users';
import {showToaster} from 'common/toasterActions';
import {
    ALTER_FORGOT_PASSWORD_REQUEST_SENT_FLAG,
    GET_FORGOT_PASSWORD_REQUEST_FAILED,
    GET_FORGOT_PASSWORD_REQUEST_SENT,
    GET_FORGOT_PASSWORD_REQUEST_SUCCEED
} from './constants';

export const forgotPasswordRequestSent = () => ({
    type: GET_FORGOT_PASSWORD_REQUEST_SENT,
});

export const forgotPasswordSucceeded = (data) => ({
    type: GET_FORGOT_PASSWORD_REQUEST_SUCCEED,
    data
});

export const forgotPasswordRequestFailed = () => ({
    type: GET_FORGOT_PASSWORD_REQUEST_FAILED,
});


export const forgotPasswordFlagALter = () => ({
    type: ALTER_FORGOT_PASSWORD_REQUEST_SENT_FLAG,
});


export const onForgotPasswordRequest = (params) => ((dispatch) => {
    dispatch(forgotPasswordRequestSent());
    forgotPasswordLink(params)
        .then((response) => {
            dispatch(forgotPasswordSucceeded(params));
            dispatch(showToaster({message: response.data.message}));
        })
        .catch((error) => {
            dispatch(forgotPasswordRequestFailed());
            dispatch(showToaster({message: error.response.data.errors[0], type: 'error'}));
            // dispatch(showToaster({ message: 'An email or text message has been sent to you.' }));
        });
});

export const alterForgotPasswordSentFlag = () => ((dispatch) => {
    dispatch(forgotPasswordFlagALter())
})
